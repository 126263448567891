import products from "../assets/products.json";
import { wineAtom } from "./configs";
import { konsole } from "./utils";
export const offset = [0, -1.12, 7];
export const offsetPostionRightWall = [0, -1.12, 6.99]
export const offsetMicrowave = [-.2, -1.12, 7];

window.doOffset = (pos) => {
	return [pos[0] + offset[0], pos[1] + offset[1], pos[2] + offset[2]];
};
window.deOffset = (pos) => [
	pos[0] - offset[0],
	pos[1] - offset[1],
	pos[2] - offset[2],
];


export const positions = {
	//Countertop Convection Microwave Oven - Checked
	DCM24S: {
		manual: true,
		position: [1.01, 0.14, 1.65],
		rotation: [0, 0, 0],
		geometryToHide: [],
		geometryToDisplay: [],
		geometriesToShow2And3: [
			'BW_DYN_noMW'
		],

	},
	// DOB30M977D
	//Over The Range Convention Microwave Hood - Checked
	PCOR30S: {
		manual: true,
		position: [-1.452, 0.69, 7.57],
		rotation: [0, 0, 0],
		geometryToHide: [
			"Panel_Cabinet_243W_x_255H_1008",
			"Panel_Cabinet_243W_x_255H_1009",
			"Blank_Cabinet_Box_for_30",
			"Blank_Cabinet_Box001",
			"Blank_Cabinet_Box002",
			"Panel_Cabinet_243W_x_255H_1008_xxm",
			"Panel_Cabinet_243W_x_255H_1009_xxm",
			"Panel_Cabinet_243W_x_255H_1010_xxm",
		],
		geometryToDisplay: [
			"Panel_Cabinet_243W_x_255H_1018_xxm",
			"Panel_Cabinet_243W_x_255H_1004_xxm",
			"Panel_Cabinet_243W_x_255H_1_xxm",
			"Panel_Cabinet_275W_x_255H002_xxm",
			"Panel_Cabinet_275W_x_255H004_xxm",
			"Cabinets_Right001",
			"Cabinets_Right010",
			"Cabinets_Right002",
			"Panel_Cabinet_243W_x_255H_1031_xxm",
			"Panel_Cabinet_243W_x_255H_1034_xxm",
			"Panel_Cabinet_243W_x_255H_1032_xxm",
			"Panel_Cabinet_243W_x_255H_1035_xxm",
			"Panel_Cabinet_243W_x_255H_1033_xxm",
			"Panel_Cabinet_243W_x_255H_1036_xxm"
		],
		geometriesToShow2And3: [
			"Cabinets_Right015_1",
			"Cabinets_Right015",
			"BWU_DYN_base",
			"BWU_DYN_wall"
		],

	},


	//Microwave-In-A-Drawer - Checked
	DMR24M977WM: {
		manual: true,
		position: [-1.283, -0.425, 3.615],
		rotation: [0, 0, 0],
		geometryToHide: [
			"Island_Counter_Whole002",
			"13_inch_Drawer001_xxm",
			"LEFT_DRAWER_for_Island_MicroWaves001_xxm",
			"RIGHT_DRAWER_for_Island_MicroWaves001_xxm",
		],
		geometryToDisplay: [
			"L3_BOTTOM_for_24inch_Microwave_Center_xxm",
			"L3_TOP_Microwave_24_Center_xxm",
			"L4_TOP001_xxm",

		],
		geometriesToShow2And3: [
			'BW_DYN_DMR24M977W'
		],

	},
	//Microwave-In-A-Drawer - Checked
	DMR24M977WS: {
		manual: true,
		position: [0.717, -0.425, 3.62],
		rotation: [0, 0, 0],
		geometryToHide: [
			"Island_Counter_Whole002",
			"13_inch_Drawer001_xxm",
			"LEFT_DRAWER_for_Island_MicroWaves001_xxm",
			"RIGHT_DRAWER_for_Island_MicroWaves001_xxm",
		],
		geometryToDisplay: [
			"L3_BOTTOM_for_24inch_Microwave_Center_xxm",
			"L3_TOP_Microwave_24_Center_xxm",
			"L4_TOP001_xxm",
		],
		geometriesToShow2And3: [
			'BW_DYN_DMR24M977W'
		],

	},

	//Microwave-In-A-Drawer - Checked
	DMR30M977WM: {
		manual: true,
		position: [-1.285, -0.42700000000000016, 5.62],
		rotation: [0, 0, 0],
		geometryToHide: [
			"L3_BOTTOM_for_24inch_Microwave_Center_xxm",
			"L3_TOP_Microwave_24_Center_xxm",
			"L4_TOP001_xxm",
		],
		geometryToDisplay: [],
		geometriesToShow2And3: [
			'BW_DYN_DMR30M977W'
		],

	},

	//Microwave-In-A-Drawer - Checked
	DMR30M977WS: {
		manual: true,
		position: [0.717, -0.42700000000000016, 5.62],
		rotation: [0, 0, 0],
		geometryToHide: [
			"L3_BOTTOM_for_24inch_Microwave_Center_xxm",
			"L3_TOP_Microwave_24_Center_xxm",
			"L4_TOP001_xxm",
			// "L3_TOP_xxm",
		],
		geometryToDisplay: [],
		geometriesToShow2And3: [
			'BW_DYN_DMR30M977W'
		],

	},
	//Countertop Microwave Oven - Checked
	DMW2420S: {
		manual: true,
		position: [1.02, 0.11, 9.65],
		rotation: [0, 0, 0],
		geometryToHide: [],
		geometryToDisplay: [],
		geometriesToShow2And3: [
			'BW_DYN_noMW'
		],

	},
	//Dishwasher 1 - Checked
	DDW24M999U: {
		manual: true,
		position: [0.605, -0.936, -2.305],
		rotation: [0, 180, 0],
		geometryToHide: [
			"White_Panel_29inch_Depth_Dishwasher_24W_RIGHT_xxm",
			"NA_Dishwasher_24W_xxm",
		],
		geometryToDisplay: ["AnklePlate_Dishwasher_xxm"],
		geometriesToShow2And3: [],

	},
	DDW24G9000APDAS: {
		manual: true,
		position: [0.605, -0.936, -2.305],
		rotation: [0, 180, 0],
		geometryToHide: [
			"White_Panel_29inch_Depth_Dishwasher_24W_RIGHT_xxm",
			"NA_Dishwasher_24W_xxm",
		],
		geometryToDisplay: ["AnklePlate_Dishwasher_xxm"],
		geometriesToShow2And3: [],

	},

	//Dishwasher 2 - Checked
	DDW24T999BB: {
		manual: true,
		position: [0, -1.12, 7],
		rotation: [0, 0, 0],
		geometryToHide: [
			"White_Panel_29inch_Depth_Dishwasher_24W_RIGHT_xxm",
			"NA_Dishwasher_24W_xxm",
		],
		geometryToDisplay: ["AnklePlate_Dishwasher_xxm"],
		geometriesToShow2And3: [],

	},

	DDW24G9000APDA: {
		manual: true,
		position: [0, -1.12, 7],
		rotation: [0, 0, 0],
		geometryToHide: [
			"White_Panel_29inch_Depth_Dishwasher_24W_RIGHT_xxm",
			"NA_Dishwasher_24W_xxm",
		],
		geometryToDisplay: ["AnklePlate_Dishwasher_xxm"],
		geometriesToShow2And3: [],

	},

	DDW24M999UM: {
		manual: true,
		position: [2.605, -0.936, -2.305],
		rotation: [0, 180, 0],
		geometryToHide: [
			"White_Panel_29inch_Depth_Dishwasher_24W_RIGHT_xxm",
			"NA_Dishwasher_24W_xxm",
		],
		geometryToDisplay: ["AnklePlate_Dishwasher_xxm"],
		geometriesToShow2And3: [],

	},

	DDW24G9000APDAM: {
		manual: true,
		position: [2.605, -0.936, -2.305],
		rotation: [0, 180, 0],
		geometryToHide: [
			"White_Panel_29inch_Depth_Dishwasher_24W_RIGHT_xxm",
			"NA_Dishwasher_24W_xxm",
		],
		geometryToDisplay: ["AnklePlate_Dishwasher_xxm"],
		geometriesToShow2And3: [],

	},



	DRW24980: {
		manual: true,
		position: [2.687, -0.967, 15.58],
		// position: [2.678, 0.182, -9.387],
		rotation: [0, 0, 0],
		geometryToHide: ["WRight_-__WinePanel_24inch_xxm"],
		geometryToDisplay: [],
		geometriesToShow2And3: [],

	},


	//Built-In Wine Dispenser - Checked
	DWS4000BBS: {
		manual: true,
		position: [2.69, -0.043, 13.7104],
		rotation: [0, 0, 0],
		geometryToHide: ["WRight_-__WinePanel_24inch_xxm"],
		geometryToDisplay: [
			"WRightProductArea24SpacerCutOut_xxm",
			"WRightProductArea24SpacerTop_xxm",
		],
		geometriesToShow2And3: [
			"RW_DYN_DWS4000BB"
		],

	},
	DWS4000BBM: {
		manual: true,
		position: [0.69, -0.043, 13.7104],
		rotation: [0, 0, 0],
		geometryToHide: ["WRight_-__WinePanel_24inch_xxm"],
		geometryToDisplay: [
			"WRightProductArea24SpacerCutOut_xxm",
			"WRightProductArea24SpacerTop_xxm",
		],
		geometriesToShow2And3: [
			"RW_DYN_DWS4000BB"
		],

	},
	//Undercounter Wine Cellar - Checked
	HWC242: {
		manual: true,
		position: [-1.425, -0.979, -14.3],
		rotation: [0, 180, 0],
		geometryToHide: ["NA_Wine_Cellar_235W_Panel_xxm"],
		geometryToDisplay: [],
		geometriesToShow2And3: [],

	},

	//Wall Oven - Single(this can exist in 3 places, but only 1 can be visible at one time) - Checked
	DOB30M977S: {
		manual: true,
		//must have position set to see actual position so if you wanna see wall please put position in this to see, will do code later
		position: [-0.105, -0.012, 11.618],
		rotation: [0, 0, 0],
		positionOnWall: [-0.105, -0.012, 11.618],
		positionInKitchen: [-3.45, -0.9, 11.63],
		positionInIsland: [1.7, -0.9, -14.314],

		rotationOnWall: [0, 0, 0],
		rotationInKitchenSink: [0, 0, 0],
		rotationInIsland: [0, 3.144444, 0],



		geometryToDisplay: [
			"R_Panel_Bottom_Left_Middlel_75H_xxm",
			"Warmer_H10_x30W_x_1D_Panel001_xxm",
			"R_Panel_Bottom_Left_Small_264H_xxm",
			"WRightProductArea30Spacer002_xxm",
			"WRightProductArea30SpacerTOP_xxm",
		],


		//check the 7 haus for kitchen and island displays, may not have to do it here but in there
		geometriesToShow2And3: [
			'RW_DYN_DOB30M977S'
		],
		geometriesToShowKitchen2And3: [],
		geometriesToShowIsland2And3: [],



	},
	//Wall Oven - Single(this can exist in 3 places, but only 1 can be visible at one time) - Checked
	DOB30M977SM: {
		manual: true,
		//must have position set to see actual position so if you wanna see wall please put position in this to see, will do code later
		position: [1.895, -0.01, 11.6179],
		rotation: [0, 0, 0],
		positionOnWall: [1.895, -0.01, 11.6179],
		positionInKitchen: [-1.448, -0.9, 11.628],
		positionInIsland: [-0.3, -0.9, -14.308],

		rotationOnWall: [0, 0, 0],
		rotationInKitchenSink: [0, 0, 0],
		rotationInIsland: [0, 3.144444, 0],

		geometryToDisplay: [
			"R_Panel_Bottom_Left_Middlel_75H_xxm",
			"Warmer_H10_x30W_x_1D_Panel001_xxm",
			"R_Panel_Bottom_Left_Small_264H_xxm",
			"WRightProductArea30Spacer002_xxm",
			"WRightProductArea30SpacerTOP_xxm"
		],

		//check the 7 hause for kitchen and island displays, may not have to do it here but in there
		geometriesToShow2And3: ['RW_DYN_DOB30M977S'],
		geometriesToShowKitchen2And3: [],
		geometriesToShowIsland2And3: [],



	},

	//Wall Oven-Double - Checked
	DOB30M977DM: {
		manual: true,
		position: [1.896, -0.6, 9.721],
		rotation: [0, 0, 0],
		geometryToHide: ["NA_52inchH_-_Wall_Oven_Double_Right_Wall_Panel_xxm"],
		geometryToDisplay: [
			"NA_-_DOC30P977D-_Wall_Oven_Combi_Righ_Wall001_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single001_xxm",
			"NA_Panel_Bottom_Left_Small001_xxm",
		],

		geometriesToShow2And3: [
			"RW_DYN_DOB30M977D_DOB30T977D"
		],

	},
	DOB30M977DS: {
		manual: true,
		position: [-0.105, -0.6, 9.72],
		rotation: [0, 0, 0],
		geometryToHide: ["NA_52inchH_-_Wall_Oven_Double_Right_Wall_Panel_xxm"],
		geometryToDisplay: [
			"NA_-_DOC30P977D-_Wall_Oven_Combi_Righ_Wall001_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single001_xxm",
			"NA_Panel_Bottom_Left_Small001_xxm",
		],
		geometriesToShow2And3: [
			"RW_DYN_DOB30M977D_DOB30T977D"
		],

	},

	//Wall Oven - Combi - Checked
	DOC30M977DS: {
		manual: true,
		position: [-0.085, -0.395, 13.6276],
		rotation: [0, 0, 0],
		geometryToHide: [],
		geometryToDisplay: [
			"Panel_Bottom_Left_Small_378H_xxm",
			"R_Panel_Bottom_Left_Middlel_75H001_xxm",
			"WRightProductArea30Spacer002_xxm",
			"WRightProductArea30SpacerTOP_xxm",
			"R_Panel_Bottom_Left_Small_123H_xxm"
		],
		geometriesToShow2And3: [
			'RW_DYN_DOC30M977D'
		],

	},

	//Wall Oven - Combi - Checked
	DOC30M977DM: {
		manual: true,
		position: [1.914, -0.395, 13.629],
		rotation: [0, 0, 0],
		geometryToHide: [],
		geometryToDisplay: [
			"Panel_Bottom_Left_Small_378H_xxm",
			"R_Panel_Bottom_Left_Middlel_75H001_xxm",
			"WRightProductArea30Spacer002_xxm",
			"WRightProductArea30SpacerTOP_xxm",
			"R_Panel_Bottom_Left_Small_123H_xxm"
		],
		geometriesToShow2And3: [
			'RW_DYN_DOC30M977D'
		],

	},

	//Dual-Fuel Steam Range w/Griddle can exist in 2 different positions - Checked
	DOP48M86DM: {
		manual: true,
		position: [-1.4, -0.97, -2.392],
		positionInKitchenSink: [-1.4, -0.97, -2.392],
		positionInIsland: [-0.41, -0.94, -0.3],
		rotationInKitchenSink: [0, 0, 0],
		rotationInIsland: [0, 180, 0],

		geometryToHideKitchen: [
			"12inch_48inch002_xxm",
			"12inch_48inch001_xxm",

			"L1_BOTTOM011_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single002_xxm",
			"LL3_BOTTOM_K_xxm",
			"L1_BOTTOM002_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_xxm",
			"For_18inch_Spacer_Wood_Side_Panel002",
			"DOB30T977S_-_Wall_Oven_Single_L_Shape_xxm_xxm",
			"Backwall_Toe-Kick_xxm",
			"Kitchen_Counter_Induction_Cooktop",
			"Kitchen_Counter_for_DTG30P875N",
			"Kitchen_Counter_for_DTG30P875N003",
			"Kitchen_Counter_for_DTG30P875N001",
			"Kitchen_Counter_4_DTG30P875NDTI30M977BBDTI36P876BB",
			"Kitchen_Counter_for_DTG30P875N002",
			"Kitchen_Counter_for_DTG30P875N_or_DTI30M977BB",
			"Kitchen_Counter_for_DTG30P875NDTI30M977BBDTI36P876BB",
			"Kitchen_Counter_for_DTI36M977BB",
			"Kitchen_Counter_for_DTT36T960G",
			"Kitchen_Counter_for_DTG30P875N_or_DTI30M977BB001",
			"Left_Counter_for_18inc_Left_Counter",
			"For_18inch_Spacer_Wood_Side_Panel",
			"For_18inch_Spacer_Wood_Side_Panel003",
			"For_18inch_Spacer_Wood_Side_Panel003",
			"For_18inch_Spacer_Wood_Side_Panel008",
			"For_18inch_Spacer_Wood_Side_Panel015",
			"For_18inch_Spacer_Wood_Side_Panel006",
			"For_18inch_Spacer_Wood_Side_Panel007",
			"For_18inch_Spacer_Wood_Side_Panel016",
			"For_18inch_Spacer_Wood_Side_Panel017",
			"For_18inch_Spacer_Wood_Side_Panel018",
			"For_18inch_Spacer_Wood_Side_Panel014",
			"Left_Counter_for_12inc_Left_Counter_D29",
			"Let_Counter_for_12inc_Left_Counter",
			"For_12inch_Spacer_Wood_Side_Panel002",
			"For_12inch_Spacer_Wood_Side_Panel",
		],
		geometryToDisplayKitchen: [
			"24_LEFT_for_48imch_Cooktop_Island_xxm",
			"24_LEFT_for_48imch_Cooktop_Island001_xxm",
			"18_LEFT002_xxm",
			"18_LEFT008_xxm",
			"13_inch_Drawer001_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom002_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom004_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom006_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom001_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom003_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom005_xxm",
			"18_RIGHT002_xxm",
			"18_RIGHT003_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island001_xxm",
			"18_RIGHT_for_30W_xxm",
			"12_RIGHT009_xxm",
			"12_RIGHT010_xxm",
			"12_RIGHT012_xxm",
			"12_RIGHT006_xxm",
			"12_RIGHT001_xxm",
			"12_RIGHT008_xxm",
			"12_RIGHT004_xxm",
			"12_RIGHT002_xxm",
			"12_RIGHT007_xxm",
			"12_RIGHT_xxm",
			"12_RIGHT011_xxm",
			"12_RIGHT005_xxm",
			"12_RIGHT003_xxm",
		],

		geometryToHideIsland: [
			"24_LEFT_for_48imch_Cooktop_Island_xxm",
			"24_LEFT_for_48imch_Cooktop_Island001_xxm",
			"18_LEFT002_xxm",
			"18_LEFT008_xxm",
			"13_inch_Drawer001_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom002_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom004_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom006_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom001_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom003_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom005_xxm",
			"18_RIGHT002_xxm",
			"18_RIGHT003_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island001_xxm",
			"18_RIGHT_for_30W_xxm",
			"12_RIGHT009_xxm",
			"12_RIGHT010_xxm",
			"12_RIGHT012_xxm",
			"12_RIGHT006_xxm",
			"12_RIGHT001_xxm",
			"12_RIGHT008_xxm",
			"12_RIGHT004_xxm",
			"12_RIGHT002_xxm",
			"12_RIGHT007_xxm",
			"12_RIGHT_xxm",
			"12_RIGHT011_xxm",
			"12_RIGHT005_xxm",
			"12_RIGHT003_xxm",
			"For_18inch_Spacer_Wood_Side_Panel004",
			"12inch_48inch002_xxm",
		],
		geometryToDisplayIsland: [
			"12inch_48inch002_xxm",
			"12inch_48inch001_xxm",
			"Panel_H262_Panel_xxm",
			"L1_BOTTOM011_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single002_xxm",
			"LL3_BOTTOM_K_xxm",
			"L1_BOTTOM002_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_xxm",
			"For_18inch_Spacer_Wood_Side_Panel002",
			"DOB30T977S_-_Wall_Oven_Single_L_Shape_xxm_xxm",
			"Backwall_Toe-Kick_xxm",
			"Kitchen_Counter_Induction_Cooktop",
			"Kitchen_Counter_for_DTG30P875N",
			"Kitchen_Counter_for_DTG30P875N003",
			"Kitchen_Counter_for_DTG30P875N001",
			"Kitchen_Counter_4_DTG30P875NDTI30M977BBDTI36P876BB",
			"Kitchen_Counter_for_DTG30P875N002",
			"Kitchen_Counter_for_DTG30P875N_or_DTI30M977BB",
			"Kitchen_Counter_for_DTG30P875NDTI30M977BBDTI36P876BB",
			"Kitchen_Counter_for_DTI36M977BB",
			"Kitchen_Counter_for_DTT36T960G",
			"Kitchen_Counter_for_DTG30P875N_or_DTI30M977BB001",
			"Left_Counter_for_18inc_Left_Counter",
			"For_18inch_Spacer_Wood_Side_Panel",
			"For_18inch_Spacer_Wood_Side_Panel003",
			"For_18inch_Spacer_Wood_Side_Panel003",
			"For_18inch_Spacer_Wood_Side_Panel008",
			"For_18inch_Spacer_Wood_Side_Panel015",
			"For_18inch_Spacer_Wood_Side_Panel006",
			"For_18inch_Spacer_Wood_Side_Panel007",
			"For_18inch_Spacer_Wood_Side_Panel016",
			"For_18inch_Spacer_Wood_Side_Panel017",
			"For_18inch_Spacer_Wood_Side_Panel018",
			"For_18inch_Spacer_Wood_Side_Panel014",
			"Left_Counter_for_12inc_Left_Counter_D29",
			"Let_Counter_for_12inc_Left_Counter",
			"For_12inch_Spacer_Wood_Side_Panel002",
			"For_12inch_Spacer_Wood_Side_Panel",
			"Island_Counter_for_DOP48M86D",
			"Counter_No_Whole_29Depth",
			"Left_Counter_for_48in_Range_D29",
			"Left_Counter_for_48in_Range_D29001",
			"Left_Counter_for_48in_Range",
			"For_18inch_Spacer_Wood_Side_Panel001",
			"12inch_48inch001_xxm",


		],

		geometriesToShowKitchen2And3: [],
		geometriesToShowIsland2And3: [],


	},
	//Dual-Fuel Steam Range w/Griddle can exist in 2 different positions - Checked
	DOP48M86DS: {
		manual: true,
		position: [-1.4, -0.97, -2.392],
		rotation: [0, 0, 0],
		positionInKitchenSink: [-1.4, -0.97, -2.392],
		positionInIsland: [-0.41, -0.94, -0.3],
		rotationInKitchenSink: [0, 0, 0],
		rotationInIsland: [0, 180, 0],

		geometryToHideKitchen: [
			"12inch_48inch002_xxm",
			"12inch_48inch001_xxm",
			"Panel_H262_Panel_xxm",
			"L1_BOTTOM011_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single002_xxm",
			"LL3_BOTTOM_K_xxm",
			"L1_BOTTOM002_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_xxm",
			"For_18inch_Spacer_Wood_Side_Panel002",
			"DOB30T977S_-_Wall_Oven_Single_L_Shape_xxm_xxm",
			"Backwall_Toe-Kick_xxm",
			"Kitchen_Counter_Induction_Cooktop",
			"Kitchen_Counter_for_DTG30P875N",
			"Kitchen_Counter_for_DTG30P875N003",
			"Kitchen_Counter_for_DTG30P875N001",
			"Kitchen_Counter_4_DTG30P875NDTI30M977BBDTI36P876BB",
			"Kitchen_Counter_for_DTG30P875N002",
			"Kitchen_Counter_for_DTG30P875N_or_DTI30M977BB",
			"Kitchen_Counter_for_DTG30P875NDTI30M977BBDTI36P876BB",
			"Kitchen_Counter_for_DTI36M977BB",
			"Kitchen_Counter_for_DTT36T960G",
			"Kitchen_Counter_for_DTG30P875N_or_DTI30M977BB001",
			"Left_Counter_for_18inc_Left_Counter",
			"For_18inch_Spacer_Wood_Side_Panel",
			"For_18inch_Spacer_Wood_Side_Panel003",
			"For_18inch_Spacer_Wood_Side_Panel003",
			"For_18inch_Spacer_Wood_Side_Panel008",
			"For_18inch_Spacer_Wood_Side_Panel015",
			"For_18inch_Spacer_Wood_Side_Panel006",
			"For_18inch_Spacer_Wood_Side_Panel007",
			"For_18inch_Spacer_Wood_Side_Panel016",
			"For_18inch_Spacer_Wood_Side_Panel017",
			"For_18inch_Spacer_Wood_Side_Panel018",
			"For_18inch_Spacer_Wood_Side_Panel014",
			"Left_Counter_for_12inc_Left_Counter_D29",
			"Let_Counter_for_12inc_Left_Counter",
			"For_12inch_Spacer_Wood_Side_Panel002",
			"For_12inch_Spacer_Wood_Side_Panel",
		],
		geometryToDisplayKitchen: [
			"24_LEFT_for_48imch_Cooktop_Island_xxm",
			"24_LEFT_for_48imch_Cooktop_Island001_xxm",
			"18_LEFT002_xxm",
			"18_LEFT008_xxm",
			"13_inch_Drawer001_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom002_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom004_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom006_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom001_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom003_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom005_xxm",
			"18_RIGHT002_xxm",
			"18_RIGHT003_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island001_xxm",
			"18_RIGHT_for_30W_xxm",
			"12_RIGHT009_xxm",
			"12_RIGHT010_xxm",
			"12_RIGHT012_xxm",
			"12_RIGHT006_xxm",
			"12_RIGHT001_xxm",
			"12_RIGHT008_xxm",
			"12_RIGHT004_xxm",
			"12_RIGHT002_xxm",
			"12_RIGHT007_xxm",
			"12_RIGHT_xxm",
			"12_RIGHT011_xxm",
			"12_RIGHT005_xxm",
			"12_RIGHT003_xxm",
		],

		geometryToHideIsland: [
			"24_LEFT_for_48imch_Cooktop_Island_xxm",
			"24_LEFT_for_48imch_Cooktop_Island001_xxm",
			"18_LEFT002_xxm",
			"18_LEFT008_xxm",
			"13_inch_Drawer001_xxm",

			"DOB30T977S_-_Wall_Oven_Single_Bottom002_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom004_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom006_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom001_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom003_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom005_xxm",
			"18_RIGHT002_xxm",
			"18_RIGHT003_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island001_xxm",
			"18_RIGHT_for_30W_xxm",
			"12_RIGHT009_xxm",
			"12_RIGHT010_xxm",
			"12_RIGHT012_xxm",
			"12_RIGHT006_xxm",
			"12_RIGHT001_xxm",
			"12_RIGHT008_xxm",
			"12_RIGHT004_xxm",
			"12_RIGHT002_xxm",
			"12_RIGHT007_xxm",
			"12_RIGHT_xxm",
			"12_RIGHT011_xxm",
			"12_RIGHT005_xxm",
			"12_RIGHT003_xxm",
			"For_18inch_Spacer_Wood_Side_Panel004",
			"12inch_48inch002_xxm",
		],
		geometryToDisplayIsland: [
			"12inch_48inch002_xxm",
			"12inch_48inch001_xxm",
			"Panel_H262_Panel_xxm",
			"L1_BOTTOM011_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single002_xxm",
			"LL3_BOTTOM_K_xxm",
			"L1_BOTTOM002_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_xxm",
			"For_18inch_Spacer_Wood_Side_Panel002",
			"DOB30T977S_-_Wall_Oven_Single_L_Shape_xxm_xxm",
			"Backwall_Toe-Kick_xxm",
			"Kitchen_Counter_Induction_Cooktop",
			"Kitchen_Counter_for_DTG30P875N",
			"Kitchen_Counter_for_DTG30P875N003",
			"Kitchen_Counter_for_DTG30P875N001",
			"Kitchen_Counter_4_DTG30P875NDTI30M977BBDTI36P876BB",
			"Kitchen_Counter_for_DTG30P875N002",
			"Kitchen_Counter_for_DTG30P875N_or_DTI30M977BB",
			"Kitchen_Counter_for_DTG30P875NDTI30M977BBDTI36P876BB",
			"Kitchen_Counter_for_DTI36M977BB",
			"Kitchen_Counter_for_DTT36T960G",
			"Kitchen_Counter_for_DTG30P875N_or_DTI30M977BB001",
			"Left_Counter_for_18inc_Left_Counter",
			"For_18inch_Spacer_Wood_Side_Panel",
			"For_18inch_Spacer_Wood_Side_Panel003",
			"For_18inch_Spacer_Wood_Side_Panel003",
			"For_18inch_Spacer_Wood_Side_Panel008",
			"For_18inch_Spacer_Wood_Side_Panel015",
			"For_18inch_Spacer_Wood_Side_Panel006",
			"For_18inch_Spacer_Wood_Side_Panel007",
			"For_18inch_Spacer_Wood_Side_Panel016",
			"For_18inch_Spacer_Wood_Side_Panel017",
			"For_18inch_Spacer_Wood_Side_Panel018",
			"For_18inch_Spacer_Wood_Side_Panel014",
			"Left_Counter_for_12inc_Left_Counter_D29",
			"Let_Counter_for_12inc_Left_Counter",
			"For_12inch_Spacer_Wood_Side_Panel002",
			"For_12inch_Spacer_Wood_Side_Panel",
			"Island_Counter_for_DOP48M86D",

			"Counter_No_Whole_29Depth",
			"Left_Counter_for_48in_Range_D29",
			"Left_Counter_for_48in_Range_D29001",
			"Left_Counter_for_48in_Range",
			"For_18inch_Spacer_Wood_Side_Panel001",
			"12inch_48inch001_xxm",
		],

		geometriesToShowKitchen2And3: [],
		geometriesToShowIsland2And3: [],


	},
	//Dual-Fuel Steam Range can exist in 2 different positions - Checked
	DOP48M96DM: {
		manual: true,
		position: [-1.66, -0.92, -2.39],
		rotation: [0, 0, 0],
		positionInKitchenSink: [-1.66, -0.92, -2.39],
		positionInIsland: [-0.41, -0.94, -0.3],
		rotationInKitchenSink: [0, 0, 0],
		rotationInIsland: [0, 180, 0],

		geometryToHideKitchen: [
			"12inch_48inch002_xxm",
			"12inch_48inch001_xxm",
			"Panel_H262_Panel_xxm",
			"L1_BOTTOM011_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single002_xxm",
			"LL3_BOTTOM_K_xxm",
			"L1_BOTTOM002_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_xxm",
			"For_18inch_Spacer_Wood_Side_Panel002",
			"DOB30T977S_-_Wall_Oven_Single_L_Shape_xxm_xxm",
			"Backwall_Toe-Kick_xxm",
			"Kitchen_Counter_Induction_Cooktop",
			"Kitchen_Counter_for_DTG30P875N",
			"Kitchen_Counter_for_DTG30P875N003",
			"Kitchen_Counter_for_DTG30P875N001",
			"Kitchen_Counter_4_DTG30P875NDTI30M977BBDTI36P876BB",
			"Kitchen_Counter_for_DTG30P875N002",
			"Kitchen_Counter_for_DTG30P875N_or_DTI30M977BB",
			"Kitchen_Counter_for_DTG30P875NDTI30M977BBDTI36P876BB",
			"Kitchen_Counter_for_DTI36M977BB",
			"Kitchen_Counter_for_DTT36T960G",
			"Kitchen_Counter_for_DTG30P875N_or_DTI30M977BB001",
			"Left_Counter_for_18inc_Left_Counter",
			"For_18inch_Spacer_Wood_Side_Panel",
			"For_18inch_Spacer_Wood_Side_Panel003",
			"For_18inch_Spacer_Wood_Side_Panel003",
			"For_18inch_Spacer_Wood_Side_Panel008",
			"For_18inch_Spacer_Wood_Side_Panel015",
			"For_18inch_Spacer_Wood_Side_Panel006",
			"For_18inch_Spacer_Wood_Side_Panel007",
			"For_18inch_Spacer_Wood_Side_Panel016",
			"For_18inch_Spacer_Wood_Side_Panel017",
			"For_18inch_Spacer_Wood_Side_Panel018",
			"For_18inch_Spacer_Wood_Side_Panel014",
			"Left_Counter_for_12inc_Left_Counter_D29",
			"Let_Counter_for_12inc_Left_Counter",
			"For_12inch_Spacer_Wood_Side_Panel002",
			"For_12inch_Spacer_Wood_Side_Panel",
		],
		geometryToDisplayKitchen: [
			"24_LEFT_for_48imch_Cooktop_Island_xxm",
			"24_LEFT_for_48imch_Cooktop_Island001_xxm",
			"18_LEFT002_xxm",
			"18_LEFT008_xxm",
			"13_inch_Drawer001_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom002_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom004_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom006_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom001_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom003_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom005_xxm",
			"18_RIGHT002_xxm",
			"18_RIGHT003_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island001_xxm",
			"18_RIGHT_for_30W_xxm",
			"12_RIGHT009_xxm",
			"12_RIGHT010_xxm",
			"12_RIGHT012_xxm",
			"12_RIGHT006_xxm",
			"12_RIGHT001_xxm",
			"12_RIGHT008_xxm",
			"12_RIGHT004_xxm",
			"12_RIGHT002_xxm",
			"12_RIGHT007_xxm",
			"12_RIGHT_xxm",
			"12_RIGHT011_xxm",
			"12_RIGHT005_xxm",
			"12_RIGHT003_xxm",
		],

		geometryToHideIsland: [
			"24_LEFT_for_48imch_Cooktop_Island_xxm",
			"24_LEFT_for_48imch_Cooktop_Island001_xxm",
			"18_LEFT002_xxm",
			"18_LEFT008_xxm",
			"13_inch_Drawer001_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom002_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom004_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom006_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom001_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom003_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom005_xxm",
			"18_RIGHT002_xxm",
			"18_RIGHT003_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island001_xxm",
			"18_RIGHT_for_30W_xxm",
			"12_RIGHT009_xxm",
			"12_RIGHT010_xxm",
			"12_RIGHT012_xxm",
			"12_RIGHT006_xxm",
			"12_RIGHT001_xxm",
			"12_RIGHT008_xxm",
			"12_RIGHT004_xxm",
			"12_RIGHT002_xxm",
			"12_RIGHT007_xxm",
			"12_RIGHT_xxm",
			"12_RIGHT011_xxm",
			"12_RIGHT005_xxm",
			"12_RIGHT003_xxm",
		],
		geometryToDisplayIsland: [
			"12inch_48inch002_xxm",
			"12inch_48inch001_xxm",
			"Panel_H262_Panel_xxm",
			"L1_BOTTOM011_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single002_xxm",
			"LL3_BOTTOM_K_xxm",
			"L1_BOTTOM002_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_xxm",
			"For_18inch_Spacer_Wood_Side_Panel002",
			"DOB30T977S_-_Wall_Oven_Single_L_Shape_xxm_xxm",
			"Backwall_Toe-Kick_xxm",
			"Kitchen_Counter_Induction_Cooktop",
			"Kitchen_Counter_for_DTG30P875N",
			"Kitchen_Counter_for_DTG30P875N003",
			"Kitchen_Counter_for_DTG30P875N001",
			"Kitchen_Counter_4_DTG30P875NDTI30M977BBDTI36P876BB",
			"Kitchen_Counter_for_DTG30P875N002",
			"Kitchen_Counter_for_DTG30P875N_or_DTI30M977BB",
			"Kitchen_Counter_for_DTG30P875NDTI30M977BBDTI36P876BB",
			"Kitchen_Counter_for_DTI36M977BB",
			"Kitchen_Counter_for_DTT36T960G",
			"Kitchen_Counter_for_DTG30P875N_or_DTI30M977BB001",
			"Left_Counter_for_18inc_Left_Counter",
			"For_18inch_Spacer_Wood_Side_Panel",
			"For_18inch_Spacer_Wood_Side_Panel003",
			"For_18inch_Spacer_Wood_Side_Panel003",
			"For_18inch_Spacer_Wood_Side_Panel008",
			"For_18inch_Spacer_Wood_Side_Panel015",
			"For_18inch_Spacer_Wood_Side_Panel006",
			"For_18inch_Spacer_Wood_Side_Panel007",
			"For_18inch_Spacer_Wood_Side_Panel016",
			"For_18inch_Spacer_Wood_Side_Panel017",
			"For_18inch_Spacer_Wood_Side_Panel018",
			"For_18inch_Spacer_Wood_Side_Panel014",
			"Left_Counter_for_12inc_Left_Counter_D29",
			"Let_Counter_for_12inc_Left_Counter",
			"For_12inch_Spacer_Wood_Side_Panel002",
			"For_12inch_Spacer_Wood_Side_Panel",
		],
		geometriesToShowKitchen2And3: [],
		geometriesToShowIsland2And3: [],


	},
	//Dual-Fuel Steam Range can exist in 2 different positions - Checked
	DOP48M96DS: {
		manual: true,
		positionInKitchenSink: [-1.678, -0.9315, -2.372],
		positionInIsland: [-0.388, 0.223, -7.298],
		rotationInKitchenSink: [0, 0, 0],
		rotationInIsland: [0, 180, 0],
		geometryToHideKitchen: [
			"12inch_48inch002_xxm",
			"12inch_48inch001_xxm",
			"Panel_H262_Panel_xxm",
			"L1_BOTTOM011_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single002_xxm",
			"LL3_BOTTOM_K_xxm",
			"L1_BOTTOM002_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_xxm",
			"For_18inch_Spacer_Wood_Side_Panel002",
			"DOB30T977S_-_Wall_Oven_Single_L_Shape_xxm_xxm",
			"Backwall_Toe-Kick_xxm",
			"Kitchen_Counter_Induction_Cooktop",
			"Kitchen_Counter_for_DTG30P875N",
			"Kitchen_Counter_for_DTG30P875N003",
			"Kitchen_Counter_for_DTG30P875N001",
			"Kitchen_Counter_4_DTG30P875NDTI30M977BBDTI36P876BB",
			"Kitchen_Counter_for_DTG30P875N002",
			"Kitchen_Counter_for_DTG30P875N_or_DTI30M977BB",
			"Kitchen_Counter_for_DTG30P875NDTI30M977BBDTI36P876BB",
			"Kitchen_Counter_for_DTI36M977BB",
			"Kitchen_Counter_for_DTT36T960G",
			"Kitchen_Counter_for_DTG30P875N_or_DTI30M977BB001",
			"Left_Counter_for_18inc_Left_Counter",
			"For_18inch_Spacer_Wood_Side_Panel",
			"For_18inch_Spacer_Wood_Side_Panel003",
			"For_18inch_Spacer_Wood_Side_Panel003",
			"For_18inch_Spacer_Wood_Side_Panel008",
			"For_18inch_Spacer_Wood_Side_Panel015",
			"For_18inch_Spacer_Wood_Side_Panel006",
			"For_18inch_Spacer_Wood_Side_Panel007",
			"For_18inch_Spacer_Wood_Side_Panel016",
			"For_18inch_Spacer_Wood_Side_Panel017",
			"For_18inch_Spacer_Wood_Side_Panel018",
			"For_18inch_Spacer_Wood_Side_Panel014",
			"Left_Counter_for_12inc_Left_Counter_D29",
			"Let_Counter_for_12inc_Left_Counter",
			"For_12inch_Spacer_Wood_Side_Panel002",
			"For_12inch_Spacer_Wood_Side_Panel",
		],
		geometryToDisplayKitchen: [
			"24_LEFT_for_48imch_Cooktop_Island_xxm",
			"24_LEFT_for_48imch_Cooktop_Island001_xxm",
			"18_LEFT002_xxm",
			"18_LEFT008_xxm",
			"13_inch_Drawer001_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom002_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom004_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom006_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom001_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom003_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom005_xxm",
			"18_RIGHT002_xxm",
			"18_RIGHT003_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island001_xxm",
			"18_RIGHT_for_30W_xxm",
			"12_RIGHT009_xxm",
			"12_RIGHT010_xxm",
			"12_RIGHT012_xxm",
			"12_RIGHT006_xxm",
			"12_RIGHT001_xxm",
			"12_RIGHT008_xxm",
			"12_RIGHT004_xxm",
			"12_RIGHT002_xxm",
			"12_RIGHT007_xxm",
			"12_RIGHT_xxm",
			"12_RIGHT011_xxm",
			"12_RIGHT005_xxm",
			"12_RIGHT003_xxm",
		],

		geometryToHideIsland: [
			"24_LEFT_for_48imch_Cooktop_Island_xxm",
			"24_LEFT_for_48imch_Cooktop_Island001_xxm",
			"18_LEFT002_xxm",
			"18_LEFT008_xxm",
			"13_inch_Drawer001_xxm",

			"DOB30T977S_-_Wall_Oven_Single_Bottom002_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom004_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom006_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom001_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom003_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom005_xxm",
			"18_RIGHT002_xxm",
			"18_RIGHT003_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island001_xxm",
			"18_RIGHT_for_30W_xxm",
			"12_RIGHT009_xxm",
			"12_RIGHT010_xxm",
			"12_RIGHT012_xxm",
			"12_RIGHT006_xxm",
			"12_RIGHT001_xxm",
			"12_RIGHT008_xxm",
			"12_RIGHT004_xxm",
			"12_RIGHT002_xxm",
			"12_RIGHT007_xxm",
			"12_RIGHT_xxm",
			"12_RIGHT011_xxm",
			"12_RIGHT005_xxm",
			"12_RIGHT003_xxm",
		],
		geometryToDisplayIsland: [
			"12inch_48inch002_xxm",
			"12inch_48inch001_xxm",
			"Panel_H262_Panel_xxm",
			"L1_BOTTOM011_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single002_xxm",
			"LL3_BOTTOM_K_xxm",
			"L1_BOTTOM002_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_xxm",
			"For_18inch_Spacer_Wood_Side_Panel002",
			"DOB30T977S_-_Wall_Oven_Single_L_Shape_xxm_xxm",
			"Backwall_Toe-Kick_xxm",
			"Kitchen_Counter_Induction_Cooktop",
			"Kitchen_Counter_for_DTG30P875N",
			"Kitchen_Counter_for_DTG30P875N003",
			"Kitchen_Counter_for_DTG30P875N001",
			"Kitchen_Counter_4_DTG30P875NDTI30M977BBDTI36P876BB",
			"Kitchen_Counter_for_DTG30P875N002",
			"Kitchen_Counter_for_DTG30P875N_or_DTI30M977BB",
			"Kitchen_Counter_for_DTG30P875NDTI30M977BBDTI36P876BB",
			"Kitchen_Counter_for_DTI36M977BB",
			"Kitchen_Counter_for_DTT36T960G",
			"Kitchen_Counter_for_DTG30P875N_or_DTI30M977BB001",
			"Left_Counter_for_18inc_Left_Counter",
			"For_18inch_Spacer_Wood_Side_Panel",
			"For_18inch_Spacer_Wood_Side_Panel003",
			"For_18inch_Spacer_Wood_Side_Panel003",
			"For_18inch_Spacer_Wood_Side_Panel008",
			"For_18inch_Spacer_Wood_Side_Panel015",
			"For_18inch_Spacer_Wood_Side_Panel006",
			"For_18inch_Spacer_Wood_Side_Panel007",
			"For_18inch_Spacer_Wood_Side_Panel016",
			"For_18inch_Spacer_Wood_Side_Panel017",
			"For_18inch_Spacer_Wood_Side_Panel018",
			"For_18inch_Spacer_Wood_Side_Panel014",
			"Left_Counter_for_12inc_Left_Counter_D29",
			"Let_Counter_for_12inc_Left_Counter",
			"For_12inch_Spacer_Wood_Side_Panel002",
			"For_12inch_Spacer_Wood_Side_Panel",
		],
		geometriesToShowKitchen2And3: [],
		geometriesToShowIsland2And3: [],

	},

	//Gas Range - Checked
	DOP36M96GLS: {
		manual: true,
		position: [-3.45, -0.931, 3.605],
		rotation: [0, 0, 0],
		positionInKitchenSink: [-3.45, -0.931, 3.605],
		positionInIsland: [1.745, -0.94, -6.28],
		rotationInKitchenSink: [0, 0, 0],
		rotationInIsland: [0, 180, 0],
		geometryToHideKitchen: [

		],
		geometryToDisplayKitchen: [
			"LL2BOTTOM_18inch_xxm",
			"Kitchen_Counter_for_DTT36T960G",
			"L1_TOP011_xxm",
			"L1_BOTTOM011_xxm",
			"LL2TOP_18inch014_xxm"
		],

		geometryToHideIsland: [
			"Island_Counter_for_DOP48M86D",
			"Counter_No_Whole_29Depth",
			"Left_Counter_48in_Range_001",
			"Island_Counter_Whole_for_DTI30M977BB_",
			"Island_Counter_for_DOP48T960G_",
			"Island_Counter_Whole010"
		],
		geometryToDisplayIsland: [
			"RIGHT_DRAWER_Island_12_Inch002_xxm",
			"8_RIGHT001_xxm",
			"12_RIGHT002_xxm",
			"Island_Counter_for_DOP36M86D"
		],
		geometriesToShowKitchen2And3: [
			"BW_DYN_DOP36M96GL"
		],
		geometriesToShowIsland2And3: [
			"ISL_DYN_DOP36C86D"
		],

	},

	DOP36C96GLS: {
		manual: true,
		position: [-3.45, -0.931, 3.605],
		rotation: [0, 0, 0],
		positionInKitchenSink: [-3.45, -0.931, 3.605],
		positionInIsland: [1.745, -0.94, -6.28],
		rotationInKitchenSink: [0, 0, 0],
		rotationInIsland: [0, 180, 0],
		geometryToHideKitchen: [

		],
		geometryToDisplayKitchen: [
			"LL2BOTTOM_18inch_xxm",
			"Kitchen_Counter_for_DTT36T960G",
			"L1_TOP011_xxm",
			"L1_BOTTOM011_xxm",
			"LL2TOP_18inch014_xxm"
		],

		geometryToHideIsland: [
			"Island_Counter_for_DOP48M86D",
			"Counter_No_Whole_29Depth",
			"Left_Counter_48in_Range_001",
			"Island_Counter_Whole_for_DTI30M977BB_",
			"Island_Counter_for_DOP48T960G_",
			"Island_Counter_Whole010"
		],
		geometryToDisplayIsland: [
			"RIGHT_DRAWER_Island_12_Inch002_xxm",
			"8_RIGHT001_xxm",
			"12_RIGHT002_xxm",
			"Island_Counter_for_DOP36M86D"
		],
		geometriesToShowKitchen2And3: [
			"BW_DYN_DOP36M96GL"
		],
		geometriesToShowIsland2And3: [
			"ISL_DYN_DOP36C86D"
		],

	},

	DOP36C86DLM: {
		manual: true,
		position: [-1.45, -0.471, -2.34],
		rotation: [0, 0, 0],
		positionInKitchenSink: [-1.45, -0.471, -2.34],
		positionInIsland: [-0.257, -0.481, -0.333],
		rotationInKitchenSink: [0, 0, 0],
		rotationInIsland: [0, 180, 0],
		geometryToHideKitchen: [

		],
		geometryToDisplayKitchen: [
			"LL2BOTTOM_18inch_xxm",
			"Kitchen_Counter_for_DTT36T960G",
			"L1_TOP011_xxm",
			"L1_BOTTOM011_xxm",
			"LL2TOP_18inch014_xxm"
		],
		geometryToHideIsland: [
			"Island_Counter_for_DOP48T960G_",
			"Island_Counter_Whole010",
			"Island_Counter_for_DOP48M86D",
			"Counter_No_Whole_29Depth",
			"Left_Counter_48in_Range_001",
			"Island_Counter_Whole_for_DTI30M977BB_"

		],
		geometryToDisplayIsland: [
			"RIGHT_DRAWER_Island_12_Inch002_xxm",
			"8_RIGHT001_xxm",
			"12_RIGHT002_xxm",
			"Island_Counter_for_DOP36M86D"
		],
		geometriesToShowKitchen2And3: [
			"BW_DYN_DOP36M96GL"
		],
		geometriesToShowIsland2And3: [
			"ISL_DYN_DOP36C86D",
		],

	},

	//Gas Range - Checked
	DOP36M96GLM: {
		manual: true,
		position: [-1.45, -0.931, 3.61],
		rotation: [0, 0, 0],
		positionInKitchenSink: [-1.45, -0.931, 3.61],
		positionInIsland: [-0.25400000000000006, -0.942, -6.282],
		rotationInKitchenSink: [0, 0, 0],
		rotationInIsland: [0, 180, 0],
		geometryToHideKitchen: [

		],
		geometryToDisplayKitchen: [
			"LL2BOTTOM_18inch_xxm",
			"Kitchen_Counter_for_DTT36T960G",
			"L1_TOP011_xxm",
			"L1_BOTTOM011_xxm",
			"LL2TOP_18inch014_xxm"
		],

		geometryToHideIsland: [
			"Island_Counter_for_DOP48T960G_",
			"Island_Counter_for_DOP48M86D",
			"Island_Counter_Whole010",
			"Counter_No_Whole_29Depth",
			"Left_Counter_48in_Range_001",
			"Island_Counter_Whole_for_DTI30M977BB_"

		],
		geometryToDisplayIsland: [
			"RIGHT_DRAWER_Island_12_Inch002_xxm",
			"Island_Counter_for_DOP36M86D",
			"8_RIGHT001_xxm",
			"12_RIGHT002_xxm",
		],
		geometriesToShowKitchen2And3: [
			"BW_DYN_DOP36M96GL"
		],
		geometriesToShowIsland2And3: [
			"ISL_DYN_DOP36C86D"
		],

	},

	DOP36C96GLM: {
		manual: true,
		position: [-1.45, -0.931, 3.61],
		rotation: [0, 0, 0],
		positionInKitchenSink: [-1.45, -0.931, 3.61],
		positionInIsland: [-0.25400000000000006, -0.942, -6.282],
		rotationInKitchenSink: [0, 0, 0],
		rotationInIsland: [0, 180, 0],
		geometryToHideKitchen: [

		],
		geometryToDisplayKitchen: [
			"LL2BOTTOM_18inch_xxm",
			"Kitchen_Counter_for_DTT36T960G",
			"L1_TOP011_xxm",
			"L1_BOTTOM011_xxm",
			"LL2TOP_18inch014_xxm"
		],

		geometryToHideIsland: [
			"Island_Counter_for_DOP48T960G_",
			"Island_Counter_for_DOP48M86D",
			"Island_Counter_Whole010",
			"Counter_No_Whole_29Depth",
			"Left_Counter_48in_Range_001",
			"Island_Counter_Whole_for_DTI30M977BB_"

		],
		geometryToDisplayIsland: [
			"RIGHT_DRAWER_Island_12_Inch002_xxm",
			"Island_Counter_for_DOP36M86D",
			"8_RIGHT001_xxm",
			"12_RIGHT002_xxm",
		],
		geometriesToShowKitchen2And3: [
			"BW_DYN_DOP36M96GL"
		],
		geometriesToShowIsland2And3: [
			"ISL_DYN_DOP36C86D"
		],

	},

	//Dual Fuel Steam Range - Checked
	DOP36M86DLM: {
		manual: true,
		position: [-1.5, -0.93, -2.43],
		rotation: [0, 0, 0],
		positionInKitchenSink: [-1.5, -0.93, -2.43],
		positionInIsland: [-0.28, -0.94, -0.26],
		rotationInKitchenSink: [0, 0, 0],
		rotationInIsland: [0, 180, 0],
		geometryToHide: [],
		geometryToDisplay: [
			"Island_Counter_for_DOP36M86D",
			"RIGHT_DRAWER_Island_12_Inch_for_DOP36M86D_xxm",
			"12_RIGHT011_xxm",
		],
		geometriesToShowKitchen2And3: [],
		geometriesToShowIsland2And3: [],

	},
	//Dual Fuel Steam Range - Checked
	DOP36M86DS: {
		manual: true,
		position: [-1.5, -0.93, -2.43],
		rotation: [0, 0, 0],
		positionInKitchenSink: [-1.5, -0.93, -2.43],
		positionInIsland: [-0.28, -0.94, -0.26],
		rotationInKitchenSink: [0, 0, 0],
		rotationInIsland: [0, 180, 0],
		geometryToHide: [],
		geometryToDisplay: [
			"Island_Counter_for_DOP36M86D",
			"RIGHT_DRAWER_Island_12_Inch_for_DOP36M86D_xxm",
			"12_RIGHT011_xxm",
		],
		geometriesToShowKitchen2And3: [],
		geometriesToShowIsland2And3: [],

	},

	DOP36M86DLS: {
		manual: true,
		position: [-1.53, -0.45, -2.38],
		rotation: [0, 0, 0],
		positionInKitchenSink: [-1.53, -0.45, -2.38],
		positionInIsland: [-0.28, -0.94, -0.26],
		rotationInKitchenSink: [0, 0, 0],
		rotationInIsland: [0, 180, 0],
		geometryToHide: [],
		geometryToDisplay: [
			"Island_Counter_for_DOP36M86D",
			"RIGHT_DRAWER_Island_12_Inch_for_DOP36M86D_xxm",
			"12_RIGHT011_xxm",
		],
		geometriesToShowKitchen2And3: [],
		geometriesToShowIsland2And3: [],

	},

	DOP36C86DLS: {
		manual: true,
		position: [-1.45, -0.471, -2.34],
		rotation: [0, 0, 0],
		positionInKitchenSink: [-1.45, -0.471, -2.34],
		positionInIsland: [-0.26, -0.48, -0.333],
		rotationInKitchenSink: [0, 0, 0],
		rotationInIsland: [0, 180, 0],
		geometryToHideKitchen: [

		],
		geometryToDisplayKitchen: [
			"LL2BOTTOM_18inch_xxm",
			"Kitchen_Counter_for_DTT36T960G",
			"L1_TOP011_xxm",
			"L1_BOTTOM011_xxm",
			"LL2TOP_18inch014_xxm",

		],

		geometryToHideIsland: [
			"Island_Counter_Whole010",
			"Island_Counter_for_DOP48M86D",
			"Counter_No_Whole_29Depth",
			"Left_Counter_48in_Range_001",
			"Island_Counter_Whole_for_DTI30M977BB_",
			"Island_Counter_for_DOP48T960G_"
		],
		geometryToDisplayIsland: [
			"RIGHT_DRAWER_Island_12_Inch002_xxm",
			"8_RIGHT001_xxm",
			"12_RIGHT002_xxm",
			"Island_Counter_for_DOP36M86D"
		],
		geometriesToShowKitchen2And3: [
			"BW_DYN_DOP36M96GL"
		],
		geometriesToShowIsland2And3: [
			"ISL_DYN_DOP36C86D"
		],

	},

	//Induction Cooktop - Checked
	DTI36M977BB: {
		manual: true,
		positionInKitchenSink: [-1.43, -0.09, 0.55],
		positionInIsland: [-0.26, -0.11, -3.26],
		rotationKitchen: [0, 0, 0],
		rotationIsland: [0, 0, 0],

		geometryToHideKitchen: [],
		geometryToDisplayKitchen: [
			"Kitchen_Counter_for_DTI36M977BB",
			"Panel_H262_Panel_xxm",
			"L1_BOTTOM011_xxm",
			"L1_TOP_xxm",
			"LL2_TOP_K_xxm",
			"LL2_BOTTOM_K_xxm",
			"LL3_TOP_K_xxm",
		],

		geometryToHideIsland: [],
		geometryToDisplayIsland: [
			"RIGHT_DRAWER_Island_12_Inch001_xxm",
			"12_RIGHT_xxm",
			"Island_Counter_for_DTI36M977BB",
			"LEFT_DRAWER_for_Island_MicroWaves001_xxm",
			"LEFT_DRAWER_for_Island_MicroWaves_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_Panel_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_Panel001_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_Panel002_xxm",
			"18_RIGHT002_xxm",
			"18_RIGHT003_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island001_xxm",
			"8_LEFT001_xxm",
			"8_LEFT_xxm",
			"24_LEFT_for_48imch_Cooktop_Island_xxm",
			"24_LEFT_for_48imch_Cooktop_Island001_xxm",
			"18_LEFT002_xxm",
			"18_LEFT008_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom002_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom004_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom006_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom001_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom003_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom005_xxm"
		],
		geometriesToShowKitchen2And3: [
			'BW_DYN_DTG36M955F_DTG36P875N'
		],
		geometriesToShowIsland2And3: [
			"ISL_DYN_DTG36M955F_DTG36P875N"
		],

	},
	//Induction Cooktop - Checked
	DTI30M977BB: {
		manual: true,
		position: [-0.259, -0.11, 1.74],
		rotation: [0, 0, 0],
		positionInKitchenSink: [-1.45, -0.101, -0.41],
		positionInIsland: [-0.259, -0.11, 1.74],
		rotationInIsland: [0, 0, 0],
		rotationInKitchen: [0, 180, 0],
		geometryToDisplayKitchen: [
			"Kitchen_Counter_for_DTI36M977BB",
			"Panel_H262_Panel_xxm",
			"L1_BOTTOM011_xxm",
			"L1_TOP_xxm",
			"LL2_TOP_K_xxm",
			"LL2_BOTTOM_K_xxm",
			"LL3_TOP_K_xxm",
		],
		geometryToDisplayIsland: [
			"RIGHT_DRAWER_Island_12_Inch001_xxm",
			"12_RIGHT_xxm",
			"Island_Counter_Whole003",
			"LEFT_DRAWER_for_Island_MicroWaves001_xxm",
			"LEFT_DRAWER_for_Island_MicroWaves_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_Panel_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_Panel001_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_Panel002_xxm",
			"18_RIGHT002_xxm",
			"18_RIGHT003_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island001_xxm",
			"8_LEFT001_xxm",
			"8_LEFT_xxm",
			"24_LEFT_for_48imch_Cooktop_Island_xxm",
			"24_LEFT_for_48imch_Cooktop_Island001_xxm",
			"18_LEFT002_xxm",
			"18_LEFT008_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom002_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom004_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom006_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom001_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom003_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom005_xxm"
		],
		geometriesToShowKitchen2And3: [
			"BW_DYN_DTG30M954F_DTG30P875N"
		],
		geometriesToShowIsland2And3: [
			"ISL_DYN_DTG30M954F_DTG30P875N"
		],

	},

	//Gas cooktop - Checked
	DTG36M955FM: {
		manual: true,
		position: [-0.31, -0.18, 0.75],
		rotation: [0, 0, 0],
		positionInKitchenSink: [-1.45, -0.17, -1.45],
		positionInIsland: [-0.31, -0.18, 0.75],
		rotationInKitchen: [0, 0, 0],
		rotationInIsland: [0, 0, 0],
		geometryToDisplayKitchen: [
			"Kitchen_Counter_for_DTG36M955F_2",
			"Kitchen_Counter_for_DTG36M955F_1",
			"Panel_H262_Panel_xxm",
			"L1_BOTTOM011_xxm",
			"L1_TOP_xxm",
			"LL2_TOP_K_xxm",
			"LL2_BOTTOM_K_xxm",
			"LL3_TOP_K_xxm",
		],
		geometryToDisplayIsland: [
			"RIGHT_DRAWER_Island_12_Inch001_xxm",
			"12_RIGHT_xxm",
			"Island_Counter_for_DTI36M977BB",
			"LEFT_DRAWER_for_Island_MicroWaves001_xxm",
			"LEFT_DRAWER_for_Island_MicroWaves_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_Panel_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_Panel001_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_Panel002_xxm",
			"18_RIGHT002_xxm",
			"18_RIGHT003_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island001_xxm",
			"8_LEFT001_xxm",
			"8_LEFT_xxm",
			"24_LEFT_for_48imch_Cooktop_Island_xxm",
			"24_LEFT_for_48imch_Cooktop_Island001_xxm",
			"18_LEFT002_xxm",
			"18_LEFT008_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom002_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom004_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom006_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom001_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom003_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom005_xxm"
		],
		geometriesToShowKitchen2And3: [
			'BW_DYN_DTG36M955F_DTG36P875N',
			'BW_DYN_noApp'
		],
		geometriesToShowIsland2And3: [
			"ISL_DYN_DTG36M955F_DTG36P875N",
			"ISL_DYN_noApp"
		],

	},
	//Gas cooktop - Checked
	DTG36M955FS: {
		manual: true,
		position: [-2.3, -0.18, 0.75],
		rotation: [0, 0, 0],
		positionInKitchenSink: [-3.456, -0.171, -1.45],
		positionInIsland: [-2.3, -0.18, 0.75],
		rotationInKitchen: [0, 180, 0],
		rotationInIsland: [0, 0, 0],

		geometryToDisplayKitchen: [
			"Kitchen_Counter_for_DTG36M955F_2",
			"Kitchen_Counter_for_DTG36M955F_1",
			"Panel_H262_Panel_xxm",
			"L1_BOTTOM011_xxm",
			"L1_TOP_xxm",
			"LL2_TOP_K_xxm",
			"LL2_BOTTOM_K_xxm",
			"LL3_TOP_K_xxm",
		],
		geometryToDisplayIsland: [
			"RIGHT_DRAWER_Island_12_Inch001_xxm",
			"12_RIGHT_xxm",
			"Island_Counter_for_DTI36M977BB",
			"LEFT_DRAWER_for_Island_MicroWaves001_xxm",
			"LEFT_DRAWER_for_Island_MicroWaves_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_Panel_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_Panel001_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_Panel002_xxm",
			"18_RIGHT002_xxm",
			"18_RIGHT003_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island001_xxm",
			"8_LEFT001_xxm",
			"8_LEFT_xxm",
			"24_LEFT_for_48imch_Cooktop_Island_xxm",
			"24_LEFT_for_48imch_Cooktop_Island001_xxm",
			"18_LEFT002_xxm",
			"18_LEFT008_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom002_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom004_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom006_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom001_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom003_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom005_xxm"
		],
		geometriesToShowKitchen2And3: [
			'BW_DYN_DTG36M955F_DTG36P875N',
			'BW_DYN_noApp'
		],
		geometriesToShowIsland2And3: [
			"ISL_DYN_DTG36M955F_DTG36P875N",
			"ISL_DYN_noApp"
		],

	},
	//Gas cooktop - Checked
	DTG30M954FS: {
		manual: true,
		position: [-2.305, -0.18, -0.26],
		positionInKitchenSink: [-3.5, -0.169, -2.43],
		positionInIsland: [-2.305, -0.18, -0.26],
		rotationInKitchen: [0, 0, 0],
		rotationInIsland: [0, 0, 0],
		geometryToDisplayKitchen: [
			"Kitchen_Counter_DTG30M954FS_1",
			"Kitchen_Counter_DTG30M954FS_2",
			"Panel_H262_Panel_xxm",
			"L1_BOTTOM011_xxm",
			"L1_TOP_xxm",
			"LL2_TOP_K_xxm",
			"LL2_BOTTOM_K_xxm",
			"LL3_TOP_K_xxm",
		],
		geometryToDisplayIsland: [
			"RIGHT_DRAWER_Island_12_Inch001_xxm",
			"12_RIGHT_xxm",
			"Island_Counter_Whole003",
			"LEFT_DRAWER_for_Island_MicroWaves001_xxm",
			"LEFT_DRAWER_for_Island_MicroWaves_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_Panel_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_Panel001_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_Panel002_xxm",
			"18_RIGHT002_xxm",
			"18_RIGHT003_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island001_xxm",
			"8_LEFT001_xxm",
			"8_LEFT_xxm",
			"24_LEFT_for_48imch_Cooktop_Island_xxm",
			"24_LEFT_for_48imch_Cooktop_Island001_xxm",
			"18_LEFT002_xxm",
			"18_LEFT008_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom002_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom004_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom006_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom001_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom003_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom005_xxm"
		],
		geometriesToShowKitchen2And3: [
			'BW_DYN_noApp',
			'BW_DYN_DTG30M954F_DTG30P875N'
		],
		geometriesToShowIsland2And3: [
			"ISL_DYN_noApp",
			"ISL_DYN_DTG30M954F_DTG30P875N"
		],

	},
	//Gas cooktop - Checked
	DTG30M954FM: {
		manual: true,
		position: [-0.214, -0.18, -0.28],
		positionInKitchenSink: [-1.5, -0.169, -2.43],
		positionInIsland: [-0.214, -0.18, -0.28],
		rotationKitchen: [0, 0, 0],
		rotationIsland: [0, 0, 0],
		geometryToDisplayKitchen: [
			"Kitchen_Counter_DTG30M954FS_1",
			"Kitchen_Counter_DTG30M954FS_2",
			"Panel_H262_Panel_xxm",
			"L1_BOTTOM011_xxm",
			"L1_TOP_xxm",
			"LL2_TOP_K_xxm",
			"LL2_BOTTOM_K_xxm",
			"LL3_TOP_K_xxm",
		],
		geometryToDisplayIsland: [
			"RIGHT_DRAWER_Island_12_Inch001_xxm",
			"12_RIGHT_xxm",
			"Island_Counter_Whole003",
			"LEFT_DRAWER_for_Island_MicroWaves001_xxm",
			"LEFT_DRAWER_for_Island_MicroWaves_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_Panel_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_Panel001_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_Panel002_xxm",
			"18_RIGHT002_xxm",
			"18_RIGHT003_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island001_xxm",
			"8_LEFT001_xxm",
			"8_LEFT_xxm",
			"24_LEFT_for_48imch_Cooktop_Island_xxm",
			"24_LEFT_for_48imch_Cooktop_Island001_xxm",
			"18_LEFT002_xxm",
			"18_LEFT008_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom002_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom004_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom006_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom001_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom003_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom005_xxm"
		],
		geometriesToShowKitchen2And3: [
			'BW_DYN_noApp',
			'BW_DYN_DTG30M954F_DTG30P875N'
		],
		geometriesToShowIsland2And3: [
			"ISL_DYN_noApp",
			"ISL_DYN_DTG30M954F_DTG30P875N"
		],

	},

	//Pro-Canopy Wall Hood - Checked
	DHD30U990CS: {
		manual: true,
		position: [-0.45, 0.84, 1.8],
		rotation: [0, 0, 0],
		geometryToHide: [
			"Cabinet_for_Microwave_Vent",
			"Panel_Cabinet_243W_x_255H_1008",
			"Panel_Cabinet_243W_x_255H_1009",
			"Blank_Cabinet_Box_for_30",
			"Blank_Cabinet_Box001",
			"Blank_Cabinet_Box002",
			"Panel_Cabinet_243W_x_255H_1004_xxm",
			'Panel_Cabinet_243W_x_255H_1010_xxm',
			'Panel_Cabinet_275W_x_255H001_xxm',
		],
		geometryToDisplay: [
			"Panel_Cabinet_243W_x_255H_1019_xxm",
			"Panel_Cabinet_243W_x_255H_1015_xxm",
			"Panel_Cabinet_243W_x_255H_1017_xxm",

			'Panel_Cabinet_243W_x_255H_1028_xxm',
			"Panel_Cabinet_275W_x_255H002_xxm",
			"Panel_Cabinet_275W_x_255H004_xxm",
			"Panel_Cabinet_243W_x_255H_1022_xxm",
			"Panel_Cabinet_243W_x_255H_1031_xxm",
			"Panel_Cabinet_243W_x_255H_1034_xxm",
			"Panel_Cabinet_243W_x_255H_1025_xxm",
			"Panel_Cabinet_243W_x_255H_1032_xxm",
			"Panel_Cabinet_243W_x_255H_1035_xxm",
			"Panel_Cabinet_243W_x_255H_1033_xxm",
			"Panel_Cabinet_243W_x_255H_1036_xxm",
		],
		geometriesToShow2And3: [
			"Cabinets_Right015_1",
			"Cabinets_Right015",
			"BWU_DYN_base",
			"BWU_DYN_wall"
		],

	},

	//Hood Liners
	DHD30U790LS: {
		manual: true,
		position: [-0.45, 0.704, -0.62],
		rotation: [0, 0, 0],
		geometryToHide: [],
		geometryToDisplay: [
			"Panel_Cabinet_243W_x_255H_1008_xxm",
			"Panel_Cabinet_243W_x_255H_1009_xxm",
			"Panel_Cabinet_243W_x_255H_1010_xxm",
			"Panel_Cabinet_275W_x_255H002_xxm",
			"Panel_Cabinet_275W_x_255H004_xxm",
			"Cabinets_Right001",
			"Cabinets_Right010",
			"Cabinets_Right002",
			"Panel_Cabinet_243W_x_255H_1031_xxm",
			"Panel_Cabinet_243W_x_255H_1034_xxm",
			"Panel_Cabinet_243W_x_255H_1032_xxm",
			"Panel_Cabinet_243W_x_255H_1035_xxm",
			"Panel_Cabinet_243W_x_255H_1033_xxm",
			"Panel_Cabinet_243W_x_255H_1036_xxm"
		],
		geometriesToShow2And3: [
			"Cabinets_Right015_1",
			"Cabinets_Right015",
			"BWU_DYN_base",
			"BWU_DYN_wall"
		],

	},
	//Hood Liners
	DHD36U790LS: {
		manual: true,
		position: [-2.45, 0.704, -0.62],
		rotation: [0, 0, 0],
		geometryToHide: [],
		geometryToDisplay: [
			"Panel_Cabinet_243W_x_255H_1008_xxm",
			"Panel_Cabinet_243W_x_255H_1009_xxm",
			"Panel_Cabinet_243W_x_255H_1010_xxm",
			"Panel_Cabinet_275W_x_255H002_xxm",
			"Panel_Cabinet_275W_x_255H004_xxm",
			"Cabinets_Right001",
			"Cabinets_Right010",
			"Cabinets_Right002",
			"Panel_Cabinet_243W_x_255H_1031_xxm",
			"Panel_Cabinet_243W_x_255H_1034_xxm",
			"Panel_Cabinet_243W_x_255H_1032_xxm",
			"Panel_Cabinet_243W_x_255H_1035_xxm",
			"Panel_Cabinet_243W_x_255H_1033_xxm",
			"Panel_Cabinet_243W_x_255H_1036_xxm"
		],
		geometriesToShow2And3: [
			"Cabinets_Right015_1",
			"Cabinets_Right015",
			"BWU_DYN_base",
			"BWU_DYN_wall"
		],

	},
	//Hood Liners
	DHD48U790LS: {
		manual: true,
		position: [-4.45, 0.707, -0.62],
		rotation: [0, 0, 0],
		geometryToHide: [],
		geometryToDisplay: [

			"Panel_Cabinet_243W_x_255H_1008_xxm",
			"Panel_Cabinet_243W_x_255H_1009_xxm",
			"Panel_Cabinet_243W_x_255H_1010_xxm",
			"Panel_Cabinet_275W_x_255H002_xxm",
			"Panel_Cabinet_275W_x_255H004_xxm",
			"Cabinets_Right001",
			"Cabinets_Right010",
			"Cabinets_Right002",
			"Panel_Cabinet_243W_x_255H_1031_xxm",
			"Panel_Cabinet_243W_x_255H_1034_xxm",
			"Panel_Cabinet_243W_x_255H_1032_xxm",
			"Panel_Cabinet_243W_x_255H_1035_xxm",
			"Panel_Cabinet_243W_x_255H_1033_xxm",
			"Panel_Cabinet_243W_x_255H_1036_xxm"
		],
		geometriesToShow2And3: [
			"Cabinets_Right015_1",
			"Cabinets_Right015",
			"BWU_DYN_base",
			"BWU_DYN_wall"
		],

	},
	//Pro-Canopy Wall Hood - Checked
	DHD30U990CM: {
		manual: true,
		position: [-0.45, 0.84, 1.8],
		rotation: [0, 0, 0],
		geometryToHide: [
			"Cabinet_for_Microwave_Vent",
			"Panel_Cabinet_243W_x_255H_1008",
			"Panel_Cabinet_243W_x_255H_1009",
			"Blank_Cabinet_Box_for_30",
			"Blank_Cabinet_Box001",
			"Blank_Cabinet_Box002",
			"Panel_Cabinet_243W_x_255H_1004_xxm",
			'Panel_Cabinet_243W_x_255H_1010_xxm',
			'Panel_Cabinet_275W_x_255H001_xxm',
		],
		geometryToDisplay: [
			"Panel_Cabinet_243W_x_255H_1019_xxm",
			"Panel_Cabinet_243W_x_255H_1015_xxm",
			"Panel_Cabinet_243W_x_255H_1017_xxm",
			"Cabinets_Right010",
			'Panel_Cabinet_243W_x_255H_1028_xxm',
			"Panel_Cabinet_275W_x_255H002_xxm",
			"Panel_Cabinet_275W_x_255H004_xxm",
			"Panel_Cabinet_243W_x_255H_1022_xxm",
			"Panel_Cabinet_243W_x_255H_1031_xxm",
			"Panel_Cabinet_243W_x_255H_1034_xxm",
			"Panel_Cabinet_243W_x_255H_1025_xxm",
			"Panel_Cabinet_243W_x_255H_1032_xxm",
			"Panel_Cabinet_243W_x_255H_1035_xxm",
			"Panel_Cabinet_243W_x_255H_1033_xxm",
			"Panel_Cabinet_243W_x_255H_1036_xxm",
		],
		geometriesToShow2And3: [
			"Cabinets_Right015_1",
			"Cabinets_Right015",
		],

	},
	//Pro-Canopy Wall Hood - Checked
	DHD36U990CM: {
		manual: true,
		position: [-2.45, 0.84, 1.8],

		rotation: [0, 0, 0],
		geometryToHide: [
			"Cabinet_for_Microwave_Vent",
			"Panel_Cabinet_243W_x_255H_1008",
			"Panel_Cabinet_243W_x_255H_1009",
			"Blank_Cabinet_Box_for_30",
			"Blank_Cabinet_Box001",
			"Blank_Cabinet_Box002",
			"Panel_Cabinet_243W_x_255H_1018_xxm",
			"Panel_Cabinet_243W_x_255H_1004_xxm",
			"Panel_Cabinet_243W_x_255H_1010_xxm",
			"Panel_Cabinet_243W_x_255H_1007_xxm",
			"Panel_Cabinet_243W_x_255H_1017_xxm",
			"Panel_Cabinet_243W_x_255H_1016_xxm",
			"Panel_Cabinet_243W_x_255H_1_xxm",
			"Cabinets_Right005",
		],
		geometryToDisplay: [
			"Panel_Cabinet_243W_x_255H_1021_xxm",
			"Panel_Cabinet_243W_x_255H_1012_xxm",
			"Panel_Cabinet_243W_x_255H_1014_xxm",
			"Panel_Cabinet_275W_x_255H002_xxm",
			"Panel_Cabinet_275W_x_255H004_xxm",
			"Cabinets_Right001",
			"Cabinets_Right010",
			"Cabinets_Right002",
			"Panel_Cabinet_243W_x_255H_1031_xxm",
			"Panel_Cabinet_243W_x_255H_1034_xxm",
			"Panel_Cabinet_243W_x_255H_1032_xxm",
			"Panel_Cabinet_243W_x_255H_1035_xxm",
			"Panel_Cabinet_243W_x_255H_1033_xxm",
			"Panel_Cabinet_243W_x_255H_1036_xxm"
		],
		geometriesToShow2And3: [
			"Cabinets_Right015_1",
			"Cabinets_Right015",
			"BWU_DYN_base",
			"BWU_DYN_wall"
		],

	},

	//Pro-Canopy Wall Hood - Checked
	DHD36U990CS: {
		manual: true,
		position: [-2.45, 0.84, 1.8],
		rotation: [0, 0, 0],
		geometryToHide: [
			"Cabinet_for_Microwave_Vent",
			"Panel_Cabinet_243W_x_255H_1008",
			"Panel_Cabinet_243W_x_255H_1009",
			"Blank_Cabinet_Box_for_30",
			"Blank_Cabinet_Box001",
			"Blank_Cabinet_Box002",
			"Panel_Cabinet_243W_x_255H_1018_xxm",
			"Panel_Cabinet_243W_x_255H_1004_xxm",
			"Panel_Cabinet_243W_x_255H_1010_xxm",
			"Panel_Cabinet_243W_x_255H_1007_xxm",
			"Panel_Cabinet_243W_x_255H_1017_xxm",
			"Panel_Cabinet_243W_x_255H_1016_xxm",
			"Panel_Cabinet_243W_x_255H_1_xxm",
			"Cabinets_Right005",
		],
		geometryToDisplay: [
			"Panel_Cabinet_243W_x_255H_1021_xxm",
			"Panel_Cabinet_243W_x_255H_1012_xxm",
			"Panel_Cabinet_243W_x_255H_1014_xxm",
			"Panel_Cabinet_275W_x_255H002_xxm",
			"Panel_Cabinet_275W_x_255H004_xxm",
			"Cabinets_Right001",
			"Cabinets_Right010",
			"Cabinets_Right002",
			"Panel_Cabinet_243W_x_255H_1031_xxm",
			"Panel_Cabinet_243W_x_255H_1034_xxm",
			"Panel_Cabinet_243W_x_255H_1032_xxm",
			"Panel_Cabinet_243W_x_255H_1035_xxm",
			"Panel_Cabinet_243W_x_255H_1033_xxm",
			"Panel_Cabinet_243W_x_255H_1036_xxm"
		],
		geometriesToShow2And3: [
			"Cabinets_Right015_1",
			"Cabinets_Right015",
			"BWU_DYN_base",
			"BWU_DYN_wall"
		],

	},

	//Pro-Canopy Wall Hood - Checked
	DHD48U990CM: {
		manual: true,
		position: [-4.445, 0.84, 7.77],
		rotation: [0, 0, 0],
		geometryToHide: [
			"Cabinet_for_Microwave_Vent",
			"Panel_Cabinet_243W_x_255H_1008",
			"Panel_Cabinet_243W_x_255H_1009",
			"Blank_Cabinet_Box_for_30",
			"Blank_Cabinet_Box001",
			"Blank_Cabinet_Box002",
			"Panel_Cabinet_243W_x_255H_1018_xxm",
			"Panel_Cabinet_243W_x_255H_1004_xxm",
			"Panel_Cabinet_243W_x_255H_1010_xxm",
			"Panel_Cabinet_243W_x_255H_1007_xxm",
			"Panel_Cabinet_243W_x_255H_1008_xxm",
			"Panel_Cabinet_243W_x_255H_1009_xxm",
			"Panel_Cabinet_243W_x_255H_1017_xxm",
			"Panel_Cabinet_243W_x_255H_1016_xxm",
			"Panel_Cabinet_243W_x_255H_1_xxm",
			"Cabinets_Right005",
			"Panel_Cabinet_243W_x_255H_1014_xxm",
			"Panel_Cabinet_243W_x_255H_1013_xxm",
			"Cabinets_Right004",
		],
		geometryToDisplay: [

			"Panel_Cabinet_243W_x_255H_1020_xxm",
			"Panel_Cabinet_243W_x_255H_1005_xxm",
			"Panel_Cabinet_243W_x_255H_1011_xxm",
			"Panel_Cabinet_275W_x_255H002_xxm",
			"Panel_Cabinet_275W_x_255H004_xxm",
			"Cabinets_Right001",
			"Cabinets_Right010",
			"Cabinets_Right002",
			"Panel_Cabinet_243W_x_255H_1031_xxm",
			"Panel_Cabinet_243W_x_255H_1034_xxm",
			"Panel_Cabinet_243W_x_255H_1032_xxm",
			"Panel_Cabinet_243W_x_255H_1035_xxm",
			"Panel_Cabinet_243W_x_255H_1033_xxm",
			"Panel_Cabinet_243W_x_255H_1036_xxm",
			"BWU_DYN_base",
			"BWU_DYN_wall"
		],
		geometriesToShow2And3: [
			"Cabinets_Right015_1",
			"Cabinets_Right015",
			"BWU_DYN_base",
			"BWU_DYN_wall"
		],

	},
	//Pro-Canopy Wall Hood - Checked
	DHD48U990CS: {
		manual: true,
		position: [-4.445, 0.84, 1.8],
		rotation: [0, 0, 0],
		geometryToHide: [
			"Cabinet_for_Microwave_Vent",
			"Panel_Cabinet_243W_x_255H_1008",
			"Panel_Cabinet_243W_x_255H_1009",
			"Blank_Cabinet_Box_for_30",
			"Blank_Cabinet_Box001",
			"Blank_Cabinet_Box002",
			"Panel_Cabinet_243W_x_255H_1018_xxm",
			"Panel_Cabinet_243W_x_255H_1004_xxm",
			"Panel_Cabinet_243W_x_255H_1010_xxm",
			"Panel_Cabinet_243W_x_255H_1007_xxm",
			"Panel_Cabinet_243W_x_255H_1017_xxm",
			"Panel_Cabinet_243W_x_255H_1016_xxm",
			"Panel_Cabinet_243W_x_255H_1_xxm",
			"Cabinets_Right005",

			"Panel_Cabinet_243W_x_255H_1014_xxm",
			"Panel_Cabinet_243W_x_255H_1013_xxm",
			"Cabinets_Right004",
		],
		geometryToDisplay: [
			"Panel_Cabinet_243W_x_255H_1020_xxm",
			"Panel_Cabinet_243W_x_255H_1005_xxm",
			"Panel_Cabinet_243W_x_255H_1011_xxm",
			"Panel_Cabinet_275W_x_255H002_xxm",
			"Panel_Cabinet_275W_x_255H004_xxm",
			"Cabinets_Right001",
			"Cabinets_Right010",
			"Cabinets_Right002",
			"Panel_Cabinet_243W_x_255H_1031_xxm",
			"Panel_Cabinet_243W_x_255H_1034_xxm",
			"Panel_Cabinet_243W_x_255H_1032_xxm",
			"Panel_Cabinet_243W_x_255H_1035_xxm",
			"Panel_Cabinet_243W_x_255H_1033_xxm",
			"Panel_Cabinet_243W_x_255H_1036_xxm"
		],
		geometriesToShow2And3: [
			"Cabinets_Right015_1",
			"Cabinets_Right015",
			"BWU_DYN_base",
			"BWU_DYN_wall"
		],

	},

	//Island Hood - Checked
	DHD36U990IM: {
		manual: true,
		position: [0.744, 0.701, 13.774],
		rotation: [0, 0, 0],
		//check the 7 haus for kitchen and island displays, may not have to do it here but in there
		geometryToHide: [],
		geometryToDisplay: [],
		geometriesToShow2And3: [],

	},
	//Island Hood - Checked
	DHD36U990IS: {
		manual: true,
		position: [0.74, 0.7, 7.77],
		rotation: [0, 0, 0],
		//check the 7 haus for kitchen and island displays, may not have to do it here but in there   
		geometryToHide: [],
		geometryToDisplay: [],
		geometriesToShow2And3: [],

	},
	//Island Hood - Checked
	DHD42U990IM: {
		manual: true,
		position: [-1.259, 0.7, 13.774],
		rotation: [0, 0, 0],
		//check the 7 haus for kitchen and island displays, may not have to do it here but in there   
		geometryToHide: [],
		geometryToDisplay: [],
		geometriesToShow2And3: [],

	},
	//Island Hood - Checked
	DHD42U990IS: {
		manual: true,
		position: [-1.26, 0.7, 7.774],
		rotation: [0, 0, 0],
		//check the 7 haus for kitchen and island displays, may not have to do it here but in there   
		geometryToHide: [],
		geometryToDisplay: [],
		geometriesToShow2And3: [],

	},
	//Island Hood - Checked
	DHD54U990IS: {
		manual: true,
		position: [-3.259, 0.7, 7.77],
		rotation: [0, 0, 0],
		//check the 7 haus for kitchen and island displays, may not have to do it here but in there   
		geometryToHide: [],
		geometryToDisplay: [],
		geometriesToShow2And3: [],

	},

	DHD54U990IM: {
		manual: true,
		position: [-3.259, 0.7, 13.774],
		rotation: [0, 0, 0],
		//check the 7 haus for kitchen and island displays, may not have to do it here but in there   
		geometryToHide: [],
		geometryToDisplay: [],
		geometriesToShow2And3: [],

	},
	//Chimney Hood - Checked
	DHD30U990WS: {
		manual: true,
		position: [-0.626, 0.682, 3.56],
		rotation: [0, 0, 0],
		geometryToHide: [
			"Cabinets_Right010",
			"cabRight",
			"Cabinets_Right001",
			"Cabinets_Right002",
			"Cabinet_for_Microwave_Vent",
			"Blank_Cabinet_Box_for_30",
			"Blank_Cabinet_Box002",
			"Blank_Cabinet_Box001",
			"Cabinets_Right004",
			"Cabinets_Right003",
			"Cabinets_Right005",
			"Panel_Cabinet_275W_x_255H001_xxm",
			"Panel_Cabinet_275W_x_255H002_xxm",
			"Panel_Cabinet_275W_x_255H004_xxm",
			"Panel_Cabinet_243W_x_255H_1009_xxm",
			"Panel_Cabinet_243W_x_255H_1007_xxm",
			"Panel_Cabinet_243W_x_255H_1016_xxm",
			"Panel_Cabinet_243W_x_255H_1006_xxm",
			"Panel_Cabinet_243W_x_255H_1013_xxm",
			"Panel_Cabinet_243W_x_255H_1020_xxm",
			"Panel_Cabinet_243W_x_255H_1021_xxm",
			"Panel_Cabinet_243W_x_255H_1018_xxm",
			"Panel_Cabinet_243W_x_255H_1019_xxm",
			"Panel_Cabinet_243W_x_255H_1008_xxm",
			"Panel_Cabinet_243W_x_255H_1005_xxm",
			"Panel_Cabinet_243W_x_255H_1004_xxm",
			"Panel_Cabinet_243W_x_255H_1012_xxm",
			"Panel_Cabinet_243W_x_255H_1015_xxm",
			"Panel_Cabinet_243W_x_255H_1010_xxm",
			"Panel_Cabinet_243W_x_255H_1_xxm",
			"Panel_Cabinet_243W_x_255H_1017_xxm",
			"Panel_Cabinet_243W_x_255H_1014_xxm",
			"Panel_Cabinet_243W_x_255H_1011_xxm",
			"Panel_Cabinet_243W_x_255H_1022_xxm",
			"Panel_Cabinet_243W_x_255H_1031_xxm",
			"Panel_Cabinet_243W_x_255H_1034_xxm",
			"Panel_Cabinet_243W_x_255H_1025_xxm",
			"Panel_Cabinet_243W_x_255H_1032_xxm",
			"Panel_Cabinet_243W_x_255H_1035_xxm",
			"Panel_Cabinet_243W_x_255H_1028_xxm",
			"Panel_Cabinet_243W_x_255H_1033_xxm",
			"Panel_Cabinet_243W_x_255H_1036_xxm",
		],
		geometryToDisplay: [],
		geometriesToShow2And3: [],

	},

	DHD30U990WM: {
		manual: true,
		position: [-0.626, 0.682, 9.56],
		rotation: [0, 0, 0],
		geometryToHide: [
			"Cabinets_Right010",
			"cabRight",
			"Cabinets_Right001",
			"Cabinets_Right002",
			"Cabinet_for_Microwave_Vent",
			"Blank_Cabinet_Box_for_30",
			"Blank_Cabinet_Box002",
			"Blank_Cabinet_Box001",
			"Cabinets_Right004",
			"Cabinets_Right003",
			"Cabinets_Right005",
			"Panel_Cabinet_275W_x_255H001_xxm",
			"Panel_Cabinet_275W_x_255H002_xxm",
			"Panel_Cabinet_275W_x_255H004_xxm",
			"Panel_Cabinet_243W_x_255H_1009_xxm",
			"Panel_Cabinet_243W_x_255H_1007_xxm",
			"Panel_Cabinet_243W_x_255H_1016_xxm",
			"Panel_Cabinet_243W_x_255H_1006_xxm",
			"Panel_Cabinet_243W_x_255H_1013_xxm",
			"Panel_Cabinet_243W_x_255H_1020_xxm",
			"Panel_Cabinet_243W_x_255H_1021_xxm",
			"Panel_Cabinet_243W_x_255H_1018_xxm",
			"Panel_Cabinet_243W_x_255H_1019_xxm",
			"Panel_Cabinet_243W_x_255H_1008_xxm",
			"Panel_Cabinet_243W_x_255H_1005_xxm",
			"Panel_Cabinet_243W_x_255H_1004_xxm",
			"Panel_Cabinet_243W_x_255H_1012_xxm",
			"Panel_Cabinet_243W_x_255H_1015_xxm",
			"Panel_Cabinet_243W_x_255H_1010_xxm",
			"Panel_Cabinet_243W_x_255H_1_xxm",
			"Panel_Cabinet_243W_x_255H_1017_xxm",
			"Panel_Cabinet_243W_x_255H_1014_xxm",
			"Panel_Cabinet_243W_x_255H_1011_xxm",
			"Panel_Cabinet_243W_x_255H_1022_xxm",
			"Panel_Cabinet_243W_x_255H_1031_xxm",
			"Panel_Cabinet_243W_x_255H_1034_xxm",
			"Panel_Cabinet_243W_x_255H_1025_xxm",
			"Panel_Cabinet_243W_x_255H_1032_xxm",
			"Panel_Cabinet_243W_x_255H_1035_xxm",
			"Panel_Cabinet_243W_x_255H_1028_xxm",
			"Panel_Cabinet_243W_x_255H_1033_xxm",
			"Panel_Cabinet_243W_x_255H_1036_xxm",
		],
		geometryToDisplay: [],
		geometriesToShow2And3: [],

	},

	//Chimney Hood - Checked
	DHD36U990WS: {
		manual: true,
		position: [-2.67, 0.682, 3.56],
		rotation: [0, 0, 0],
		geometryToHide: [
			"Cabinets_Right010",
			"cabRight",
			"Cabinets_Right001",
			"Cabinets_Right002",
			"Cabinet_for_Microwave_Vent",
			"Blank_Cabinet_Box_for_30",
			"Blank_Cabinet_Box002",
			"Blank_Cabinet_Box001",
			"Cabinets_Right004",
			"Cabinets_Right003",
			"Cabinets_Right005",
			"Panel_Cabinet_275W_x_255H001_xxm",
			"Panel_Cabinet_275W_x_255H002_xxm",
			"Panel_Cabinet_275W_x_255H004_xxm",
			"Panel_Cabinet_243W_x_255H_1009_xxm",
			"Panel_Cabinet_243W_x_255H_1007_xxm",
			"Panel_Cabinet_243W_x_255H_1016_xxm",
			"Panel_Cabinet_243W_x_255H_1006_xxm",
			"Panel_Cabinet_243W_x_255H_1013_xxm",
			"Panel_Cabinet_243W_x_255H_1020_xxm",
			"Panel_Cabinet_243W_x_255H_1021_xxm",
			"Panel_Cabinet_243W_x_255H_1018_xxm",
			"Panel_Cabinet_243W_x_255H_1019_xxm",
			"Panel_Cabinet_243W_x_255H_1008_xxm",
			"Panel_Cabinet_243W_x_255H_1005_xxm",
			"Panel_Cabinet_243W_x_255H_1004_xxm",
			"Panel_Cabinet_243W_x_255H_1012_xxm",
			"Panel_Cabinet_243W_x_255H_1015_xxm",
			"Panel_Cabinet_243W_x_255H_1010_xxm",
			"Panel_Cabinet_243W_x_255H_1_xxm",
			"Panel_Cabinet_243W_x_255H_1017_xxm",
			"Panel_Cabinet_243W_x_255H_1014_xxm",
			"Panel_Cabinet_243W_x_255H_1011_xxm",
			"Panel_Cabinet_243W_x_255H_1022_xxm",
			"Panel_Cabinet_243W_x_255H_1031_xxm",
			"Panel_Cabinet_243W_x_255H_1034_xxm",
			"Panel_Cabinet_243W_x_255H_1025_xxm",
			"Panel_Cabinet_243W_x_255H_1032_xxm",
			"Panel_Cabinet_243W_x_255H_1035_xxm",
			"Panel_Cabinet_243W_x_255H_1028_xxm",
			"Panel_Cabinet_243W_x_255H_1033_xxm",
			"Panel_Cabinet_243W_x_255H_1036_xxm",
		],
		geometryToDisplay: [],
		geometriesToShow2And3: [],

	},

	DHD36U990WM: {
		manual: true,
		position: [-2.67, 0.682, 9.57109],
		rotation: [0, 0, 0],
		geometryToHide: [
			"Cabinets_Right010",
			"cabRight",
			"Cabinets_Right001",
			"Cabinets_Right002",
			"Cabinet_for_Microwave_Vent",
			"Blank_Cabinet_Box_for_30",
			"Blank_Cabinet_Box002",
			"Blank_Cabinet_Box001",
			"Cabinets_Right004",
			"Cabinets_Right003",
			"Cabinets_Right005",
			"Panel_Cabinet_275W_x_255H001_xxm",
			"Panel_Cabinet_275W_x_255H002_xxm",
			"Panel_Cabinet_275W_x_255H004_xxm",
			"Panel_Cabinet_243W_x_255H_1009_xxm",
			"Panel_Cabinet_243W_x_255H_1007_xxm",
			"Panel_Cabinet_243W_x_255H_1016_xxm",
			"Panel_Cabinet_243W_x_255H_1006_xxm",
			"Panel_Cabinet_243W_x_255H_1013_xxm",
			"Panel_Cabinet_243W_x_255H_1020_xxm",
			"Panel_Cabinet_243W_x_255H_1021_xxm",
			"Panel_Cabinet_243W_x_255H_1018_xxm",
			"Panel_Cabinet_243W_x_255H_1019_xxm",
			"Panel_Cabinet_243W_x_255H_1008_xxm",
			"Panel_Cabinet_243W_x_255H_1005_xxm",
			"Panel_Cabinet_243W_x_255H_1004_xxm",
			"Panel_Cabinet_243W_x_255H_1012_xxm",
			"Panel_Cabinet_243W_x_255H_1015_xxm",
			"Panel_Cabinet_243W_x_255H_1010_xxm",
			"Panel_Cabinet_243W_x_255H_1_xxm",
			"Panel_Cabinet_243W_x_255H_1017_xxm",
			"Panel_Cabinet_243W_x_255H_1014_xxm",
			"Panel_Cabinet_243W_x_255H_1011_xxm",
			"Panel_Cabinet_243W_x_255H_1022_xxm",
			"Panel_Cabinet_243W_x_255H_1031_xxm",
			"Panel_Cabinet_243W_x_255H_1034_xxm",
			"Panel_Cabinet_243W_x_255H_1025_xxm",
			"Panel_Cabinet_243W_x_255H_1032_xxm",
			"Panel_Cabinet_243W_x_255H_1035_xxm",
			"Panel_Cabinet_243W_x_255H_1028_xxm",
			"Panel_Cabinet_243W_x_255H_1033_xxm",
			"Panel_Cabinet_243W_x_255H_1036_xxm",
		],
		geometryToDisplay: [],
		geometriesToShow2And3: [],

	},

	//Chimney Hood - Checked
	DHD48U990WS: {
		manual: true,
		position: [-4.67, 0.682, 3.56],
		rotation: [0, 0, 0],
		geometryToHide: [
			"Cabinets_Right010",
			"cabRight",
			"Cabinets_Right001",
			"Cabinets_Right002",
			"Cabinet_for_Microwave_Vent",
			"Blank_Cabinet_Box_for_30",
			"Blank_Cabinet_Box002",
			"Blank_Cabinet_Box001",
			"Cabinets_Right004",
			"Cabinets_Right003",
			"Cabinets_Right005",
			"Panel_Cabinet_275W_x_255H001_xxm",
			"Panel_Cabinet_275W_x_255H002_xxm",
			"Panel_Cabinet_275W_x_255H004_xxm",
			"Panel_Cabinet_243W_x_255H_1009_xxm",
			"Panel_Cabinet_243W_x_255H_1007_xxm",
			"Panel_Cabinet_243W_x_255H_1016_xxm",
			"Panel_Cabinet_243W_x_255H_1006_xxm",
			"Panel_Cabinet_243W_x_255H_1013_xxm",
			"Panel_Cabinet_243W_x_255H_1020_xxm",
			"Panel_Cabinet_243W_x_255H_1021_xxm",
			"Panel_Cabinet_243W_x_255H_1018_xxm",
			"Panel_Cabinet_243W_x_255H_1019_xxm",
			"Panel_Cabinet_243W_x_255H_1008_xxm",
			"Panel_Cabinet_243W_x_255H_1005_xxm",
			"Panel_Cabinet_243W_x_255H_1004_xxm",
			"Panel_Cabinet_243W_x_255H_1012_xxm",
			"Panel_Cabinet_243W_x_255H_1015_xxm",
			"Panel_Cabinet_243W_x_255H_1010_xxm",
			"Panel_Cabinet_243W_x_255H_1_xxm",
			"Panel_Cabinet_243W_x_255H_1017_xxm",
			"Panel_Cabinet_243W_x_255H_1014_xxm",
			"Panel_Cabinet_243W_x_255H_1011_xxm",
			"Panel_Cabinet_243W_x_255H_1022_xxm",
			"Panel_Cabinet_243W_x_255H_1031_xxm",
			"Panel_Cabinet_243W_x_255H_1034_xxm",
			"Panel_Cabinet_243W_x_255H_1025_xxm",
			"Panel_Cabinet_243W_x_255H_1032_xxm",
			"Panel_Cabinet_243W_x_255H_1035_xxm",
			"Panel_Cabinet_243W_x_255H_1028_xxm",
			"Panel_Cabinet_243W_x_255H_1033_xxm",
			"Panel_Cabinet_243W_x_255H_1036_xxm",
		],
		geometryToDisplay: [],
		geometriesToShow2And3: [],

	},

	DHD48U990WM: {
		manual: true,
		position: [-4.67, 0.682, 9.565],
		rotation: [0, 0, 0],
		geometryToHide: [
			"Cabinets_Right010",
			"cabRight",
			"Cabinets_Right001",
			"Cabinets_Right002",
			"Cabinet_for_Microwave_Vent",
			"Blank_Cabinet_Box_for_30",
			"Blank_Cabinet_Box002",
			"Blank_Cabinet_Box001",
			"Cabinets_Right004",
			"Cabinets_Right003",
			"Cabinets_Right005",
			"Panel_Cabinet_275W_x_255H001_xxm",
			"Panel_Cabinet_275W_x_255H002_xxm",
			"Panel_Cabinet_275W_x_255H004_xxm",
			"Panel_Cabinet_243W_x_255H_1009_xxm",
			"Panel_Cabinet_243W_x_255H_1007_xxm",
			"Panel_Cabinet_243W_x_255H_1016_xxm",
			"Panel_Cabinet_243W_x_255H_1006_xxm",
			"Panel_Cabinet_243W_x_255H_1013_xxm",
			"Panel_Cabinet_243W_x_255H_1020_xxm",
			"Panel_Cabinet_243W_x_255H_1021_xxm",
			"Panel_Cabinet_243W_x_255H_1018_xxm",
			"Panel_Cabinet_243W_x_255H_1019_xxm",
			"Panel_Cabinet_243W_x_255H_1008_xxm",
			"Panel_Cabinet_243W_x_255H_1005_xxm",
			"Panel_Cabinet_243W_x_255H_1004_xxm",
			"Panel_Cabinet_243W_x_255H_1012_xxm",
			"Panel_Cabinet_243W_x_255H_1015_xxm",
			"Panel_Cabinet_243W_x_255H_1010_xxm",
			"Panel_Cabinet_243W_x_255H_1_xxm",
			"Panel_Cabinet_243W_x_255H_1017_xxm",
			"Panel_Cabinet_243W_x_255H_1014_xxm",
			"Panel_Cabinet_243W_x_255H_1011_xxm",
			"Panel_Cabinet_243W_x_255H_1022_xxm",
			"Panel_Cabinet_243W_x_255H_1031_xxm",
			"Panel_Cabinet_243W_x_255H_1034_xxm",
			"Panel_Cabinet_243W_x_255H_1025_xxm",
			"Panel_Cabinet_243W_x_255H_1032_xxm",
			"Panel_Cabinet_243W_x_255H_1035_xxm",
			"Panel_Cabinet_243W_x_255H_1028_xxm",
			"Panel_Cabinet_243W_x_255H_1033_xxm",
			"Panel_Cabinet_243W_x_255H_1036_xxm",
		],
		geometryToDisplay: [],
		geometriesToShow2And3: [],

	},

	//warming drawer(dependent on amount of wall ovens) - Checked
	DWR30U900WP: {
		manual: true,
		position: [3.899, -0.152, -2.42],
		positionSingleOven: [3.899, -0.152, -2.42],
		positionDoubleOven: [3.899, -0.723, -2.42],
		positionCombiOven: [3.899, -0.535, -2.42],
		rotationSingleOven: [0, 0, 0],
		rotationDoubleOven: [0, 0, 0],
		rotationCombiOven: [0, 0, 0],

		geometryToHideSingleOven: [],
		geometryToDisplaySingleOven: [
			"Warmer_H10_x30W_x_1D_Panel002_xxm",
			"NA_Panel_Bottom_Left_Small001_xxm",
			"R_Panel_Bottom_Left_Small_123H_xxm",
		],

		geometryToHideDoubleOven: [],
		geometryToDisplayDoubleOven: [],

		geometryToHideDoubleOven: [
			"Warmer_H10_x30W_x_1D_Panel002_xxm",
			"NA_Panel_Bottom_Left_Small001_xxm",
			"R_Panel_Bottom_Left_Small_123H_xxm",
		],
		geometryToDisplayDoubleOven: [],

		geometryToHideCombiOven: [
			"NA_-_DOC30P977D-_Wall_Oven_Combi_Righ_Wall001_xxm",
		],
		geometryToDisplayCombiOven: [],

		geometriesToShowSingle2: [],
		geometriesToShowDouble2: [],
		geometriesToShowCombi2: [],
		geometriesToShowSingle3: [],
		geometriesToShowDouble3: [],
		geometriesToShowCombi3: [],
	},

	DWR30U900WS: {
		manual: true,
		position: [1.898, -0.152, -2.41],
		positionSingleOven: [1.898, -0.152, -2.42],
		positionDoubleOven: [1.898, -0.723, -2.42],
		positionCombiOven: [1.898, -0.535, -2.42],
		rotationSingleOven: [0, 0, 0],
		rotationDoubleOven: [0, 0, 0],
		rotationCombiOven: [0, 0, 0],

		geometryToHideSingleOven: [],
		geometryToDisplaySingleOven: [
			"Warmer_H10_x30W_x_1D_Panel002_xxm",
			"NA_Panel_Bottom_Left_Small001_xxm",
			"R_Panel_Bottom_Left_Small_123H_xxm",
		],

		geometryToHideDoubleOven: [],
		geometryToDisplayDoubleOven: [],

		geometryToHideDoubleOven: [
			"Warmer_H10_x30W_x_1D_Panel002_xxm",
			"NA_Panel_Bottom_Left_Small001_xxm",
			"R_Panel_Bottom_Left_Small_123H_xxm",
		],
		geometryToDisplayDoubleOven: [],

		geometryToHideCombiOven: [
			"NA_-_DOC30P977D-_Wall_Oven_Combi_Righ_Wall001_xxm",
		],
		geometryToDisplayCombiOven: [],

		geometriesToShowSingle2: [],
		geometriesToShowDouble2: [],
		geometriesToShowCombi2: [],
		geometriesToShowSingle3: [],
		geometriesToShowDouble3: [],
		geometriesToShowCombi3: [],
	},



	DWR30U900WM: {
		manual: true,
		position: [-0.102, -0.153, -2.41],
		positionSingleOven: [-0.102, -0.153, -2.41],
		positionDoubleOven: [-0.103, -0.72, -2.41],
		positionCombiOven: [-0.101, -0.53, -2.41],
		rotationSingleOven: [0, 0, 0],
		rotationDoubleOven: [0, 0, 0],
		rotationCombiOven: [0, 0, 0],

		geometryToHideSingleOven: [],
		geometryToDisplaySingleOven: [
			"Warmer_H10_x30W_x_1D_Panel002_xxm",
			"NA_Panel_Bottom_Left_Small001_xxm",
			"R_Panel_Bottom_Left_Small_123H_xxm",
		],

		geometryToHideDoubleOven: [],
		geometryToDisplayDoubleOven: [],

		geometryToHideDoubleOven: [
			"Warmer_H10_x30W_x_1D_Panel002_xxm",
			"NA_Panel_Bottom_Left_Small001_xxm",
			"R_Panel_Bottom_Left_Small_123H_xxm",
		],
		geometryToDisplayDoubleOven: [],

		geometryToHideCombiOven: [
			"NA_-_DOC30P977D-_Wall_Oven_Combi_Righ_Wall001_xxm",
		],
		geometryToDisplayCombiOven: [],
		geometriesToShowSingle2: [],
		geometriesToShowDouble2: [],
		geometriesToShowCombi2: [],
		geometriesToShowSingle3: [],
		geometriesToShowDouble3: [],
		geometriesToShowCombi3: [],
	},

	//Gas cooktop - checked
	DTG30P875NS: {
		manual: true,
		position: [0.74, -0.12, 1.78],
		rotation: [0, 180, 0],
		positionInKitchenSink: [-2.45, -0.11, -4.48],
		positionInIsland: [0.74, -0.12, 1.78],
		rotationInKitchen: [0, 0, 0],
		rotationInIsland: [0, 180, 0],
		geometryToDisplayKitchen: [
			"Kitchen_Counter_for_DTI36M977BB",
			"Panel_H262_Panel_xxm",
			"L1_BOTTOM011_xxm",
			"L1_TOP_xxm",
			"LL2_TOP_K_xxm",
			"LL2_BOTTOM_K_xxm",
			"LL3_TOP_K_xxm",
		],
		geometryToDisplayIsland: [
			"RIGHT_DRAWER_Island_12_Inch001_xxm",
			"12_RIGHT_xxm",
			"Island_Counter_Whole003",
			"LEFT_DRAWER_for_Island_MicroWaves001_xxm",
			"LEFT_DRAWER_for_Island_MicroWaves_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_Panel_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_Panel001_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_Panel002_xxm",
			"18_RIGHT002_xxm",
			"18_RIGHT003_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island001_xxm",
			"8_LEFT001_xxm",
			"8_LEFT_xxm",
			"24_LEFT_for_48imch_Cooktop_Island_xxm",
			"24_LEFT_for_48imch_Cooktop_Island001_xxm",
			"18_LEFT002_xxm",
			"18_LEFT008_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom002_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom004_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom006_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom001_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom003_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom005_xxm"
		],
		geometriesToShowKitchen2And3: [
			'BW_DYN_DTG30M954F_DTG30P875N'
		],
		geometriesToShowIsland2And3: [
			"ISL_DYN_DTG30M954F_DTG30P875N"
		],

	},
	//Gas cooktop - checked
	DTG36P875NS: {
		manual: true,
		position: [0.74, -0.12, 0.797],
		rotation: [0, 0, 0],
		positionInKitchenSink: [-2.45, -0.116, -3.46],
		positionInIsland: [0.74, -0.12, 0.797],
		rotationKitchen: [0, 0, 0],
		rotationIsland: [0, 180, 0],
		geometryToHide: [],
		geometryToDisplayKitchen: [
			"Kitchen_Counter_for_DTG30P875N",
			"Panel_H262_Panel_xxm",
			"L1_BOTTOM011_xxm",
			"L1_TOP_xxm",
			"LL2_TOP_K_xxm",
			"LL2_BOTTOM_K_xxm",
			"LL3_TOP_K_xxm",
		],
		geometryToDisplayIsland: [
			"RIGHT_DRAWER_Island_12_Inch001_xxm",
			"12_RIGHT_xxm",
			"Island_Counter_for_DTI36M977BB",
			"LEFT_DRAWER_for_Island_MicroWaves001_xxm",
			"LEFT_DRAWER_for_Island_MicroWaves_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_Panel_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_Panel001_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_Panel002_xxm",
			"18_RIGHT002_xxm",
			"18_RIGHT003_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island001_xxm",
			"8_LEFT001_xxm",
			"8_LEFT_xxm",
			"24_LEFT_for_48imch_Cooktop_Island_xxm",
			"24_LEFT_for_48imch_Cooktop_Island001_xxm",
			"18_LEFT002_xxm",
			"18_LEFT008_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom002_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom004_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom006_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom001_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom003_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom005_xxm"
		],
		geometriesToShowKitchen2And3: [
			'BW_DYN_DTG36M955F_DTG36P875N',

		],
		geometriesToShowIsland2And3: [
			"ISL_DYN_DTG36M955F_DTG36P875N",
		],

	},

	//Induction Cooktop - checked
	DTI36P876BB: {
		manual: true,
		positionInKitchenSink: [-2.45, -0.1, -1.45],
		positionInIsland: [0.745, -0.1, -1.247],
		rotationKitchen: [0, 0, 0],
		rotationIsland: [0, 180, 0],
		geometryToHide: [
		],
		geometryToDisplayKitchen: [
			"Panel_H262_Panel_xxm",
			"L1_BOTTOM011_xxm",
			"L1_TOP_xxm",
			"LL2_TOP_K_xxm",
			"LL2_BOTTOM_K_xxm",
			"LL3_TOP_K_xxm",
			"Kitchen_Counter_for_DTI36M977BB"
		],
		geometryToDisplayIsland: [
			"RIGHT_DRAWER_Island_12_Inch001_xxm",
			"12_RIGHT_xxm",
			"Island_Counter_for_DTI36M977BB",
			"LEFT_DRAWER_for_Island_MicroWaves001_xxm",
			"LEFT_DRAWER_for_Island_MicroWaves_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_Panel_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_Panel001_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_Panel002_xxm",
			"18_RIGHT002_xxm",
			"18_RIGHT003_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island001_xxm",
			"8_LEFT001_xxm",
			"8_LEFT_xxm",
			"24_LEFT_for_48imch_Cooktop_Island_xxm",
			"24_LEFT_for_48imch_Cooktop_Island001_xxm",
			"18_LEFT002_xxm",
			"18_LEFT008_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom002_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom004_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom006_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom001_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom003_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom005_xxm"
		],


		geometriesToShowKitchen2And3: [
			'BW_DYN_DTG36M955F_DTG36P875N',

		],
		geometriesToShowIsland2And3: [
			"ISL_DYN_DTG36M955F_DTG36P875N",
		],

	},
	//Induction Cooktop - checked
	DTI30P876BB: {
		manual: true,
		position: [0.741, -0.14, -0.266],
		positionInKitchenSink: [-2.455, -0.133, -2.42],
		positionInIsland: [0.741, -0.14, -0.266],
		rotationIsland: [0, 0, 0],
		rotationKitchen: [0, 0, 0],
		geometryToHide: [],
		geometryToDisplayIsland: [
			"RIGHT_DRAWER_Island_12_Inch001_xxm",
			"12_RIGHT_xxm",
			"Island_Counter_Whole003",
			"LEFT_DRAWER_for_Island_MicroWaves001_xxm",
			"LEFT_DRAWER_for_Island_MicroWaves_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_Panel_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_Panel001_xxm",
			"NA_-_DOB30T977S_-_Wall_Oven_Single_Panel002_xxm",
			"18_RIGHT002_xxm",
			"18_RIGHT003_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island001_xxm",
			"8_LEFT001_xxm",
			"8_LEFT_xxm",
			"24_LEFT_for_48imch_Cooktop_Island_xxm",
			"24_LEFT_for_48imch_Cooktop_Island001_xxm",
			"18_LEFT002_xxm",
			"18_LEFT008_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom002_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom004_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom006_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom001_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom003_xxm",
			"DOB30T977S_-_Wall_Oven_Single_Bottom005_xxm"
		],

		geometryToHideKitchenSink: [

		],
		geometryToDisplayKitchen: [
			"Kitchen_Counter_for_DTI36M977BB",
			"Panel_H262_Panel_xxm",
			"L1_BOTTOM011_xxm",
			"L1_TOP_xxm",
			"LL2_TOP_K_xxm",
			"LL2_BOTTOM_K_xxm",
			"LL3_TOP_K_xxm",
		],
		geometriesToShowKitchen2And3: [
			'BW_DYN_DTG30M954F_DTG30P875N'
		],
		geometriesToShowIsland2And3: [
			"ISL_DYN_DTG30M954F_DTG30P875N"
		],

	},
	//Wall Oven-Double - checked
	DOB30T977DS: {
		manual: true,
		position: [0.897, -0.6, 11.6],
		rotation: [0, 0, 0],
		geometryToHide: [],
		geometryToDisplay: [
			"WRightProductArea30Spacer002_xxm",
			"R_Panel_Bottom_Left_Middlel_75H002_xxm",
			"Panel_Bottom_Left_Small_378H_xxm"
		],
		geometriesToShow2And3: [
			'RW_DYN_DOB30M977D_DOB30T977D'
		],

	},

	//Wall Oven - Single(this can exist in 3 places, but only 1 can be visible at one time) - checked
	DOB30T977SS: {
		manual: true,
		//must have position set to see actual position so if you wanna see wall please put position in this to see, will do code later
		position: [0.898, -0.028, 13.595],
		rotation: [0, 0, 0],
		positionOnWall: [0.898, -0.028, 13.595],
		positionInKitchen: [-2.445, -0.9, 13.608],
		positionInIsland: [0.7, -0.89, -16.292],

		rotationOnWall: [0, 0, 0],
		rotationInKitchen: [0, 0, 0],
		rotationInIsland: [0, Math.PI, 0],
		geometryToDisplay: [
			"R_Panel_Bottom_Left_Middlel_75H_xxm",
			"Warmer_H10_x30W_x_1D_Panel001_xxm",
			"R_Panel_Bottom_Left_Small_264H_xxm",
			"WRightProductArea30Spacer002_xxm",
			"WRightProductArea30SpacerTOP_xxm"
		],
		//check the 7 hause for kitchen and island displays, may not have to do it here but in there
		geometriesToShow2And3: [
			'RW_DYN_DOB30T977S'
		],
		geometriesToShowKitchen2And3: [],
		geometriesToShowIsland2And3: [],



	},

	//Wall Oven - Combi - checked
	DOC30T977D: {
		manual: true,
		position: [0.897, -0.393, 15.595],
		rotation: [0, 0, 0],
		geometryToHide: [

		],
		geometryToDisplay: [
			"Panel_Bottom_Left_Small_378H_xxm",
			"R_Panel_Bottom_Left_Middlel_75H001_xxm",
			"WRightProductArea30Spacer002_xxm",
			"WRightProductArea30SpacerTOP_xxm",
			"R_Panel_Bottom_Left_Small_123H_xxm"
		],
		geometriesToShow2And3: [
			'RW_DYN_DOC30T977D'
		],

	},

	//Range Tops - Checked?
	DTT36T960G: {
		manual: true,
		positionInKitchenSink: [-2.45, -0.94, 5.64],
		positionInIsland: [0.743, -0.952, -8.32],
		rotationInKitchen: [0, 0, 0],
		rotationIsland: [0, 180, 0],
		geometryToHideKitchen: [],
		geometryToDisplayKitchen: [
			"Panel_H262_Panel_xxm",
			"L1_BOTTOM011_xxm",
			"L1_TOP_xxm",
			"LL2_TOP_K_xxm",
			"LL2_BOTTOM_K_xxm",
			"LL3_TOP_K_xxm",
			"Kitchen_Counter_for_DTT36T960G"
		],
		geometryToHideIsland: [],
		geometryToDisplayIsland: [
			"Island_Counter_Whole009",
			"RIGHT_DRAWER_Island_12_Inch005_xxm",
			"12_RIGHT005_xxm",
			"18_LEFT002_xxm",
			"18_RIGHT002_xxm",
		],
		geometriesToShowKitchen2And3: [
			"BW_DYN_DTT36T960G"
		],
		geometriesToShowIsland2And3: [
			"ISL_DYN_DTT36T960G"
		],

	},
	//Range Tops - Checked?
	DTT36T960GM: {
		manual: true,
		positionInKitchenSink: [-4.45, -0.94, 5.64],
		positionInIsland: [2.743, -0.952, -8.32],
		rotationInKitchen: [0, 0, 0],
		rotationIsland: [0, 180, 0],
		geometryToHideKitchen: [],
		geometryToDisplayKitchen: [
			"Panel_H262_Panel_xxm",
			"L1_BOTTOM011_xxm",
			"L1_TOP_xxm",
			"LL2_TOP_K_xxm",
			"LL2_BOTTOM_K_xxm",
			"LL3_TOP_K_xxm",
			"Kitchen_Counter_for_DTT36T960G"
		],
		geometryToHideIsland: [],
		geometryToDisplayIsland: [
			"Island_Counter_Whole009",
			"RIGHT_DRAWER_Island_12_Inch005_xxm",
			"12_RIGHT005_xxm",
			"18_LEFT002_xxm",
			"18_RIGHT002_xxm",
		],
		geometriesToShowKitchen2And3: [
			"BW_DYN_DTT36T960G"
		],
		geometriesToShowIsland2And3: [
			"ISL_DYN_DTT36T960G"
		],

	},
	//Range Tops - Checked?
	DTT48T960GS: {
		manual: true,
		positionInKitchenSink: [-2.446, -0.945, 7.64],
		positionInIsland: [0.59, -0.95, -10.32],
		rotationInKitchen: [0, 0, 0],
		rotationIsland: [0, 180, 0],
		geometryToDisplayKitchen: [
			"Kitchen_Counter_for_DTT48T960G",
			"Panel_H262_Panel_xxm",
			"L1_BOTTOM011_xxm",
			"L1_TOP_xxm",
			"LL2_TOP_K_xxm",
			"LL2_BOTTOM_K_xxm",
			"LL3_TOP_K_xxm",
			"LL2BOTTOM_18inch003_xxm"

		],
		geometryToDisplayIsland: [
			"Island_Counter_Whole012",
			"24_LEFT_for_48imch_Cooktop_Island_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island_xxm",
		],
		geometryToHideIsland: [],
		geometryToHideKitchen: [

		],
		geometriesToShowKitchen2And3: [
			"BW_DYN_DTT48T960G_DTT48T963G",
			"Left_Counter_for_48in_Range001_1"
		],
		geometriesToShowIsland2And3: [

			'ISL_DYN_DTT48T960G_DTT48T963G'
		],

	},
	DTT48T960GM: {
		manual: true,
		positionInKitchenSink: [-4.443999999999999, -0.945, 7.632],
		positionInIsland: [2.59, -0.955, -10.32],
		rotationInKitchen: [0, 0, 0],
		rotationIsland: [0, 180, 0],
		geometryToDisplayKitchen: [
			"Kitchen_Counter_for_DTT48T960G",
			"Panel_H262_Panel_xxm",
			"L1_BOTTOM011_xxm",
			"L1_TOP_xxm",
			"LL2_TOP_K_xxm",
			"LL2_BOTTOM_K_xxm",
			"LL3_TOP_K_xxm",
			"LL2BOTTOM_18inch003_xxm"

		],
		geometryToDisplayIsland: [
			"Island_Counter_Whole012",
			"24_LEFT_for_48imch_Cooktop_Island_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island_xxm",
		],
		geometryToHideIsland: [],
		geometryToHideKitchen: [],
		geometriesToShowKitchen2And3: [
			"BW_DYN_DTT48T960G_DTT48T963G",
			"Left_Counter_for_48in_Range001_1"
		],
		geometriesToShowIsland2And3: [

			'ISL_DYN_DTT48T960G_DTT48T963G'
		],

	},

	//Range Tops - Checked
	DTT48T963G: {
		manual: true,
		position: [2.59, -0.951, -12.32],
		positionInKitchenSink: [-4.447, -0.943, 9.64],
		positionInIsland: [2.59, -0.951, -12.32],
		rotationInKitchen: [0, 180, 0],
		rotationIsland: [0, 0, 0],
		geometryToDisplayKitchen: [

			"Kitchen_Counter_for_DTT48T960G",
			"Panel_H262_Panel_xxm",
			"L1_BOTTOM011_xxm",
			"L1_TOP_xxm",
			"LL2_TOP_K_xxm",
			"LL2_BOTTOM_K_xxm",
			"LL3_TOP_K_xxm"
		],
		geometryToDisplayIsland: [
			"Island_Counter_Whole012",
			"24_LEFT_for_48imch_Cooktop_Island_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island_xxm",],
		geometryToHideKitchen: [],
		geometryToHideIsland: [],
		geometriesToShowKitchen2And3: [
			"BW_DYN_DTT48T960G_DTT48T963G",
			"Left_Counter_for_48in_Range001_1"
		],
		geometriesToShowIsland2And3: [

			'ISL_DYN_DTT48T960G_DTT48T963G'
		],

	},

	DTT48T963GS: {
		manual: true,
		position: [0.59, -0.95, -12.32],
		positionInKitchenSink: [-2.445, -0.94, 9.64],
		positionInIsland: [0.59, -0.95, -12.32],
		rotationInKitchen: [0, 180, 0],
		rotationIsland: [0, 0, 0],
		geometryToDisplayKitchen: [
			"Kitchen_Counter_for_DTT48T960G",
			"Panel_H262_Panel_xxm",
			"L1_BOTTOM011_xxm",
			"L1_TOP_xxm",
			"LL2_TOP_K_xxm",
			"LL2_BOTTOM_K_xxm",
			"LL3_TOP_K_xxm"
		],
		geometryToDisplayIsland: [
			"Island_Counter_Whole012",
			"24_LEFT_for_48imch_Cooktop_Island_xxm",
			"24_RIGHT_for_48imch_Cooktop_Island_xxm",],
		geometryToHideKitchen: [],
		geometryToHideIsland: [


		],
		geometriesToShowKitchen2And3: [
			"BW_DYN_DTT48T960G_DTT48T963G",
			"Left_Counter_for_48in_Range001_1"
		],
		geometriesToShowIsland2And3: [

			'ISL_DYN_DTT48T960G_DTT48T963G'
		],

	},

	//Gas Range - Checked
	DOP48: {
		manual: true,
		position: [-0.41, -0.942, -0.306],
		positionInKitchenSink: [-1.678, -0.9315, -2.372],
		positionInIsland: [-0.41, -0.942, -0.306],

		rotationInKitchen: [0, 0, 0],
		rotationIsland: [0, 180, 0],

		geometryToHideKitchen: [
			"Island_Counter_for_DOP48M86D",
			"LL2BOTTOM_18inch001_xxm",
			"LL2BOTTOM_18inch003_xxm",
		],
		geometryToDisplayKitchen: [
			"L1_BOTTOM011_xxm",
			"L1_TOP002_xxm",
			"Left_Counter_for_48in_Range_D29001"
		],

		geometryToHideIsland: [
			"Counter_No_Whole_29Depth",
			"Left_Counter_48in_Range_001",
			"Island_Counter_Whole_for_DTI30M977BB_",
			"Island_Counter_Whole012"
		],
		geometryToDisplayIsland: ["Island_Counter_for_DOP48M86D",],
		geometriesToShowKitchen2And3: [
			"BW_DYN_DOP48C86D_DOP48C96D"
		],
		geometriesToShowIsland2And3: [
			"ISL_DYN_DOP48C86D_DOP48C96D"
		],

	},
	DOP48T963DS: {
		manual: true,
		position: [-1.408, -0.93, -6.3],
		rotation: [0, 180, 0],
		positionInKitchenSink: [-0.675, -0.919, 3.62],
		positionInIsland: [-1.408, -0.93, -6.3],

		rotationInKitchen: [0, 0, 0],
		rotationIsland: [0, 180, 0],

		geometryToHideKitchen: [
			"Island_Counter_for_DOP48M86D",
			"LL2BOTTOM_18inch001_xxm",
			"LL2BOTTOM_18inch003_xxm",
		],
		geometryToDisplayKitchen: [
			'L1_BOTTOM011_xxm',
			'L1_TOP002_xxm',
			"Left_Counter_for_48in_Range_D29001"
		],

		geometryToHideIsland: [
			"Island_Counter_for_DOP48M86D",
			"Counter_No_Whole_29Depth",
			"Left_Counter_48in_Range_001",
			"Island_Counter_Whole_for_DTI30M977BB_",
		],
		geometryToDisplayIsland: ["Island_Counter_for_DOP48M86D"],
		geometriesToShowKitchen2And3: [
			"BW_DYN_DOP48C86D_DOP48C96D"
		],
		geometriesToShowIsland2And3: [
			"ISL_DYN_DOP48C86D_DOP48C96D"
		],
	},
	DOP48T960G: {
		manual: true,
		position: [0.59, -0.93, -6.3],
		rotation: [0, 180, 0],
		positionInKitchenSink: [-2.675, -0.919, 3.62],
		positionInIsland: [0.59, -0.93, -6.3],

		rotationInKitchen: [0, 0, 0],
		rotationIsland: [0, 180, 0],

		geometryToHideKitchen: [
			"Island_Counter_for_DOP48M86D",
			"LL2BOTTOM_18inch001_xxm",
			"LL2BOTTOM_18inch003_xxm",
		],
		geometryToDisplayKitchen: [
			'L1_BOTTOM011_xxm',
			'L1_TOP002_xxm',
			"Left_Counter_for_48in_Range_D29001"
		],

		geometryToHideIsland: [
			"Island_Counter_for_DOP48M86D",
			"Counter_No_Whole_29Depth",
			"Left_Counter_48in_Range_001",
			"Island_Counter_Whole_for_DTI30M977BB_",
		],
		geometryToDisplayIsland: ["Island_Counter_for_DOP48M86D"],
		geometriesToShowKitchen2And3: [
			"BW_DYN_DOP48C86D_DOP48C96D"
		],
		geometriesToShowIsland2And3: [
			"ISL_DYN_DOP48C86D_DOP48C96D"
		],

	},
	DOP48T96: { // copied from DOP48T960G by Praj to support two new DOPs
		manual: true,
		position: [0.59, -0.93, -6.3],
		rotation: [0, 180, 0],
		positionInKitchenSink: [-2.675, -0.919, 3.62],
		positionInIsland: [0.59, -0.93, -6.3],

		rotationInKitchen: [0, 0, 0],
		rotationIsland: [0, 180, 0],

		geometryToHideKitchen: [
			"Island_Counter_for_DOP48M86D",
			"LL2BOTTOM_18inch001_xxm",
			"LL2BOTTOM_18inch003_xxm",
		],
		geometryToDisplayKitchen: [
			'L1_BOTTOM011_xxm',
			'L1_TOP002_xxm',
		],

		geometryToHideIsland: [
			"Island_Counter_for_DOP48M86D",
			"Counter_No_Whole_29Depth",
			"Left_Counter_48in_Range_001",
			"Island_Counter_Whole_for_DTI30M977BB_",
		],
		geometryToDisplayIsland: ["Island_Counter_for_DOP48M86D"],
		geometriesToShowKitchen2And3: [
			"BW_DYN_DOP48C86D_DOP48C96D"
		],
		geometriesToShowIsland2And3: [],

	},

	//Gas Range - Checked
	DOP30T840GS: {
		manual: true,
		position: [-2.447, -0.91, -0.344],
		rotation: [0, 0, 0],
		positionInKitchenSink: [-2.447, -0.91, -0.344],
		positionInIsland: [0.8199, -0.917, -2.315],

		rotationInKitchenSink: [0, 0, 0],
		rotationInIsland: [0, 180, 0],
		geometryToHideKitchen: [

		],
		geometryToDisplayKitchen: [
			"Left_Counter_for_18inc_Left_Counter",
			"Counter_No_Whole_29Depth",
			"L1_TOP011_xxm",
			"L1_BOTTOM011_xxm",
			"LL2TOP_18inch014_xxm",
			"LL2BOTTOM_18inch_xxm",
			"LL2TOP_18inch_xxm",
			"L1_TOP002_xxm"
		],

		geometryToHideIsland: [
			"Island_Counter_for_DOP48T960G_",
			"Island_Counter_Whole010",
			"Island_Counter_for_DOP48M86D",
			"Counter_No_Whole_29Depth",
			"Left_Counter_48in_Range_001",
			"Island_Counter_Whole_for_DTI30M977BB_",


		],
		geometryToDisplayIsland: [
			"Island_Counter_Island_DOP30T840G_",
			"RIGHT_DRAWER_Island_12_Inch002_xxm",
			"8_RIGHT001_xxm",
			"12_RIGHT002_xxm",
			"18_RIGHT_for_30W_xxm",
			"RIGHT_DRAWER_Island_18_Inch_xxm",
		],
		geometriesToShowKitchen2And3: [
			"BW_DYN_DOP30T840G"
		],
		geometriesToShowIsland2And3: [
			"ISL_DYN_DOP30T840G"
		],

	},
	//Gas Range Checked
	DOP30T940DS: {
		manual: true,
		position: [0.8199, -0.917, -2.295],
		rotation: [0, 180, 0],
		positionInKitchenSink: [-2.447, -0.91, -0.344],
		positionInIsland: [0.8199, -0.917, -2.315],
		rotationInKitchenSink: [0, 0, 0],
		rotationInIsland: [0, 180, 0],
		geometryToHideKitchen: [

		],
		geometryToDisplayKitchen: [
			"Left_Counter_for_18inc_Left_Counter",
			"Counter_No_Whole_29Depth",
			"L1_TOP011_xxm",
			"L1_BOTTOM011_xxm",
			"LL2TOP_18inch014_xxm",
			"LL2BOTTOM_18inch_xxm",
			"LL2TOP_18inch_xxm",
			"L1_TOP002_xxm"
		],

		geometryToHideIsland: [
			"Island_Counter_for_DOP48M86D",
			"Counter_No_Whole_29Depth",
			"Left_Counter_48in_Range_001",
			"Island_Counter_Whole_for_DTI30M977BB_"

		],
		geometryToDisplayIsland: [
			"Island_Counter_Island_DOP30T840G_",
			"RIGHT_DRAWER_Island_12_Inch002_xxm",
			"8_RIGHT001_xxm",
			"12_RIGHT002_xxm",
			"18_RIGHT_for_30W_xxm",
			"RIGHT_DRAWER_Island_18_Inch_xxm",
		],
		geometriesToShowKitchen2And3: [
			"BW_DYN_DOP30T840G"
		],
		geometriesToShowIsland2And3: [
			"ISL_DYN_DOP30T840G"
		],

	},
	//Gas Range - Checked
	DOP36T86DLS: {
		manual: true,
		position: [-2.45, -0.925, 1.628],
		rotation: [0, 0, 0],
		positionInKitchenSink: [-2.45, -0.925, 1.628],
		positionInIsland: [0.743, -0.936, -4.3],
		rotationInKitchenSink: [0, 0, 0],
		rotationInIsland: [0, 180, 0],
		geometryToHideKitchen: [

		],
		geometryToDisplayKitchen: [
			"LL2BOTTOM_18inch_xxm",
			"Kitchen_Counter_for_DTT36T960G",
			"L1_TOP011_xxm",
			"L1_BOTTOM011_xxm",
			"LL2TOP_18inch014_xxm"
		],

		geometryToHideIsland: [
			"Island_Counter_for_DOP48T960G_",
			"Island_Counter_Whole010",
			"Island_Counter_for_DOP48M86D",
			"Counter_No_Whole_29Depth",
			"Left_Counter_48in_Range_001",
			"Island_Counter_Whole_for_DTI30M977BB_"

		],
		geometryToDisplayIsland: [
			"RIGHT_DRAWER_Island_12_Inch002_xxm",
			"8_RIGHT001_xxm",
			"12_RIGHT002_xxm",
			"Island_Counter_for_DOP36M86D"
		],
		geometriesToShowKitchen2And3: [
			"BW_DYN_DOP36M96GL"
		],
		geometriesToShowIsland2And3: [
			"ISL_DYN_DOP36C86D"
		],

	},

	//Gas Range
	DOP36T86GLS: {
		manual: true,
		position: [-2.45, -0.925, 1.628],
		rotation: [0, 0, 0],
		positionInKitchenSink: [-2.45, -0.925, 1.628],
		positionInIsland: [0.74, -0.936, -4.3],
		rotationInKitchenSink: [0, 0, 0],
		rotationInIsland: [0, 180, 0],
		geometryToHideKitchen: [

		],
		geometryToDisplayKitchen: [
			"LL2BOTTOM_18inch_xxm",
			"Kitchen_Counter_for_DTT36T960G",
			"L1_TOP011_xxm",
			"L1_BOTTOM011_xxm",
			"LL2TOP_18inch014_xxm"
		],

		geometryToHideIsland: [
			"Island_Counter_for_DOP48T960G_",
			"Island_Counter_Whole010",
			"Island_Counter_for_DOP48M86D",
			"Counter_No_Whole_29Depth",
			"Left_Counter_48in_Range_001",
			"Island_Counter_Whole_for_DTI30M977BB_"

		],
		geometryToDisplayIsland: [
			"RIGHT_DRAWER_Island_12_Inch002_xxm",
			"8_RIGHT001_xxm",
			"12_RIGHT002_xxm",
			"Island_Counter_for_DOP36M86D"
		],
		geometriesToShowKitchen2And3: [
			"BW_DYN_DOP36M96GL",
		],
		geometriesToShowIsland2And3: [
			"ISL_DYN_DOP36C86D"
		],

	},

	//French Door-4DR Flex
	DRF36C500MT: {
		manual: true,
		position: [4.424, -0.965, 0.904],
		rotation: [0, 90, 0],
		geometryToHide: [],
		geometryToDisplay: [],
		geometriesToShow2And3: [
			"LW_DYN_DRF36C700",
			"Plane011",
			"Plane011_1",
		],

	},


	//French Door-4DR Flex
	DRF36C500SR: {
		manual: true,
		position: [4.424, -0.965, 0.904],
		rotation: [0, 90, 0],
		geometryToHide: [],
		geometryToDisplay: [],
		geometriesToShow2And3: [
			"LW_DYN_DRF36C700",
			"Plane011",
			"Plane011_1",

		],

	},
	//French Door - 3DR
	DRF365300AP: {
		manual: true,
		position: [3.72, -0.965, 7.924],
		rotation: [0, 90, 0],
		geometryToHide: [],
		geometryToDisplay: [],
		geometriesToShow2And3: [
			"LW_DYN_DRF36C700",
			'Plane012',
			"Plane012_1"
		],

	},
	//French Door - 3DR
	DRF365300APM: {
		manual: true,
		position: [3.73, -0.965, 7.925],
		rotation: [0, 90, 0],
		geometryToHide: [],
		geometryToDisplay: [],
		geometriesToShow2And3: [
			"LW_DYN_DRF36C700",
			'Plane012',
			"Plane012_1"
		],

	},
	//French Door - 3DR
	DRF365300APS: {
		manual: true,
		position: [3.73, -0.965, 7.925],
		rotation: [0, 90, 0],
		geometryToHide: [],
		geometryToDisplay: [],
		geometriesToShow2And3: [
			"LW_DYN_DRF36C700",
			'Plane012',
			"Plane012_1"
		],

	},
	//French Door - 3DR
	DRF367500AP: {
		manual: true,
		position: [3.73, -0.965, 7.925],
		rotation: [0, 90, 0],
		geometryToHide: [],
		geometryToDisplay: [],
		geometriesToShow2And3: [
			"LW_DYN_36",
			'Plane012',
			"Plane012_1"
		],

	},
	//French Door - 4DR
	DRF425300AP: {
		manual: true,
		position: [6.72, -0.97, 4.978],
		rotation: [0, 90, 0],
		geometryToDisplay: [],
		geometriesToShow2And3: [
			'LW_DYN_42',
			'Plane013',
			'Plane013_1'
		],

	},
	DRF425300APM: {
		manual: true,
		position: [6.72, -0.97, 4.978],
		rotation: [0, 90, 0],
		geometryToDisplay: [],
		geometriesToShow2And3: [
			'LW_DYN_42',
			'Plane013',
			'Plane013_1'
		],

	},
	DRF425300APS: {
		manual: true,
		position: [6.72, -0.97, 4.978],
		rotation: [0, 90, 0],
		geometryToDisplay: [],
		geometriesToShow2And3: [
			'LW_DYN_42',
			'Plane013',
			'Plane013_1'
		],

	},
	//French Door - 4DR
	DRF427500AP: {
		manual: true,
		position: [6.72, -0.97, 4.978],
		rotation: [0, 90, 0],
		geometryToDisplay: [],
		geometriesToShow2And3: [
			'LW_DYN_42',
			'Plane013',
			'Plane013_1'
		],

	},

	//French Door - 4DR
	DRF485300AP: {
		manual: true,
		position: [9.72, -0.97, 2.056],
		rotation: [0, 90, 0],
		geometryToDisplay: [],
		geometriesToShow2And3: [
			'LW_DYN_48',
			'Plane014',
			'Plane014_1'
		],

	},
	//French Door - 4DR
	DRF487500AP: {
		manual: true,
		position: [9.72, -0.97, 2.056],
		rotation: [0, 90, 0],
		geometryToDisplay: [],
		geometriesToShow2And3: [
			'LW_DYN_48',
			'Plane014',
			'Plane014_1'
		],

	},
	//Side by Side
	DRS425300SR: {
		manual: true,
		position: [9.76, -0.966, 5.98],
		rotation: [0, 90, 0],
		geometryToDisplay: [],
		geometryToHide: [],
		geometriesToShow2And3: [
			'LW_DYN_42',
			'Plane013',
			'Plane013_1'
		],

	},





};

// | 3 | 2 | 1 |
const columnsPos = {
	//done update
	"DRW24": {
		"manual": true,
		"position": [14.675, -0.97, -1.25],
		//done
		"DRW24": {
			"manual": true,
			"position": [14.675, -0.97, -0.6375],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, -0.028]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.62, -0.97, 0.9705]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.952]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.4, -0.97, -4.876]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 3.895]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 0.97105]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.954]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.8755]
			}
		},
		//done
		"DRR24": {
			"manual": true,
			"position": [-0.622, -0.97, 0.361],

			"DRW24": {//good
				"manual": true,
				"position": [14.675, -0.97, -0.032]
			},
			"DRR24": {//good
				"manual": true,
				"position": [-0.622, -0.97, 0.97105]
			},
			"DRR30": {//good
				"manual": true,
				"position": [2.382, -0.97, -1.952]
			},
			"DRR36": {// good
				"manual": true,
				"position": [5.382, -0.97, -4.876]
			},
			"DRZ18": {//good
				"manual": true,
				"position": [-3.6185, -0.97, 3.895]
			},
			"DRZ24": {//good
				"manual": true,
				"position": [-0.622, -0.97, 0.97105]
			},
			"DRZ30": {// good
				"manual": true,
				"position": [2.382, -0.97, -1.954]
			},
			"DRZ36": {//good
				"manual": true,
				"position": [5.382, -0.97, -4.8755]
			}
		},
		//done
		"DRR30": {
			"manual": true,
			"position": [2.382, -0.97, -2.562],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.115]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.62, -0.97, 1.125]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.798]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.723]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.05]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.62, -0.97, 1.125]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.798]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.723]
			}
		},
		//done
		"DRR36": {
			"manual": true,
			"position": [5.4, -0.97, -5.484],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.281]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.282]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.645]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.57]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.203]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.282]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.644]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.577]
			}
		},
		//done
		"DRZ18": {
			"manual": true,
			"position": [-3.6185, -0.97, 3.285],
			"DRW24": {//done
				"manual": true,
				"position": [14.69, -0.97, -0.182]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 0.818]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -2.105]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, -5.029]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 3.743]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 0.818]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -2.105]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -5.029]
			}
		},
		//done
		"DRZ24": {
			"manual": true,
			"position": [-0.622, -0.97, 0.361],
			"DRW24": {//good
				"manual": true,
				"position": [14.675, -0.97, -0.032]
			},
			"DRR24": {//good
				"manual": true,
				"position": [-0.622, -0.97, 0.97105]
			},
			"DRR30": {//good
				"manual": true,
				"position": [2.382, -0.97, -1.952]
			},
			"DRR36": {// good
				"manual": true,
				"position": [5.382, -0.97, -4.876]
			},
			"DRZ18": {//good
				"manual": true,
				"position": [-3.6185, -0.97, 3.895]
			},
			"DRZ24": {//good
				"manual": true,
				"position": [-0.622, -0.97, 0.97105]
			},
			"DRZ30": {// good
				"manual": true,
				"position": [2.382, -0.97, -1.954]
			},
			"DRZ36": {//good
				"manual": true,
				"position": [5.382, -0.97, -4.8755]
			}
		},
		//done
		"DRZ30": {
			"manual": true,
			"position": [2.382, -0.97, -2.562],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.115]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.62, -0.97, 1.125]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.798]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.723]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.05]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.62, -0.97, 1.125]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.798]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.723]
			}
		},
		//done
		"DRZ36": {
			"manual": true,
			"position": [5.382, -0.97, -5.493],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.281]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.282]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.645]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.57]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.203]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.282]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.644]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.577]
			}
		}
	},
	//done update
	"DRR24": {
		manual: true,
		"position": [-0.622, -0.97, -0.25],
		//done
		"DRW24": {
			"manual": true,
			"position": [14.675, -0.97, -0.6375],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, -0.028]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.62, -0.97, 0.9705]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.952]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.4, -0.97, -4.876]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 3.895]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 0.97105]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.954]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.8755]
			}
		},
		//done
		"DRR24": {
			"manual": true,
			"position": [-0.622, -0.97, 0.361],

			"DRW24": {//good
				"manual": true,
				"position": [14.675, -0.97, -0.032]
			},
			"DRR24": {//good
				"manual": true,
				"position": [-0.622, -0.97, 0.97105]
			},
			"DRR30": {//good
				"manual": true,
				"position": [2.382, -0.97, -1.952]
			},
			"DRR36": {// good
				"manual": true,
				"position": [5.382, -0.97, -4.876]
			},
			"DRZ18": {//good
				"manual": true,
				"position": [-3.6185, -0.97, 3.895]
			},
			"DRZ24": {//good
				"manual": true,
				"position": [-0.622, -0.97, 0.97105]
			},
			"DRZ30": {// good
				"manual": true,
				"position": [2.382, -0.97, -1.954]
			},
			"DRZ36": {//good
				"manual": true,
				"position": [5.382, -0.97, -4.8755]
			}
		},
		//done
		"DRR30": {
			"manual": true,
			"position": [2.382, -0.97, -2.562],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.115]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.62, -0.97, 1.125]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.798]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.723]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.05]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.62, -0.97, 1.125]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.798]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.723]
			}
		},
		//done
		"DRR36": {
			"manual": true,
			"position": [5.4, -0.97, -5.484],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.281]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.282]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.645]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.57]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.203]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.282]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.644]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.577]
			}
		},
		//done
		"DRZ18": {
			"manual": true,
			"position": [-3.6185, -0.97, 3.285],
			"DRW24": {//done
				"manual": true,
				"position": [14.69, -0.97, -0.182]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 0.818]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -2.105]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, -5.029]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 3.743]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 0.818]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -2.105]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -5.029]
			}
		},
		//done
		"DRZ24": {
			"manual": true,
			"position": [-0.622, -0.97, 0.361],
			"DRW24": {//good
				"manual": true,
				"position": [14.675, -0.97, -0.032]
			},
			"DRR24": {//good
				"manual": true,
				"position": [-0.622, -0.97, 0.97105]
			},
			"DRR30": {//good
				"manual": true,
				"position": [2.382, -0.97, -1.952]
			},
			"DRR36": {// good
				"manual": true,
				"position": [5.382, -0.97, -4.876]
			},
			"DRZ18": {//good
				"manual": true,
				"position": [-3.6185, -0.97, 3.895]
			},
			"DRZ24": {//good
				"manual": true,
				"position": [-0.622, -0.97, 0.97105]
			},
			"DRZ30": {// good
				"manual": true,
				"position": [2.382, -0.97, -1.954]
			},
			"DRZ36": {//good
				"manual": true,
				"position": [5.382, -0.97, -4.8755]
			}
		},
		//done
		"DRZ30": {
			"manual": true,
			"position": [2.382, -0.97, -2.562],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.115]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.62, -0.97, 1.125]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.798]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.723]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.05]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.62, -0.97, 1.125]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.798]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.723]
			}
		},
		//done
		"DRZ36": {
			"manual": true,
			"position": [5.382, -0.97, -5.493],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.281]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.282]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.645]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.57]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.203]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.282]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.644]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.577]
			}
		}
	},
	//done update
	"DRR30": {
		'manual': true,
		"position": [2.382, -0.97, -3.18],
		//done
		"DRW24": {
			"manual": true,
			"position": [14.675, -0.97, -0.492],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.12]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.117]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.805]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.73]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.042]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.117]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.805]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.73]
			},
		},
		//done
		"DRR24": {
			"manual": true,
			"position": [-0.622, -0.97, 0.508],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.12]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.117]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.805]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.73]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.042]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.117]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.805]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.73]
			},
		},
		//done
		"DRR30": {
			"manual": true,
			"position": [2.382, -0.97, -2.413],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.274]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.274]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.645]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.4, -0.97, -4.572]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.2]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.274]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.645]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.4, -0.97, -4.572]
			},
		},
		//done
		"DRR36": {
			"manual": true,
			"position": [5.382, -0.97, -5.335],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.432]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.432]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.492]
			},
			"DRR36": {//set over 36
				"manual": true,
				"setsO": true,
				"position": [5.4, -0.97, -4.435]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.354]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.432]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.492]
			},
			"DRZ36": {//set over 36
				"manual": true,
				"setsO": true,
				"position": [5.4, -0.97, -4.435]
			},
		},
		//done
		"DRZ18": {
			"manual": true,
			"position": [-3.6185, -0.97, 3.435],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, -0.029]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 0.97]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.953]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.875]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 3.895]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 0.97]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.953]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.875]
			},

		},
		//done
		"DRZ24": {
			"manual": true,
			"position": [-0.622, -0.97, 0.508],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.12]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.117]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.805]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.73]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.042]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.117]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.805]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.73]
			},
		},
		//done
		"DRZ30": {
			"manual": true,
			"position": [2.382, -0.97, -2.413],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.274]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.274]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.645]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.4, -0.97, -4.572]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.2]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.274]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.645]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.4, -0.97, -4.572]
			},
		},
		//done
		"DRZ36": {
			"manual": true,
			"position": [5.382, -0.97, -5.335],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.432]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.432]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.492]
			},
			"DRR36": {//set over 36
				"manual": true,
				"setsO": true,
				"position": [5.4, -0.97, -4.435]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.354]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.432]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.492]
			},
			"DRZ36": {//set over 36
				"manual": true,
				"setsO": true,
				"position": [5.4, -0.97, -4.435]
			},
		},
	},
	//done update
	"DRR36": {
		'manual': true,
		"position": [5.382, -0.97, -6.1],
		//done
		"DRW24": {
			"manual": true,
			"position": [14.675, -0.97, -0.335],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.276]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.278]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.645]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.57]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.199]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.278]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.645]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.57]
			},
		},
		//done
		"DRR24": {
			"manual": true,
			"position": [-0.622, -0.97, 0.665],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.277]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.277]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.645]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, 4.572]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.2]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.277]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.645]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, 4.572]
			},
		},
		//done
		"DRR30": {
			"manual": true,
			"position": [2.382, -0.97, -2.258],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.432]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.432]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.495]
			},
			"DRR36": {//set over 36
				"manual": true,
				"setsO": true,
				"position": [
					5.4,
					-0.97,
					-4.435
				]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.352]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.432]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.495]
			},
			"DRZ36": {//set over 36
				"manual": true,
				"setsO": true,
				"position": [
					5.4,
					-0.97,
					-4.435
				]
			},
		},
		//done
		"DRR36": {
			"manual": true,
			"position": [5.382, -0.97, -5.183],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.583]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.581]
			},
			"DRR30": {//set over 96
				"manual": true,
				"setsO": true,
				"position": [2.382, -0.97,
					-1.362
				]
			},
			"DRR36": {//set over 96
				"manual": true,
				"setsO": true,
				"position": [
					5.4,
					-0.97,
					-4.285
				]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.503]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.581]
			},
			"DRZ30": {//set over 96
				"manual": true,
				"setsO": true,
				"position": [2.382, -0.97, 2.647]
			},
			"DRZ36": {//set over 96
				"manual": true,
				"setsO": true,
				"position": [
					5.4,
					-0.97,
					5.716
				]
			}
		},
		//done
		"DRZ18": {
			"manual": true,
			"position": [-3.6185, -0.97, 3.586],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.12]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.121]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.804]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.728]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.042]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.121]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.804]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.728]
			},
		},
		//done
		"DRZ24": {
			"manual": true,
			"position": [-0.622, -0.97, 0.665],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.277]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.277]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.645]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, 4.572]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.2]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.277]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.645]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, 4.572]
			},
		},
		//done
		"DRZ30": {
			"manual": true,
			"position": [2.382, -0.97, -2.258],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.432]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.432]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.495]
			},
			"DRR36": {//set over 36
				"manual": true,
				"setsO": true,
				"position": [
					5.4,
					-0.97,
					-4.435
				]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.352]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.432]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.495]
			},
			"DRZ36": {//set over 36
				"manual": true,
				"setsO": true,
				"position": [
					5.4,
					-0.97,
					-4.435
				]
			},
		},
		//done
		"DRZ36": {
			"manual": true,
			"position": [5.382, -0.97, -5.183],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.583]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.581]
			},
			"DRR30": {//set over 96
				"manual": true,
				"setsO": true,
				"position": [2.382, -0.97,
					-1.362
				]
			},
			"DRR36": {//set over 96
				"manual": true,
				"setsO": true,
				"position": [
					5.4,
					-0.97,
					-4.285
				]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.503]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.581]
			},
			"DRZ30": {//set over 96
				"manual": true,
				"setsO": true,
				"position": [2.382, -0.97, 2.647]
			},
			"DRZ36": {//set over 96
				"manual": true,
				"setsO": true,
				"position": [
					5.4,
					-0.97,
					5.716
				]
			}
		},
	},
	//done update
	"DRZ18": {
		"position": [-3.6185, -0.97, 2.67],
		//done
		"DRW24": {
			"manual": true,
			"position": [14.675, -0.97, -0.795],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, -0.1855]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 0.815]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -2.11]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, -5.034]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 3.737]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 0.815]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -2.109]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -5.034]
			}
		},
		//done
		"DRR24": {
			"manual": true,
			"position": [-0.622, -0.97, 0.205],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, -0.1855]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 0.815]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -2.11]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, -5.034]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 3.737]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 0.815]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -2.11]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -5.034]
			}
		},
		//done
		"DRR30": {
			"manual": true,
			"position": [2.382, -0.97, -2.72],
			"DRW24": {//good
				"manual": true,
				"position": [14.675, -0.97, -0.033]
			},
			"DRR24": {//good
				"manual": true,
				"position": [-0.622, -0.97, 0.968]
			},
			"DRR30": {//good
				"manual": true,
				"position": [2.382, -0.97, -1.955]
			},
			"DRR36": {// good
				"manual": true,
				"position": [5.382, -0.97, -4.88]
			},
			"DRZ18": {//good
				"manual": true,
				"position": [-3.6185, -0.97, 3.894]
			},
			"DRZ24": {//good
				"manual": true,
				"position": [-0.622, -0.97, 0.968]
			},
			"DRZ30": {// good
				"manual": true,
				"position": [2.382, -0.97, -1.955]
			},
			"DRZ36": {//good
				"manual": true,
				"position": [5.382, -0.97, -4.88]
			}
		},
		//done
		"DRR36": {
			"manual": true,
			"position": [5.382, -0.97, -5.642],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.125]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.12]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.802]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.726]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.045]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.12]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.802]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.726]
			}
		},
		//done
		"DRZ18": {
			"manual": true,
			"position": [-3.6185, -0.97, 3.13],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, -0.336]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 0.664]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -2.261]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, -5.185]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 3.585]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 0.664]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -2.261]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -5.185]
			},
		},
		//done
		"DRZ24": {
			"manual": true,
			"position": [-0.622, -0.97, 0.205],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, -0.1855]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 0.815]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -2.11]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, -5.034]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 3.737]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 0.815]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -2.11]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -5.034]
			}
		},
		//done
		"DRZ30": {
			"manual": true,
			"position": [2.382, -0.97, -2.72],
			"DRW24": {//good
				"manual": true,
				"position": [14.675, -0.97, -0.033]
			},
			"DRR24": {//good
				"manual": true,
				"position": [-0.622, -0.97, 0.968]
			},
			"DRR30": {//good
				"manual": true,
				"position": [2.382, -0.97, -1.955]
			},
			"DRR36": {// good
				"manual": true,
				"position": [5.382, -0.97, -4.88]
			},
			"DRZ18": {//good
				"manual": true,
				"position": [-3.6185, -0.97, 3.894]
			},
			"DRZ24": {//good
				"manual": true,
				"position": [-0.622, -0.97, 0.968]
			},
			"DRZ30": {// good
				"manual": true,
				"position": [2.382, -0.97, -1.955]
			},
			"DRZ36": {//good
				"manual": true,
				"position": [5.382, -0.97, -4.88]
			}
		},
		//done
		"DRZ36": {
			"manual": true,
			"position": [5.382, -0.97, -5.642],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.125]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.12]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.802]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.726]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.045]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.12]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.802]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.726]
			}
		},
	},
	//done update
	"DRZ24": {
		manual: true,
		"position": [-0.622, -0.97, -0.25],
		//done
		"DRW24": {
			"manual": true,
			"position": [14.675, -0.97, -0.6375],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, -0.028]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.62, -0.97, 0.9705]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.952]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.4, -0.97, -4.876]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 3.895]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 0.97105]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.954]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.8755]
			}
		},
		//done
		"DRR24": {
			"manual": true,
			"position": [-0.622, -0.97, 0.361],

			"DRW24": {//good
				"manual": true,
				"position": [14.675, -0.97, -0.032]
			},
			"DRR24": {//good
				"manual": true,
				"position": [-0.622, -0.97, 0.97105]
			},
			"DRR30": {//good
				"manual": true,
				"position": [2.382, -0.97, -1.952]
			},
			"DRR36": {// good
				"manual": true,
				"position": [5.382, -0.97, -4.876]
			},
			"DRZ18": {//good
				"manual": true,
				"position": [-3.6185, -0.97, 3.895]
			},
			"DRZ24": {//good
				"manual": true,
				"position": [-0.622, -0.97, 0.97105]
			},
			"DRZ30": {// good
				"manual": true,
				"position": [2.382, -0.97, -1.954]
			},
			"DRZ36": {//good
				"manual": true,
				"position": [5.382, -0.97, -4.8755]
			}
		},
		//done
		"DRR30": {
			"manual": true,
			"position": [2.382, -0.97, -2.562],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.115]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.62, -0.97, 1.125]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.798]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.723]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.05]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.62, -0.97, 1.125]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.798]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.723]
			}
		},
		//done
		"DRR36": {
			"manual": true,
			"position": [5.4, -0.97, -5.484],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.281]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.282]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.645]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.57]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.203]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.282]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.644]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.577]
			}
		},
		//done
		"DRZ18": {
			"manual": true,
			"position": [-3.6185, -0.97, 3.285],
			"DRW24": {//done
				"manual": true,
				"position": [14.69, -0.97, -0.182]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 0.818]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -2.105]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, -5.029]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 3.743]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 0.818]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -2.105]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -5.029]
			}
		},
		//done
		"DRZ24": {
			"manual": true,
			"position": [-0.622, -0.97, 0.361],
			"DRW24": {//good
				"manual": true,
				"position": [14.675, -0.97, -0.032]
			},
			"DRR24": {//good
				"manual": true,
				"position": [-0.622, -0.97, 0.97105]
			},
			"DRR30": {//good
				"manual": true,
				"position": [2.382, -0.97, -1.952]
			},
			"DRR36": {// good
				"manual": true,
				"position": [5.382, -0.97, -4.876]
			},
			"DRZ18": {//good
				"manual": true,
				"position": [-3.6185, -0.97, 3.895]
			},
			"DRZ24": {//good
				"manual": true,
				"position": [-0.622, -0.97, 0.97105]
			},
			"DRZ30": {// good
				"manual": true,
				"position": [2.382, -0.97, -1.954]
			},
			"DRZ36": {//good
				"manual": true,
				"position": [5.382, -0.97, -4.8755]
			}
		},
		//done
		"DRZ30": {
			"manual": true,
			"position": [2.382, -0.97, -2.562],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.115]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.62, -0.97, 1.125]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.798]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.723]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.05]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.62, -0.97, 1.125]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.798]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.723]
			}
		},
		//done
		"DRZ36": {
			"manual": true,
			"position": [5.382, -0.97, -5.493],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.281]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.282]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.645]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.57]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.203]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.282]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.644]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.577]
			}
		}
	},
	//done update
	"DRZ30": {
		'manual': true,
		"position": [2.382, -0.97, -3.18],
		//done
		"DRW24": {
			"manual": true,
			"position": [14.675, -0.97, -0.492],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.12]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.117]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.805]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.73]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.042]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.117]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.805]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.73]
			},
		},
		//done
		"DRR24": {
			"manual": true,
			"position": [-0.622, -0.97, 0.508],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.12]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.117]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.805]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.73]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.042]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.117]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.805]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.73]
			},
		},
		//done
		"DRR30": {
			"manual": true,
			"position": [2.382, -0.97, -2.413],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.274]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.274]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.645]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.4, -0.97, -4.572]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.2]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.274]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.645]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.4, -0.97, -4.572]
			},
		},
		//done
		"DRR36": {
			"manual": true,
			"position": [5.382, -0.97, -5.335],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.432]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.432]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.492]
			},
			"DRR36": {//set over 36
				"manual": true,
				"setsO": true,
				"position": [5.4, -0.97, -4.435]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.354]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.432]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.492]
			},
			"DRZ36": {//set over 36
				"manual": true,
				"setsO": true,
				"position": [5.4, -0.97, -4.435]
			},
		},
		//done
		"DRZ18": {
			"manual": true,
			"position": [-3.6185, -0.97, 3.435],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, -0.029]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 0.97]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.953]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.875]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 3.895]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 0.97]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.953]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.875]
			},

		},
		//done
		"DRZ24": {
			"manual": true,
			"position": [-0.622, -0.97, 0.508],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.12]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.117]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.805]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.73]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.042]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.117]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.805]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.73]
			},
		},
		//done
		"DRZ30": {
			"manual": true,
			"position": [2.382, -0.97, -2.413],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.274]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.274]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.645]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.4, -0.97, -4.572]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.2]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.274]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.645]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.4, -0.97, -4.572]
			},
		},
		//done
		"DRZ36": {
			"manual": true,
			"position": [5.382, -0.97, -5.335],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.432]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.432]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.492]
			},
			"DRR36": {//set over 36
				"manual": true,
				"setsO": true,
				"position": [5.4, -0.97, -4.435]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.354]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.432]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.492]
			},
			"DRZ36": {//set over 36
				"manual": true,
				"setsO": true,
				"position": [5.4, -0.97, -4.435]
			},
		},
	},
	//done update
	"DRZ36": {
		'manual': true,
		"position": [5.382, -0.97, -6.1],
		//done
		"DRW24": {
			"manual": true,
			"position": [14.675, -0.97, -0.335],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.276]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.278]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.645]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.57]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.199]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.278]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.645]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.57]
			},
		},
		//done
		"DRR24": {
			"manual": true,
			"position": [-0.622, -0.97, 0.665],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.277]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.277]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.645]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, 4.572]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.2]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.277]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.645]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, 4.572]
			},
		},
		//done
		"DRR30": {
			"manual": true,
			"position": [2.382, -0.97, -2.258],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.432]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.432]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.495]
			},
			"DRR36": {//set over 36
				"manual": true,
				"setsO": true,
				"position": [
					5.4,
					-0.97,
					-4.435
				]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.352]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.432]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.495]
			},
			"DRZ36": {//set over 36
				"manual": true,
				"setsO": true,
				"position": [
					5.4,
					-0.97,
					-4.435
				]
			},
		},
		//done
		"DRR36": {
			"manual": true,
			"position": [5.382, -0.97, -5.183],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.583]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.581]
			},
			"DRR30": {//set over 96
				"manual": true,
				"setsO": true,
				"position": [2.382, -0.97,
					-1.362
				]
			},
			"DRR36": {//set over 96
				"manual": true,
				"setsO": true,
				"position": [
					5.4,
					-0.97,
					-4.285
				]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.503]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.581]
			},
			"DRZ30": {//set over 96
				"manual": true,
				"setsO": true,
				"position": [2.382, -0.97, 2.647]
			},
			"DRZ36": {//set over 96
				"manual": true,
				"setsO": true,
				"position": [
					5.4,
					-0.97,
					5.716
				]
			}
		},
		//done
		"DRZ18": {
			"manual": true,
			"position": [-3.6185, -0.97, 3.586],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.12]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.121]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.804]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.728]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.042]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.121]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.804]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, -4.728]
			},
		},
		//done
		"DRZ24": {
			"manual": true,
			"position": [-0.622, -0.97, 0.665],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.277]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.277]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.645]
			},
			"DRR36": {//done
				"manual": true,
				"position": [5.382, -0.97, 4.572]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.2]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.277]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.645]
			},
			"DRZ36": {//done
				"manual": true,
				"position": [5.382, -0.97, 4.572]
			},
		},
		//done
		"DRZ30": {
			"manual": true,
			"position": [2.382, -0.97, -2.258],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.432]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.432]
			},
			"DRR30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.495]
			},
			"DRR36": {//set over 36
				"manual": true,
				"setsO": true,
				"position": [
					5.4,
					-0.97,
					-4.435
				]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.352]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.432]
			},
			"DRZ30": {//done
				"manual": true,
				"position": [2.382, -0.97, -1.495]
			},
			"DRZ36": {//set over 36
				"manual": true,
				"setsO": true,
				"position": [
					5.4,
					-0.97,
					-4.435
				]
			},
		},
		//done
		"DRZ36": {
			"manual": true,
			"position": [5.382, -0.97, -5.183],
			"DRW24": {//done
				"manual": true,
				"position": [14.675, -0.97, 0.583]
			},
			"DRR24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.581]
			},
			"DRR30": {//set over 96
				"manual": true,
				"setsO": true,
				"position": [2.382, -0.97,
					-1.362
				]
			},
			"DRR36": {//set over 96
				"manual": true,
				"setsO": true,
				"position": [
					5.4,
					-0.97,
					-4.285
				]
			},
			"DRZ18": {//done
				"manual": true,
				"position": [-3.6185, -0.97, 4.503]
			},
			"DRZ24": {//done
				"manual": true,
				"position": [-0.622, -0.97, 1.581]
			},
			"DRZ30": {//set over 96
				"manual": true,
				"setsO": true,
				"position": [2.382, -0.97, 2.647]
			},
			"DRZ36": {//set over 96
				"manual": true,
				"setsO": true,
				"position": [
					5.4,
					-0.97,
					5.716
				]
			}
		},
	},
}


export const modelFiles = {
	DRW24980LAPS: "DRW24980LAPS",
	DRF36C500MT: 'DRF36C500MT',
	DRF36C500SR: 'DRF36C500SR',
	DRF365300AP: 'DRF365300AP',
	DRF365300APM: 'DRF365300APM',
	DRF365300APS: 'DRF365300APS',
	DRW24980: "DRW24980",
	DRW24980LAPM: "DRW24980LAPM",
	DRW24980RAP: "DRW24980RAP",
	DRW24980LAP: "DRW24980LAP",
	DRW24980RAPM: "DRW24980RAPM",
	DRW24980RAPS: "DRW24980RAPS",
	DWS4000BB: "DWS4000BB",
	DTG30M954FM: "DTG30M954FM",
	DTG30M954FS: "DTG30M954FS",
	DTG36M955FM: "DTG36M955FM",
	DTG36M955FS: "DTG36M955FS",
	DTI30M977BB: "DTI30M977BB",
	DTI36M977BB: "DTI36M977BB",
	DOP36C86DLM: "DOP36M86DM",
	DOP36C86DLS: "DOP36M86DS",
	DOP36M96GLM: "DOP36M96GLM",
	DOP36C96GLM: "DOP36C96GLM",

	DOP36M96GLS: "DOP36M96GLS",
	DOP36C96GLS: "DOP36C96GLS",

	DOP48C86DLM: "DOP48M86DM",
	DOP48C86DLS: "DOP48M86DS",
	DOP48C96DLM: "DOP48M96DM",
	DOP48C96DLS: "DOP48M96DS",
	DOB30M977DM: "DOB30M977DM",
	DOB30M977DS: "DOB30M977DS",
	DOB30M977SM: "DOB30M977SM",
	DOB30M977SS: "DOB30M977SS",
	DOC30M977DM: "DOC30M977DM",
	DOC30M977DS: "DOC30M977DS",
	DTG30P875NS: "DTG30P875NS",
	DTG36P875NS: "DTG36P875NS",
	DTI30P876BB: "DTI30P876BB",
	DTI36P876BB: "DTI36P876BB",
	DOP30T840GS: "DOP30T840GS",
	DOP30T940DS: "DOP30T840GS",
	DOP36T86DLS: "DOP36T86DLS",
	DOP36T86GLS: "DOP36T86DLS",
	DOP48T960GS: "DOP48T960GS",
	DOP48T960DS: "DOP48T960GS",
	DOP48T963DS: "DOP48T963DS",
	DTT36T960GM: "DTT36T960GM",
	DTT36T960GS: "DTT36T960GS",
	DTT48T960GM: "DTT48T960GM",
	DTT48T960GS: "DTT48T960GS",
	DTT48T963GM: "DTT48T963GM",
	DTT48T963GS: "DTT48T963GS",
	DOB30T977DS: "DOB30T977DS",
	DOB30T977SS: "DOB30T977SS",
	DOC30T977DS: "DOC30T977DS",
	DRR24980LAP: "DRZ24980LAP",
	DRR24980RAP: "DRZ24980RAP",
	DRR30980LAP: "DRZ30980LAP",
	DRR30980RAP: "DRZ30980RAP",
	DRR36980LAP: "DRZ36980LAP",
	DRR36980RAP: "DRZ36980RAP",
	DRZ18980LAP: "DRZ18980LAP",
	DRZ18980RAP: "DRZ18980RAP",
	DRZ24980LAP: "DRZ24980LAP",
	DRZ24980RAP: "DRZ24980RAP",
	DRZ30980LAP: "DRZ30980LAP",
	DRZ30980RAP: "DRZ30980RAP",
	DRZ36980LAP: "DRZ36980LAP",
	DRZ36980RAP: "DRZ36980RAP",
	DRF36C500: "DRF36C500",
	DRF365300AP: "DRF365300AP",
	DRF367500AP: "DRF365300AP",
	DRF425300AP: "DRF425300AP",
	DRF427500AP: "DRF425300AP",
	DRF485300AP: "DRF485300AP",
	DRF487500AP: "DRF485300AP",
	DRF487500AP: "DRF485300AP",
	DRS425300SR: "DRS425300SR",
	DHD30U790LS: "DHD30U790LS",
	DHD36U790LS: "DHD36U790LS",
	DHD48U790LS: "DHD48U790LS",
	DHD30U990CM: "DHD30U990CM",
	DHD30U990CS: "DHD30U990CS",
	DHD36U990CM: "DHD36U990CM",
	DHD36U990CS: "DHD36U990CS",
	DHD48U990CM: "DHD48U990CM",
	DHD48U990CS: "DHD48U990CS",
	DHD36U990IM: "DHD36U990IM",
	DHD36U990IS: "DHD36U990IS",
	DHD42U990IM: "DHD42U990IM",
	DHD42U990IS: "DHD42U990IS",
	DHD54U990IM: "DHD54U990IM",
	DHD54U990IS: "DHD54U990IS",
	DHD30U990WM: "DHD30U990WM",
	DHD30U990WS: "DHD30U990WS",
	DHD36U990WM: "DHD36U990WM",
	DHD36U990WS: "DHD36U990WS",
	DHD48U990WM: "DHD48U990WM",
	DHD48U990WS: "DHD48U990WS",
	DWR30U900WM: "DWR30U900WM",
	DWR30U900WP: "DWR30U900WP-k1",
	DWR30U900WS: "DWR30U900WS",
	DDW24M999UM: "DDW24M999UM",
	DDW24G9000APDAM: "DDW24G9000APDAM",
	DDW24M999US: "DDW24M999US",
	DDW24G9000APDAS: "DDW24G9000APDAS",
	DDW24T999BB: "DDW24T999BB-k1",
	DDW24G9000APDA: 'DDW24G9000APDA-k1',
	DCM24S: "DCM24S",
	Cabinets_Right005: "Cabinets_Right005",
	DMR24M977WM: "DMR24M977WM",
	DMR24M977WS: "DMR24M977WS",
	DMR30M977WM: "DMR30M977WM",
	DMR30M977WS: "DMR30M977WS",
	DMW2420S: "DMW2420S",
	PCOR30S: "PCOR30S",
	HWC242R: "HWC242R",
	HWC242L: "HWC242R",
};

export const modelFiles2 = {
	DDW24T999BB: "DDW24T999BB-k2",
	DDW24G9000APDA: 'DDW24G9000APDA-k2',
	// DRF365300AP:"DRF365300AP-k2",
	// DRF425300AP:"DRF425300AP-k2",
	// DRF485300AP:"DRF485300AP-k2",
	// DRW24980: "DRW24980-k2",
	// DRZ18980RAP: "DRZ18980RAP-k2",
	// DRZ24980RAP: "DRZ24980RAP-k2",
	// DRZ30980RAP: "DRZ30980RAP-k2",
	// DRZ36980RAP: "DRZ36980RAP-k2",
	DWR30U900WP: "DWR30U900WP-k2",
}

export const modelFiles3 = {
	DDW24T999BB: "DDW24T999BB-k3",
	DDW24G9000APDA: 'DDW24G9000APDA-k3',
	// DRF365300AP:"DRF365300AP-k3",
	// DRF425300AP:"DRF425300AP-k3",
	// DRF485300AP:"DRF485300AP-k3",
	// DRW24980: "DRW24980-k3",
	// DRZ18980RAP: "DRZ18980RAP-k3",
	// DRZ24980RAP: "DRZ24980RAP-k3",
	// DRZ30980RAP: "DRZ30980RAP-k3",
	// DRZ36980RAP: "DRZ36980RAP-k3",
	DWR30U900WP: "DWR30U900WP-k3",
}

window.positions = positions;

export const getPosition = (sku, obj = {}) => {
	let id = window.location.pathname
	sku = sku.split("/")[0];
	if (sku.startsWith('DWS400')) {
		konsole.log('POSITION BEFORE', sku, obj)
		sku = obj.file
	}
	obj = JSON.parse(JSON.stringify(obj));
	let { type = "", prdFiles = [], isIsland = false, wallOvenCookTop } = obj;
	if ((sku.startsWith("DOP") || sku.startsWith("DT")) && !type) {
		type = isIsland ? "island" : "kitchen";
	}




	let allSkus = prdFiles.map((x) => x.sku)

	let file = sku;
	let posObj = positions[file];
	if (!posObj) {
		file = file.slice(0, -1);
		posObj = positions[file];
	}

	if (file.includes("RAP")) file = file.replace("RAP", "");
	if (file.includes("LAP")) file = file.replace("LAP", "");


	let position = [0, 0, 0],
		rotation = [0, 0, 0],
		scale = [1, 1, 1];

	if (obj.hasOwnProperty('column')) {
		let columnRow = obj.columns.map(x => x.substr(0, 5))

		let first = columnsPos[columnRow[0]]
		let second, third;

		if (columnRow[1] && first[columnRow[1]]) {
			second = first[columnRow[1]]
		}
		if (columnRow[2] && second[columnRow[2]]) {
			third = second[columnRow[2]]
		}
		let poses = [first, second, third]
		posObj = {
			manual: true,
			position: poses[obj.column].position,
			rotation: [0, Math.PI / 2, 0]
		}

	}


	if (sku.startsWith('DRW') && !obj.hasOwnProperty('column')) {
	}



	if (!posObj) {
		file = file.slice(0, -1);
		posObj = positions[file];
	}
	if (!posObj) {
		file = file.slice(0, -1);
		posObj = positions[file];
	}
	// get first 5 letters
	if (!posObj) {
		file = file.slice(0, 5);
		posObj = positions[file];
	}
	if (sku.startsWith('DWS400')) {
		konsole.log('FOUND POSITION OBJECT', posObj)
	}

	if (posObj) posObj = JSON.parse(JSON.stringify(posObj));

	if (wallOvenCookTop) {
		posObj.position = isIsland ? posObj.positionInIsland : posObj.positionInKitchen
		posObj.rotation = isIsland ? [0, Math.PI, 0] : [0, 0, 0]
	}

	// if(sku.startsWith('DRR24980')){
	//     return{
	//         position:[posObj.position[0],posObj.position[1],posObj.position[2] - 0.02],
	//         rotation:[0,Math.PI/2,0]
	//     }
	// }else if(sku.startsWith('DRR30980')){
	//     return{
	//         position:[posObj.position[0],posObj.position[1],posObj.position[2] - 0.02],
	//         rotation:[0,Math.PI/2,0]
	//     }
	// }else if(sku.startsWith('DRR36980')){
	//     return{
	//         position:[posObj.position[0],posObj.position[1],posObj.position[2] - 0.02],
	//         rotation:[0,Math.PI/2,0]
	//     }
	// }

	if (id === '/kitchen3') {
		if (sku.startsWith("DDW24T999BB")) {
			posObj = positions['DDW24T999BB']
			posObj = rotation['DDW24T999BB']
			return {
				position: [0, -1.12, 7],
				rotation: [0, 0, 0]
			}
		}
	}

	if (sku.startsWith("DWR30U900WS")) {
		if (id === '/kitchen2') {
			if (obj.prdFiles.find(x => x.sku.includes('DOB30M977SS') || x.sku.includes('DOB30M977SM'))) {
				posObj = positions['DWR30U900WS']
				posObj = rotation['DWR30U900WS']
				return {
					position: [1.898, -0.163, -2.42],
					rotation: [0, 0, 0]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DOC30M977DS') || x.sku.includes('DOC30M977DM'))) {
				posObj = positions['DWR30U900WS']
				posObj = rotation['DWR30U900WS']
				return {
					position: [1.898, -0.543, -2.42],
					rotation: [0, 0, 0]
				}
			}
		} else if (id === '/kitchen3') {
			if (obj.prdFiles.find(x => x.sku.includes('DOB30M977SS') || x.sku.includes('DOB30M977SM'))) {
				posObj = positions['DWR30U900WS']
				posObj = rotation['DWR30U900WS']
				return {
					position: [1.898, -0.162, -2.42],
					rotation: [0, 0, 0]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DOC30M977DS') || x.sku.includes('DOC30M977DM'))) {
				posObj = positions['DWR30U900WS']
				posObj = rotation['DWR30U900WS']
				return {
					position: [1.898, -0.545, -2.42],
					rotation: [0, 0, 0]
				}
			}
		}
	} else if (sku.startsWith("DWR30U900WM")) {

		if (id !== '/kitchen3') {
			if (obj.prdFiles.find(x => x.sku.includes('DOC30T977DS'))) {
				posObj = positions['DWR30U900WM']
				posObj = rotation['DWR30U900WM']
				return {
					position: [-0.103, -0.51, -2.41],
					rotation: [0, 0, 0]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DOB30M977SS') || x.sku.includes('DOB30M977SM'))) {
				posObj = positions['DWR30U900WM']
				posObj = rotation['DWR30U900WM']
				return {
					position: [-0.102, -0.161, -2.41],
					rotation: [0, 0, 0]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DOC30M977DS') || x.sku.includes('DOC30M977DM'))) {
				posObj = positions['DWR30U900WM']
				posObj = rotation['DWR30U900WM']
				return {
					position: [-0.103, -0.54, -2.41],
					rotation: [0, 0, 0]
				}
			}
		} else if (id === '/kitchen3') {
			if (obj.prdFiles.find(x => x.sku.includes('DOC30T977DS'))) {
				posObj = positions['DWR30U900WM']
				posObj = rotation['DWR30U900WM']
				return {
					position: [-0.103, -0.515, -2.41],
					rotation: [0, 0, 0]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DOB30M977SS') || x.sku.includes('DOB30M977SM'))) {
				posObj = positions['DWR30U900WM']
				posObj = rotation['DWR30U900WM']
				return {
					position: [-0.102, -0.165, -2.41],
					rotation: [0, 0, 0]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DOC30M977DS') || x.sku.includes('DOC30M977DM'))) {
				posObj = positions['DWR30U900WM']
				posObj = rotation['DWR30U900WM']
				return {
					position: [-0.103, -0.545, -2.41],
					rotation: [0, 0, 0]
				}
			}
		}
	} else if (sku.startsWith("DWR30U900WP")) {
		if (id === '/kitchen2') {
			if (obj.prdFiles.find(x => x.sku.includes('DOB30T977SS'))) {
				posObj = positions['DWR30U900WP']
				posObj = rotation['DWR30U900WP']
				return {
					position: [0, -0.54, 7],
					rotation: [0, 0, 0]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DOB30T977DS') || x.sku.includes('DOB30M977DS') || x.sku.includes('DOB30M977DM'))) {
				posObj = positions['DWR30U900WP']
				posObj = rotation['DWR30U900WP']
				return {
					position: [0, -1.12, 7],
					rotation: [0, 0, 0]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DOC30T977DS'))) {
				posObj = positions['DWR30U900WP']
				posObj = rotation['DWR30U900WP']
				return {
					position: [0, -0.908, 7],
					rotation: [0, 0, 0]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DOB30M977SS') || x.sku.includes('DOB30M977SM'))) {
				posObj = positions['DWR30U900WP']
				posObj = rotation['DWR30U900WP']
				return {
					position: [0, -0.562, 7],
					rotation: [0, 0, 0]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DOC30M977DS') || x.sku.includes('DOC30M977DM'))) {
				posObj = positions['DWR30U900WP']
				posObj = rotation['DWR30U900WP']
				return {
					position: [0, -0.939, 7],
					rotation: [0, 0, 0]
				}
			}
		} else if (id === '/kitchen3') {
			if (obj.prdFiles.find(x => x.sku.includes('DOB30T977SS'))) {
				posObj = positions['DWR30U900WP']
				posObj = rotation['DWR30U900WP']
				return {
					position: [1.897, -0.14, -2.405],
					rotation: [0, 0, 0]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DOB30T977DS') || x.sku.includes('DOB30M977DS') || x.sku.includes('DOB30M977DM'))) {
				posObj = positions['DWR30U900WP']
				posObj = rotation['DWR30U900WP']
				return {
					position: [1.897, -0.722, -2.405],
					rotation: [0, 0, 0]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DOC30T977DS'))) {
				posObj = positions['DWR30U900WP']
				posObj = rotation['DWR30U900WP']
				return {
					position: [1.897, -0.51, -2.405],
					rotation: [0, 0, 0]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DOB30M977SS') || x.sku.includes('DOB30M977SM'))) {
				posObj = positions['DWR30U900WP']
				posObj = rotation['DWR30U900WP']
				return {
					position: [1.897, -0.165, -2.405],
					rotation: [0, 0, 0]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DOC30M977DS') || x.sku.includes('DOC30M977DM'))) {
				posObj = positions['DWR30U900WP']
				posObj = rotation['DWR30U900WP']
				return {
					position: [1.897, -0.54, -2.405],
					rotation: [0, 0, 0]
				}
			}
		}
	}


   


	if (sku.startsWith('DTI36P876BB')) {
		posObj = positions['DTI36P876BB']
		posObj = rotation['DTI36P876BB']
		if (!isIsland) {
			return {
				position: [-2.525, -0.1, -1.41],
				rotation: [0, 0, 0]
			}
		} else if (isIsland) {
			return {
				position: [0.745, -0.1, -1.27],
				rotation: [0, Math.PI, 0]
			}
		}

	}
	if (sku.startsWith('DTI30P876BB')) {
		posObj = positions['DTI30P876BB']
		posObj = rotation['DTI30P876BB']
		if (!isIsland) {
			return {
				position: [-2.455, -0.133, -2.42],
				rotation: [0, 0, 0]
			}
		} else if (isIsland) {
			return {
				position: [0.82, -0.14, -0.269],
				rotation: [0, Math.PI, 0]
			}
		}

	}
	if (sku.startsWith('DTG36P875NS')) {
		posObj = positions['DTG36P875NS']
		posObj = rotation['DTG36P875NS']

		if (!isIsland) {
			return {
				position: [-2.525, -0.116, -3.46],
				rotation: [0, 0, 0]
			}
		} else if (isIsland) {
			return {
				position: [0.743, -0.12, 0.77],
				rotation: [0, Math.PI, 0]
			}
		}
	}
	if (sku.startsWith('DTI36M977BB')) {
		posObj = positions['DTI36M977BB']
		posObj = rotation['DTI36M977BB']
		if (!isIsland) {
			return {
				position: [-1.52, -0.1, 0.6],
				rotation: [0, 0, 0]
			}
		} else if (isIsland) {
			return {
				position: [-0.26, -0.11, -3.28],
				rotation: [0, Math.PI, 0]
			}
		}
	}
	if (sku.startsWith('DTG36M955FS')) {
		posObj = positions['DTG36M955FS']
		posObj = rotation['DTG36M955FS']
		if (!isIsland) {
			return {
				position: [-3.57, -0.172, -1.41],
				rotation: [0, 0, 0]
			}
		} else if (isIsland) {
			return {
				position: [1.7865, -0.1825, -1.287],
				rotation: [0, Math.PI, 0]
			}
		}
	}
	if (sku.startsWith('DTG36M955FM')) {
		posObj = positions['DTG36M955FM']
		posObj = rotation['DTG36M955FM']
		if (!isIsland) {
			return {
				position: [-1.57, -0.173, -1.405],
				rotation: [0, 0, 0]
			}
		} else if (isIsland) {
			return {
				position: [-0.22, -0.1825, -1.287],
				rotation: [0, Math.PI, 0]
			}
		}

	}
	if (sku.startsWith('DTG30P875NS')) {
		posObj = positions['DTG30P875NS']
		posObj = rotation['DTG30P875NS']
		if (!isIsland) {
			return {
				position: [-2.45, -0.113, -4.46],
				rotation: [0, 0, 0]
			}
		} else if (isIsland) {
			return {
				position: [0.82, -0.125, 1.772],
				rotation: [0, Math.PI, 0]
			}
		}
	}

	if (sku.startsWith('DTI30M977BB')) {
		posObj = positions['DTI30M977BB']
		posObj = rotation['DTI30M977BB']
		if (isIsland) {
			return {
				position: [-0.18, -0.11, 1.71],
			}
		} else if (!isIsland) {
			return {
				position: [-1.45, -0.1, -4.4],
				rotation: [0, Math.PI, 0]
			}
		}
	}
	if (sku.startsWith('DTG30M954FS')) {
		posObj = positions['DTG30M954FS']
		posObj = rotation['DTG30M954FS']
		if (!isIsland) {
			return {
				position: [-3.495, -0.17, -2.41],
				rotation: [0, 0, 0]
			}
		} else if (isIsland) {
			return {
				position: [1.864, -0.182, -0.28],
				rotation: [0, Math.PI, 0]
			}
		}
	}
	if (sku.startsWith('DTG30M954FM')) {
		posObj = positions['DTG30M954FM']
		posObj = rotation['DTG30M954FM']
		if (!isIsland) {
			return {
				position: [-1.495, -0.173, -2.41],
				rotation: [0, 0, 0]
			}
		} else if (isIsland) {
			return {
				position: [-0.135, -0.182, -0.28],
				rotation: [0, Math.PI, 0]
			}
		}
	}
	if (sku.startsWith('DTT48T960GS')) {
		posObj = positions['DTT48T960GS']
		posObj = rotation['DTT48T960GS']
		if (!isIsland) {
			return {
				position: [-2.677, -0.945, 7.635],
				rotation: [0, 0, 0]
			}
		} else if (isIsland) {
			return {
				position: [0.59, -0.95, -10.32],
				rotation: [0, Math.PI, 0]
			}
		}

	}
	if (sku.startsWith('DTT48T960GM')) {
		posObj = positions['DTT48T960GM']
		posObj = rotation['DTT48T960GM']
		if (!isIsland) {
			return {
				position: [-4.677, -0.945, 7.632],
				rotation: [0, 0, 0]
			}
		} else if (isIsland) {
			return {
				position: [2.59, -0.955, -10.32],
				rotation: [0, Math.PI, 0]
			}
		}

	}
	if (sku.startsWith('DTT48T963GS')) {
		posObj = positions['DTT48T963GS']
		posObj = rotation['DTT48T963GS']
		if (!isIsland) {
			return {
				position: [-2.677, -0.94, 9.635],
				rotation: [0, 0, 0]
			}
		} else if (isIsland) {
			return {
				position: [0.59, -0.95, -12.32],
				rotation: [0, Math.PI, 0]
			}
		}

	}
	if (sku.startsWith('DTT48T963GM')) {
		posObj = positions['DTT48T963GM']
		posObj = rotation['DTT48T963GM']
		if (!isIsland) {
			return {
				position: [-4.677, -0.943, 9.635],
				rotation: [0, 0, 0]
			}
		} else if (isIsland) {
			return {
				position: [2.59, -0.955, -12.32],
				rotation: [0, Math.PI, 0]
			}
		}

	}

	if (sku.startsWith('DTT36T960GS')) {
		posObj = positions['DTT36T960GS']
		posObj = rotation['DTT36T960GS']
		if (!isIsland) {
			return {
				position: [-2.525, -0.94, 5.635],
				rotation: [0, 0, 0]
			}
		} else if (isIsland) {
			return {
				position: [0.743, -0.952, -8.32],
				rotation: [0, Math.PI, 0]
			}
		}

	}

	if (sku.startsWith('DTT36T960GM')) {
		posObj = positions['DTT36T960GM']
		posObj = rotation['DTT36T960GM']
		if (!isIsland) {
			return {
				position: [-4.524, -0.94, 5.637],
				rotation: [0, 0, 0]
			}
		} else if (isIsland) {
			return {
				position: [2.743, -0.952, -8.32],
				rotation: [0, Math.PI, 0]
			}
		}

	}

	if (sku.startsWith('DOP36T86GLS')) {
		posObj = positions['DOP36T86GLS']
		posObj = rotation['DOP36T86GLS']
		if (!isIsland) {
			return {
				position: [-2.525, -0.925, 1.618],
				rotation: [0, 0, 0]
			}
		} else if (isIsland) {
			return {
				position: [0.74, -0.936, -4.3],
				rotation: [0, Math.PI, 0]
			}
		}

	}

	if (sku.startsWith('DOP36T86DLS')) {
		posObj = positions['DOP36T86DLS']
		posObj = rotation['DOP36T86DLS']
		if (!isIsland) {
			return {
				position: [-2.525, -0.925, 1.618],
				rotation: [0, 0, 0]
			}
		} else if (isIsland) {
			return {
				position: [0.74, -0.936, -4.3],
				rotation: [0, Math.PI, 0]
			}
		}
	}

	if (sku.startsWith('DOP30T840GS')) {
		posObj = positions['DOP30T840GS']
		posObj = rotation['DOP30T840GS']
		if (!isIsland) {
			return {
				position: [-2.447, -0.91, -0.35],
				rotation: [0, 0, 0]
			}
		} else if (isIsland) {
			return {
				position: [0.8199, -0.917, -2.315],
				rotation: [0, Math.PI, 0]
			}
		}
	}

	if (sku.startsWith('DOP30T940DS')) {
		posObj = positions['DOP30T940DS']
		posObj = rotation['DOP30T940DS']
		if (!isIsland) {
			return {
				position: [-2.447, -0.91, -0.35],
				rotation: [0, 0, 0]
			}
		} else if (isIsland) {
			return {
				position: [0.8199, -0.917, -2.315],
				rotation: [0, Math.PI, 0]
			}
		}

	}
	if (sku.startsWith('DOP48C')) {
		posObj = positions['DOP48C']
		posObj = rotation['DOP48C']
		if (!isIsland) {
			return {
				position: [-1.678, -0.9315, -2.379],
				rotation: [0, 0, 0]
			}
		} else if (isIsland) {
			return {
				position: [-0.41, -0.942, -0.306],
				rotation: [0, Math.PI, 0]
			}
		}
	}

	if (sku.startsWith('DOP36M96GLS')) {
		posObj = positions['DOP36M96GLS']
		posObj = rotation['DOP36M96GLS']
		if (!isIsland) {
			return {
				position: [-3.523, -0.931, 3.595],
				rotation: [0, 0, 0]
			}
		} else if (isIsland) {
			return {
				position: [1.745, -0.94, -6.28],
				rotation: [0, Math.PI, 0]
			}
		}

	}


	if (sku.startsWith('DOP36C96GLS')) {
		posObj = positions['DOP36C96GLS']
		posObj = rotation['DOP36C96GLS']
		if (!isIsland) {
			return {
				position: [-3.523, -0.931, 3.595],
				rotation: [0, 0, 0]
			}
		} else if (isIsland) {
			return {
				position: [1.745, -0.94, -6.28],
				rotation: [0, Math.PI, 0]
			}
		}

	}

	if (sku.startsWith('DOP36M96GLM')) {
		posObj = positions['DOP36M96GLM']
		posObj = rotation['DOP36M96GLM']
		if (!isIsland) {
			return {
				position: [-1.524, -0.931, 3.595],
				rotation: [0, 0, 0]
			}
		} else if (isIsland) {
			return {
				position: [-0.25400000000000006, -0.942, -6.282],
				rotation: [0, Math.PI, 0]
			}
		}

	}
	if (sku.startsWith('DOP36C96GLM')) {
		posObj = positions['DOP36C96GLM']
		posObj = rotation['DOP36C96GLM']
		if (!isIsland) {
			return {
				position: [-1.524, -0.931, 3.595],
				rotation: [0, 0, 0]
			}
		} else if (isIsland) {
			return {
				position: [-0.25400000000000006, -0.942, -6.282],
				rotation: [0, Math.PI, 0]
			}
		}

	}



	if (sku.startsWith('DOP36C86DLS')) {
		posObj = positions['DOP36C86DLS']
		posObj = positions['DOP36C86DLS']
		if (!isIsland) {
			return {
				position: [-1.524, -0.471, -2.379],
				rotation: [0, 0, 0]
			}
		} else if (isIsland) {
			return {
				position: [-0.257, -0.481, -0.305],
				rotation: [0, Math.PI, 0]
			}
		}

	}

	if (sku.startsWith('DOP36C86DLM')) {
		posObj = positions['DOP36C86DLM']
		posObj = rotation['DOP36C86DLM']
		if (!isIsland) {
			return {
				position: [-1.524, -0.471, -2.379],
				rotation: [0, 0, 0]
			}
		} else if (isIsland) {
			return {
				position: [-0.257, -0.481, -0.305],
				rotation: [0, Math.PI, 0]
			}
		}

	}
    if(id==='/kitchen2'){
        if(sku.startsWith('DRW24980')){
            if(obj.file === 'DRW24980-k2-rw'){  
                posObj = positions['DRW24980']
                return{
                    position:[2.6865, -0.967, 15.559]
                }
            }
        }
    }
    
	if (sku.startsWith('DWR30U900WS')) {
		posObj = positions['DWR30U900WS']
		if (obj.prdFiles.find(x => x.sku.includes('DOB30T977S'))) {
			return {
				position: [1.898, -0.14, -2.42]
			}
		} else if (obj.prdFiles.find(x => x.sku.includes('DOB30M977S'))) {
			return {
				position: [1.898, -0.163, -2.42]
			}
		}
		else if (obj.prdFiles.find(x => x.sku.includes('DOC30T977D'))) {
			return {
				position: [1.898, -0.51, -2.42]
			}
		} else if (obj.prdFiles.find(x => x.sku.includes('DOC30M977D'))) {
			return {
				position: [1.898, -0.542, -2.42]
			}
		}
	} else if (sku.startsWith('DWR30U900WM')) {
		posObj = positions['DWR30U900WM']
		if (obj.prdFiles.find(x => x.sku.includes('DOB30T977S')) || obj.prdFiles.find(x => x.sku.includes('DOB30M977S'))) {
			return {
				position: [-0.102, -0.141, -2.41]
			}
		}
		// else if(obj.prdFiles.find(x=>x.sku.includes('DOC30T977D')) || obj.prdFiles.find(x=>x.sku.includes('DOC30M977D')) || obj.prdFiles.find(x=>x.sku.includes('DOB30T977D')) || obj.prdFiles.find(x=>x.sku.includes('DOB30M977D'))){
		//     return{
		//         position:[-0.103, -0.724, -2.41]
		//     }
		// }
	} else if (sku.startsWith('DWR30U900WP')) {
		posObj = positions['DWR30U900WP']
		if (obj.prdFiles.find(x => x.sku.includes('DOB30M977S'))) {
			return {
				position: [3.899, -0.16099999999999998, -2.42]

			}
		} else if (obj.prdFiles.find(x => x.sku.includes('DOB30T977S'))) {
			return {
				position: [3.899, -0.14, -2.42]
			}
		}
		else if (obj.prdFiles.find(x => x.sku.includes('DOC30M977D'))) {
			return {
				position: [3.899, -0.54, -2.42]
			}
		} else if (obj.prdFiles.find(x => x.sku.includes('DOC30T977D'))) {
			return {
				position: [3.899, -0.51, -2.42]
			}
		}
	}

	if (!isIsland) {
		if (sku.startsWith('DHD30U990WS')) {
			posObj = positions['DHD30U990WS']
			if (obj.prdFiles.find(x => x.sku.includes('DTI36')) || obj.prdFiles.find(x => x.sku.includes('DTG36')) || obj.prdFiles.find(x => x.sku.includes('DTT36')) || obj.prdFiles.find(x => x.sku.includes('DOP36'))) {
				return {
					position: [-0.53, 0.682, 3.56]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DTI30')) || obj.prdFiles.find(x => x.sku.includes('DTG30')) || obj.prdFiles.find(x => x.sku.includes('DOP30T'))) {
				return {
					position: [-0.47, 0.682, 3.56]
				}
			}
		} else if (sku.startsWith('DHD30U990WM')) {
			posObj = positions['DHD30U990WM']
			if (obj.prdFiles.find(x => x.sku.includes('DTI36')) || obj.prdFiles.find(x => x.sku.includes('DTG36')) || obj.prdFiles.find(x => x.sku.includes('DTT36')) || obj.prdFiles.find(x => x.sku.includes('DOP36'))) {
				return {
					position: [-0.53, 0.682, 9.56]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DTI30')) || obj.prdFiles.find(x => x.sku.includes('DTG30')) || obj.prdFiles.find(x => x.sku.includes('DOP30T'))) {
				return {
					position: [-0.47, 0.682, 9.56]
				}
			}
		} else if (sku.startsWith('DHD36U990WS')) {
			posObj = positions['DHD36U990WS']
			if (obj.prdFiles.find(x => x.sku.includes('DTI36')) || obj.prdFiles.find(x => x.sku.includes('DTG36')) || obj.prdFiles.find(x => x.sku.includes('DTT36')) || obj.prdFiles.find(x => x.sku.includes('DOP36'))) {
				return {
					position: [-2.55, 0.682, 3.56]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DTI30')) || obj.prdFiles.find(x => x.sku.includes('DTG30')) || obj.prdFiles.find(x => x.sku.includes('DOP30T'))) {
				return {
					position: [-2.49, 0.682, 3.56]
				}
			}
		} else if (sku.startsWith('DHD36U990WM')) {
			posObj = positions['DHD36U990WM']
			if (obj.prdFiles.find(x => x.sku.includes('DTI36')) || obj.prdFiles.find(x => x.sku.includes('DTG36')) || obj.prdFiles.find(x => x.sku.includes('DTT36')) || obj.prdFiles.find(x => x.sku.includes('DOP36'))) {
				return {
					position: [-2.55, 0.682, 9.57109]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DTI30')) || obj.prdFiles.find(x => x.sku.includes('DTG30')) || obj.prdFiles.find(x => x.sku.includes('DOP30T'))) {
				return {
					position: [-2.49, 0.682, 9.57109]
				}
			}
		} else if (sku.startsWith('DHD48U990WS')) {
			posObj = positions['DHD48U990WS']
			if (obj.prdFiles.find(x => x.sku.includes('DTI36')) || obj.prdFiles.find(x => x.sku.includes('DTG36')) || obj.prdFiles.find(x => x.sku.includes('DTT36')) || obj.prdFiles.find(x => x.sku.includes('DOP36'))) {
				return {
					position: [-4.56, 0.682, 3.56]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DTI30')) || obj.prdFiles.find(x => x.sku.includes('DTG30')) || obj.prdFiles.find(x => x.sku.includes('DOP30T'))) {
				return {
					position: [-4.49, 0.682, 3.56]
				}
			}
		} else if (sku.startsWith('DHD48U990WM')) {
			posObj = positions['DHD48U990WM']
			if (obj.prdFiles.find(x => x.sku.includes('DTI36')) || obj.prdFiles.find(x => x.sku.includes('DTG36')) || obj.prdFiles.find(x => x.sku.includes('DTT36')) || obj.prdFiles.find(x => x.sku.includes('DOP36'))) {
				return {
					position: [-4.56, 0.682, 9.565]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DTI30')) || obj.prdFiles.find(x => x.sku.includes('DTG30')) || obj.prdFiles.find(x => x.sku.includes('DOP30T'))) {
				return {
					position: [-4.49, 0.682, 9.565]
				}
			}
		} else if (sku.startsWith('DHD30U990CS')) {
			posObj = positions['DHD30U990CS']
			if (obj.prdFiles.find(x => x.sku.includes('DTI30')) || obj.prdFiles.find(x => x.sku.includes('DTG30')) || obj.prdFiles.find(x => x.sku.includes('DOP30'))) {
				return {
					position: [-0.45, 0.827, 1.775]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DTI36')) || obj.prdFiles.find(x => x.sku.includes('DTG36')) || obj.prdFiles.find(x => x.sku.includes('DOP36')) || obj.prdFiles.find(x => x.sku.includes('DTT36'))) {
				return {
					position: [-0.523, 0.827, 1.775]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DOP48')) || obj.prdFiles.find(x => x.sku.includes('DTT48'))) {
				return {
					position: [-0.677, 0.827, 1.775]
				}
			}
		} else if (sku.startsWith('DHD30U990CM')) {
			posObj = positions['DHD30U990CM']
			if (obj.prdFiles.find(x => x.sku.includes('DTI30')) || obj.prdFiles.find(x => x.sku.includes('DTG30')) || obj.prdFiles.find(x => x.sku.includes('DOP30'))) {
				return {
					position: [-0.45, 0.827, 7.76]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DTI36')) || obj.prdFiles.find(x => x.sku.includes('DTG36')) || obj.prdFiles.find(x => x.sku.includes('DOP36')) || obj.prdFiles.find(x => x.sku.includes('DTT36'))) {
				return {
					position: [-0.523, 0.827, 7.76]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DOP48')) || obj.prdFiles.find(x => x.sku.includes('DTT48'))) {
				return {
					position: [-0.677, 0.827, 7.76]
				}
			}
		} else if (sku.startsWith('DHD36U990CM')) {
			posObj = positions['DHD36U990CM']
			if (obj.prdFiles.find(x => x.sku.includes('DTI30')) || obj.prdFiles.find(x => x.sku.includes('DTG30')) || obj.prdFiles.find(x => x.sku.includes('DOP30'))) {
				return {
					position: [-2.45, 0.827, 7.76]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DTI36')) || obj.prdFiles.find(x => x.sku.includes('DTG36')) || obj.prdFiles.find(x => x.sku.includes('DOP36')) || obj.prdFiles.find(x => x.sku.includes('DTT36'))) {
				return {
					position: [-2.525, 0.827, 7.76]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DOP48')) || obj.prdFiles.find(x => x.sku.includes('DTT48'))) {
				return {
					position: [-2.677, 0.827, 7.76]
				}
			}
		} else if (sku.startsWith('DHD36U990CS')) {
			posObj = positions['DHD36U990CS']
			if (obj.prdFiles.find(x => x.sku.includes('DTI30')) || obj.prdFiles.find(x => x.sku.includes('DTG30')) || obj.prdFiles.find(x => x.sku.includes('DOP30'))) {
				return {
					position: [-2.45, 0.827, 1.775]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DTI36')) || obj.prdFiles.find(x => x.sku.includes('DTG36')) || obj.prdFiles.find(x => x.sku.includes('DOP36')) || obj.prdFiles.find(x => x.sku.includes('DTT36'))) {
				return {
					position: [-2.525, 0.827, 1.775]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DOP48')) || obj.prdFiles.find(x => x.sku.includes('DTT48'))) {
				return {
					position: [-2.677, 0.827, 1.775]
				}
			}
		} else if (sku.startsWith('DHD48U990CS')) {
			posObj = positions['DHD48U990CS']
			if (obj.prdFiles.find(x => x.sku.includes('DTI30')) || obj.prdFiles.find(x => x.sku.includes('DTG30')) || obj.prdFiles.find(x => x.sku.includes('DOP30'))) {
				return {
					position: [-4.448, 0.827, 1.775]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DTI36')) || obj.prdFiles.find(x => x.sku.includes('DTG36')) || obj.prdFiles.find(x => x.sku.includes('DOP36')) || obj.prdFiles.find(x => x.sku.includes('DTT36'))) {
				return {
					position: [-4.525, 0.827, 1.775]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DOP48')) || obj.prdFiles.find(x => x.sku.includes('DTT48'))) {
				return {
					position: [-4.675, 0.827, 1.775]
				}
			}
		} else if (sku.startsWith('DHD48U990CM')) {
			posObj = positions['DHD48U990CM']
			if (obj.prdFiles.find(x => x.sku.includes('DTI30')) || obj.prdFiles.find(x => x.sku.includes('DTG30')) || obj.prdFiles.find(x => x.sku.includes('DOP30'))) {
				return {
					position: [-4.448, 0.825, 7.77]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DTI36')) || obj.prdFiles.find(x => x.sku.includes('DTG36')) || obj.prdFiles.find(x => x.sku.includes('DOP36')) || obj.prdFiles.find(x => x.sku.includes('DTT36'))) {
				return {
					position: [-4.525, 0.825, 7.77]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DOP48')) || obj.prdFiles.find(x => x.sku.includes('DTT48'))) {
				return {
					position: [-4.675, 0.825, 7.77]
				}
			}
		} else if (sku.startsWith('PCOR30S')) {
			posObj = positions['PCOR30S']
			if (obj.prdFiles.find(x => x.sku.includes('DTI30')) || obj.prdFiles.find(x => x.sku.includes('DTG30')) || obj.prdFiles.find(x => x.sku.includes('DOP30'))) {
				return {
					position: [-1.452, 0.69, 7.57]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DTI36')) || obj.prdFiles.find(x => x.sku.includes('DTG36')) || obj.prdFiles.find(x => x.sku.includes('DOP36')) || obj.prdFiles.find(x => x.sku.includes('DTT36'))) {
				return {
					position: [-1.525, 0.69, 7.57]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DOP48')) || obj.prdFiles.find(x => x.sku.includes('DTT48'))) {
				return {
					position: [-1.6775, 0.69, 7.57]
				}
			}
		} else if (sku.startsWith('DHD30U790LS')) {
			posObj = positions['DHD30U790LS']
			if (obj.prdFiles.find(x => x.sku.includes('DTI30')) || obj.prdFiles.find(x => x.sku.includes('DTG30')) || obj.prdFiles.find(x => x.sku.includes('DOP30'))) {
				return {
					position: [-0.45, 0.704, -0.58]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DTI36')) || obj.prdFiles.find(x => x.sku.includes('DTG36')) || obj.prdFiles.find(x => x.sku.includes('DOP36')) || obj.prdFiles.find(x => x.sku.includes('DTT36'))) {
				return {
					position: [-0.525, 0.704, -0.58]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DOP48')) || obj.prdFiles.find(x => x.sku.includes('DTT48'))) {
				return {
					position: [-0.677, 0.704, -0.58]
				}
			}
		} else if (sku.startsWith('DHD36U790LS')) {
			posObj = positions['DHD36U790LS']
			if (obj.prdFiles.find(x => x.sku.includes('DTI30')) || obj.prdFiles.find(x => x.sku.includes('DTG30')) || obj.prdFiles.find(x => x.sku.includes('DOP30'))) {
				return {
					position: [-2.45, 0.704, -0.58]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DTI36')) || obj.prdFiles.find(x => x.sku.includes('DTG36')) || obj.prdFiles.find(x => x.sku.includes('DOP36')) || obj.prdFiles.find(x => x.sku.includes('DTT36'))) {
				return {
					position: [-2.525, 0.704, -0.58]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DOP48')) || obj.prdFiles.find(x => x.sku.includes('DTT48'))) {
				return {
					position: [-2.675, 0.704, -0.58]
				}
			}
		} else if (sku.startsWith('DHD48U790LS')) {
			posObj = positions['DHD48U790LS']
			if (obj.prdFiles.find(x => x.sku.includes('DTI30')) || obj.prdFiles.find(x => x.sku.includes('DTG30')) || obj.prdFiles.find(x => x.sku.includes('DOP30'))) {
				return {
					position: [-4.45, 0.707, -0.55]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DTI36')) || obj.prdFiles.find(x => x.sku.includes('DTG36')) || obj.prdFiles.find(x => x.sku.includes('DOP36')) || obj.prdFiles.find(x => x.sku.includes('DTT36'))) {
				return {
					position: [-4.525, 0.707, -0.55]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DOP48')) || obj.prdFiles.find(x => x.sku.includes('DTT48'))) {
				return {
					position: [-4.675, 0.707, -0.55]
				}
			}
		}
	}
	if (obj.wallOvenCookTop) {
		if (!isIsland) {
			if (sku.startsWith('DOB30T977SS')) {
				posObj = positions['DOB30T977SS']
				if (prdFiles.find(x => x.sku.includes('DTI30')) || prdFiles.find(x => x.sku.includes('DTG30'))) {
					return {
						position: [-2.446, -0.855, 13.608]
					}
				} else if (prdFiles.find(x => x.sku.includes('DTI36')) || prdFiles.find(x => x.sku.includes('DTG36'))) {
					return {
						position: [-2.521, -0.855, 13.608]
					}
				}
			} else if (sku.startsWith('DOB30M977SS')) {
				posObj = positions['DOB30M977SS']
				if (prdFiles.find(x => x.sku.includes('DTI30')) || prdFiles.find(x => x.sku.includes('DTG30'))) {
					return {
						position: [-3.45, -0.827, 11.63]
					}
				} else if (prdFiles.find(x => x.sku.includes('DTI36')) || prdFiles.find(x => x.sku.includes('DTG36'))) {
					return {
						position: [-3.525, -0.827, 11.63]
					}
				}
			} else if (sku.startsWith('DOB30M977SM')) {
				posObj = positions['DOB30M977SM']
				if (prdFiles.find(x => x.sku.startsWith('DTI30')) || prdFiles.find(x => x.sku.startsWith('DTG30'))) {
					return {
						position: [-1.446, -0.827, 11.628]
					}
				} else if (prdFiles.find(x => x.sku.startsWith('DTI36')) || prdFiles.find(x => x.sku.startsWith('DTG36'))) {
					return {
						position: [-1.522, -0.827, 11.628]
					}
				}
			}
		} else if (isIsland) {
			if (sku.startsWith('DOB30T977SS')) {
				posObj = positions['DOB30T977SS']
				rotation = rotation['DOB30T977SS']
				if (prdFiles.find(x => x.sku.includes('DTI30')) || prdFiles.find(x => x.sku.includes('DTG30'))) {
					return {
						position: [0.822, -0.846, -16.292],
						rotation: [0, Math.PI, 0]
					}
				} else if (prdFiles.find(x => x.sku.includes('DTI36')) || prdFiles.find(x => x.sku.includes('DTG36'))) {
					return {
						position: [0.745, -0.846, -16.292],
						rotation: [0, Math.PI, 0]
					}
				}
			} else if (sku.startsWith('DOB30M977SS')) {
				posObj = positions['DOB30M977SS']
				rotation = rotation['DOB30T977SS']

				if (prdFiles.find(x => x.sku.includes('DTI30')) || prdFiles.find(x => x.sku.includes('DTG30'))) {
					return {
						position: [1.824, -0.828, -14.314],
						rotation: [0, Math.PI, 0]
					}
				} else if (prdFiles.find(x => x.sku.includes('DTI36')) || prdFiles.find(x => x.sku.includes('DTG36'))) {
					return {
						position: [1.749, -0.828, -14.314],
						rotation: [0, Math.PI, 0]
					}
				}
			} else if (sku.startsWith('DOB30M977SM')) {
				posObj = positions['DOB30M977SM']
				rotation = rotation['DOB30T977SM']

				if (prdFiles.find(x => x.sku.startsWith('DTI30')) || prdFiles.find(x => x.sku.startsWith('DTG30'))) {
					return {
						position: [-0.179, -0.828, -14.314],
						rotation: [0, Math.PI, 0]
					}
				} else if (prdFiles.find(x => x.sku.startsWith('DTI36')) || prdFiles.find(x => x.sku.startsWith('DTG36'))) {
					return {
						position: [-0.255, -0.828, -14.314],
						rotation: [0, Math.PI, 0]
					}
				}
			}
		}
	}

	if (isIsland) {
		//36 island hood finished
		if (sku.startsWith("DHD36U990IS")) {
			posObj = positions['DHD36U990IS']
			if (prdFiles.find(x => x.sku.includes('DTI30')) || prdFiles.find(x => x.sku.includes('DTG30')) || prdFiles.find(x => x.sku.includes('DOP30'))) {
				return {
					position: [0.82, 0.7, 7.77]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DOP48')) || obj.prdFiles.find(x => x.sku.includes('DTT48'))) {
				return {
					position: [0.59, 0.7, 7.77]
				}
			}

		} else if (sku.startsWith("DHD36U990IM")) {
			posObj = positions['DHD36U990IM']
			if (prdFiles.find(x => x.sku.includes('DTI30')) || prdFiles.find(x => x.sku.includes('DTG30')) || prdFiles.find(x => x.sku.includes('DOP30'))) {
				return {
					position: [0.82, 0.701, 13.774]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DOP48')) || obj.prdFiles.find(x => x.sku.includes('DTT48'))) {
				return {
					position: [0.59, 0.701, 13.774]
				}
			}
		}
		else if (sku.startsWith("DHD42U990IS")) {
			posObj = positions['DHD42U990IS']
			if (prdFiles.find(x => x.sku.includes('DTI30')) || prdFiles.find(x => x.sku.includes('DTG30')) || prdFiles.find(x => x.sku.includes('DOP30'))) {
				return {
					position: [-1.18, 0.7, 7.774]
				}
			} else if (prdFiles.find(x => x.sku.includes('DTI36')) || prdFiles.find(x => x.sku.includes('DTG36')) || prdFiles.find(x => x.sku.includes('DOP36')) || prdFiles.find(x => x.sku.includes('DTT36'))) {
				return {
					position: [-1.26, 0.7, 7.774]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DOP48')) || obj.prdFiles.find(x => x.sku.includes('DTT48'))) {
				return {
					position: [-1.41, 0.7, 7.774]
				}
			}

		} else if (sku.startsWith("DHD42U990IM")) {
			posObj = positions['DHD42U990IM']
			if (prdFiles.find(x => x.sku.includes('DTI30')) || prdFiles.find(x => x.sku.includes('DTG30')) || prdFiles.find(x => x.sku.includes('DOP30'))) {
				return {
					position: [-1.18, 0.7, 13.774]
				}
			} else if (prdFiles.find(x => x.sku.includes('DTI36')) || prdFiles.find(x => x.sku.includes('DTG36')) || prdFiles.find(x => x.sku.includes('DOP36')) || prdFiles.find(x => x.sku.includes('DTT36'))) {
				return {
					position: [-1.26, 0.7, 13.774]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DOP48')) || obj.prdFiles.find(x => x.sku.includes('DTT48'))) {
				return {
					position: [-1.41, 0.7, 13.774]
				}
			}
		}
		else if (sku.startsWith("DHD54U990IS")) {
			posObj = positions['DHD54U990IS']
			if (prdFiles.find(x => x.sku.includes('DTI30')) || prdFiles.find(x => x.sku.includes('DTG30')) || prdFiles.find(x => x.sku.includes('DOP30'))) {
				return {
					position: [-3.18, 0.7, 7.77]
				}
			} else if (prdFiles.find(x => x.sku.includes('DTI36')) || prdFiles.find(x => x.sku.includes('DTG36')) || prdFiles.find(x => x.sku.includes('DOP36')) || prdFiles.find(x => x.sku.includes('DTT36'))) {
				return {
					position: [-3.26, 0.7, 7.77]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DOP48')) || obj.prdFiles.find(x => x.sku.includes('DTT48'))) {
				return {
					position: [-3.408, 0.7, 7.77]
				}
			}
		} else if (sku.startsWith("DHD54U990IM")) {
			posObj = positions['DHD54U990IM']
			if (prdFiles.find(x => x.sku.includes('DTI30')) || prdFiles.find(x => x.sku.includes('DTG30')) || prdFiles.find(x => x.sku.includes('DOP30'))) {
				return {
					position: [-3.18, 0.7, 13.774]
				}
			} else if (prdFiles.find(x => x.sku.includes('DTI36')) || prdFiles.find(x => x.sku.includes('DTG36')) || prdFiles.find(x => x.sku.includes('DOP36')) || prdFiles.find(x => x.sku.includes('DTT36'))) {
				return {
					position: [-3.26, 0.7, 13.774]
				}
			} else if (obj.prdFiles.find(x => x.sku.includes('DOP48')) || obj.prdFiles.find(x => x.sku.includes('DTT48'))) {
				return {
					position: [-3.408, 0.7, 13.774]
				}
			}
		}
	}


	if (!posObj) {
		return { position, rotation };;
	} else if (sku.startsWith("DWR30")) {
		// warmer config
		let wallOvenSKU = allSkus.find(
			(sku) => sku.startsWith("DOB") || sku.startsWith("DOC")
		);
		if (wallOvenSKU) {
			if (wallOvenSKU.startsWith("DOB")) {
				if (wallOvenSKU[wallOvenSKU.length - 2] === "D") {
					position = posObj.positionDoubleOven;
				} else {
					position = posObj.positionSingleOven;
				}
			} else {
				position = posObj.positionCombiOven;
			}
		} else {
			position = posObj.position || [0, 0, 0];
		}
	} else if (type === "kitchen") {
		position = posObj.positionInKitchenSink;
		rotation = posObj.rotationInKitchenSink || [0, 0, 0];

	} else if (type === "island") {
		position = posObj.positionInIsland;
		rotation = posObj.rotationInIsland || [0, 180, 0];
	} else if (posObj) {
		position = posObj.position || [0, 0, 0];
		rotation = posObj.rotation || [0, 0, 0];
	}

	if (posObj && !posObj.manual && position) {
		position[0] = position[0] + offset[0];
		position[1] = position[1] + offset[1];
		position[2] = position[2] + offset[2];
	}

	let rotationLookup = {
		180: Math.PI,
		90: Math.PI / 2,
		270: Math.PI * 1.5,
	};

	if (rotation && rotation.length)
		rotation = rotation.map((r) => (r == 0 ? r : rotationLookup[r] || r));

	return {
		position,
		rotation,
		scale,
	};
};


window.getPosition = getPosition;

window.checkAll = () => {
	Object.keys(products).filter((sku) => {
		let { position, rotation } = getPosition(sku);
		if (!position || !position[2]) return true;
		return false;
	});
}

export const getGeometries = (sku, obj = {}) => {
	let { type = "", prdFiles = [], isIsland = false } = obj;
	let id = window.location.pathname
	let allSkus = prdFiles.map((x) => x.sku);
	let posObj = positions[sku];
	let file = sku;

	if (!posObj) {
		file = file.slice(0, -1);
		posObj = positions[file];
	}
	if (!posObj) {
		file = file.slice(0, -1);
		posObj = positions[file];
	}
	// get first 5 letters
	if (!posObj) {
		file = file.slice(0, 5);
		posObj = positions[file];
	}

	let geometryToHide = [], geometryToDisplay = [];

	if (!posObj) return { geometryToHide, geometryToDisplay };

	posObj = JSON.parse(JSON.stringify(posObj));


	if (sku.startsWith("DWR30")) {
		// warmer config
		let wallOvenSKU = allSkus.find(
			(sku) => sku.startsWith("DOB") || sku.startsWith("DOC")
		);
		if (wallOvenSKU) {
			if (wallOvenSKU.startsWith("DOB")) {
				if (wallOvenSKU[wallOvenSKU.length - 2] === "D") {
					geometryToHide = posObj.geometryToHideDoubleOven;
					geometryToDisplay = posObj.geometryToDisplayDoubleOven;
				} else {
					geometryToHide = posObj.geometryToHideSingleOven;
					geometryToDisplay = posObj.geometryToDisplaySingleOven;
				}
			} else {
				geometryToHide = posObj.geometryToHideCombiOven;
				geometryToDisplay = posObj.geometryToDisplayCombiOven;
			}
		}
	} else if (type) {
		if (type === "island") {
			geometryToHide = posObj.geometryToHideIsland;
			geometryToDisplay = posObj.geometryToDisplayIsland;
			if (id != '/kitchen1X') {
				geometryToDisplay = posObj.geometriesToShowIsland2And3;
			}
		} else if (type === "kitchen") {
			geometryToHide = posObj.geometryToHideKitchen;
			geometryToDisplay = posObj.geometryToDisplayKitchen;
			if (id != '/kitchen1X') {
				geometryToDisplay = posObj.geometriesToShowKitchen2And3;
			}
		}
	} else {
		if (id === '/kitchen1X') {
			geometryToHide = posObj.geometryToHide || [];
			geometryToDisplay = posObj.geometryToDisplay || [];
		} else if (id != '/kitchen1X') {
			geometryToDisplay = posObj.geometriesToShow2And3 || [];
		}
	}
	return { hide: geometryToHide, display: geometryToDisplay };
};

export const geometryTransform = {
	//Dishwasher initial
	"DDW24M999U - DDW24T999BB": {
		hide: [
			"White_Panel_29inch_Depth_Dishwasher_24W_RIGHT_xxm",
			"NA_Dishwasher_24W_xxm",
		],
	},
	//Undercounter Wine Cellar
	'HWC242R': {
		hide: ["NA_Wine_Cellar_235W_Panel_xxm"],
	},
	//Microwave initial
	"DMR30M977WM - DMR30M977WS": {
		hide: [
			"L3_BOTTOM_for_24inch_Microwave_Center_xxm",
			"L3_TOP_Microwave_24_Center_xxm",
			"L4_TOP001_xxm",
		],
	},
	//Wine Dispenser initial
	"DWS4000BBM - DWS4000BBS": {
		hide: ["WRight_-__WinePanel_24inch_xxm"],
	},
	//Wall Oven-double initial
	"DOB30M977DM - DOB30M977DS": {
		hide: ["NA_52inchH_-_Wall_Oven_Double_Right_Wall_Panel_xxm"],
	},
	//Warming drawers initial
	"DWR30U990WM - DWR30U990WS": {
		hide: [
			"Warmer_H10_x30W_x_1D_Panel002_xxm",
			"NA_Panel_Bottom_Left_Small001_xxm",
			"R_Panel_Bottom_Left_Small_123H_xxm",
		],
	},
	//Island Hood initial
	DHD54U990IS: {
		hide: [
			"BULKHEAD_-_54inch_Ceiling_for_DOP48T960G_",
			"BULKHEAD_T-SHAPED_-_54inch_Ceiling_165W_Fixed002",
			"BULKHEAD_-_54inch_for_48inch_for_DOP48M86D",
			"BULKHEAD_T-SHAPED_-_54inch_Ceiling_165W_Fixed001",
		],
	},
	//cooling initial
	"DRR30980LAPS - DRR30980LAPM": {
		hide: [],
	},
	//range tops initial
	"DTT48T960GS - DTT48T960GM": {
		hide: ["Island_Counter_for_DOP48T960G_"],
	},
};
