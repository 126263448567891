import { atom } from 'jotai';

const themeAtom = atom(null);  // e.g., 'Modern'
const sceneLoadingAtom = atom(true);
const DeclinePerformanceAtom = atom(false);
const showingSummaryAtom = atom(false);
const fridgeTypeAtom = atom(null);  // e.g., 'Column'
const cookingTypeAtom = atom(null);  // e.g., 'Built-in'
const viewAtom = atom('');

const selectedApplianceAtom = atom(null);
const islandViewAtom = atom(false);
const addedAtom = atom([]);
const lookAtAtom = atom([0, 0, 0]);
const floorY = -0.9675615999698636
const hoverAtom = atom('');
const shownFridgeAtom = atom('');
const chosenFridgeAtom = atom('');


const isDev = location.search.includes('devmode')
const moviemode = location.search.includes('moviemode')


const uiStateAtom = atom({
})

const appliances = [
	{
		label: 'Fridge',
		value: 'fridge'
	},
	{
		label: 'Cooktop',
		value: 'stove'
	},
	{
		label: 'Hood',
		value: 'hood'
	},
	{
		label: 'Wall Oven',
		value: 'walloven'
	},
	{
		label: 'Wine',
		value: 'wine'
	},
	{
		label: 'Dishwasher',
		value: 'dishwasher'
	},

]
const kitchenLoadedAtom = atom(false);


const defaults = {
	theme1: {
		kitchen: 1,
		fridge: {
			type: 'column',

		},
		cookingType: 'wallOven',
		view: 'top',
	},
}


const kitchen1 = {
	products: {
		fridge: {
			type: 'column',
			position: [0, 0, 0],
			models: [],
		}
	},
	leftTopCabinet: {
	},
	leftBottomCabinet: {
	},
	hoodCabinets: {
		visible: false,
	},
	counterCabinets: {
	},
	rightCabinet: {

	},
	islandCabinet: {

	},
}

const isEffect = location.search.includes('effects')

export {
	floorY,
	isDev,
    moviemode,
	isEffect,
	showingSummaryAtom,
	kitchenLoadedAtom,
	appliances,
	addedAtom,
	themeAtom,
	fridgeTypeAtom,
	cookingTypeAtom,
	viewAtom,
	selectedApplianceAtom,
	uiStateAtom,
	sceneLoadingAtom,
	lookAtAtom,
	shownFridgeAtom,
	hoverAtom,
	chosenFridgeAtom,
	islandViewAtom,
	DeclinePerformanceAtom
};