import { useThree } from "@react-three/fiber";
import { useMemo } from "react";
import productList from '../assets/products.json';
import jasearch from 'jasearch'
import axios from 'axios'

export const konsole = new Proxy({}, {
	get: function (target, prop) {
		if (typeof console[prop] === 'function') {
			return function (...args) {
				if (localStorage.getItem('dev')) {
					console[prop].apply(console, args);
				}
			};
		}
		return console[prop];
	}
});


export const cameraRefs = {
	fridge: {

		position: [3.47, 0.588, -0.2903],
		lookAt: [1, 0.4, -1],

		frenchSmall: {
			position: [3.47, 0.588, -0.2903],
			lookAt: [1, 0.4, -1]
		},
		frenchBig: {
			position: [3.47, 0.588, -0.2903],
			lookAt: [1, 0.4, -1]
		},
		c1: {
			position: [3.47, 0.588, -0.2903],
			lookAt: [-2, 0.4, -1]
		},
		c2: {
			position: [3.47, 0.588, -0.2903],
			lookAt: [-2, 0.4, -1]
		},
		c3: {
			position: [1.47, 0.588, 3.2903],
			lookAt: [-3, 0.4, -1]
		},
	},
	cookAll: {
		position: [3.741351751255759, 2.0124455193678576, 2.335542760358085],
		lookAt: [0.2, 0.10000000000000003, -1.9000000000000001]
	},
	cook: {
		position: [
			0.5413517512557589,
			1.0124455193678576,
			-0.664457239641915
		],
		lookAt: [0.2, 0.8, -1]
	},
	islandCook: {
		lookAt: [-0.6999999999999997, -0.5, 0],
		position: [
			0.7413517512557588,
			1.0124455193678576,
			-2.164457239641915
		]
	},
	range: {
		position: [0.7413517512557588, 1.0124455193678576, -0.66445723964191502],
		lookAt: [0.2, 0.8, -1]
	},
	microwave: {
		position: [2.433721759484326, 0.8771712546711872, 1.3878061372212467],
		lookAt: [1.8, 0.4, -1]
	},
	walloven: {
		position: [0.433721759484326, 0.47717125467118726, 1.3878061372212467],
		lookAt: [1.8, 0.4, -1]
	},
	warmer: {
		position: [1.9337217594843261, 1.0771712546711874, 1.3878061372212467],
		lookAt: [2.3000000000000003, 0.4, -1]
	},
	fullWallOven: {
		position: [0.433721759484326, 0.47717125467118726, 1.3878061372212467],
		lookAt: [1.8, 0.4, -1]
	},
	hood: {
		position: [-0.8606862932761058, 2.1158008403671094, 0.5640343356961715],
		lookAt: [-1.45, 1.7, -1]
	},
	wallHood: {
		position: [1.2393137067238942, 0.31580084036710954, 2.7640343356961714],
		lookAt: [-0.24999999999999997, 1.1, -1]
	},
	cabinetHood: {
		position: [-0.8606862932761058, 2.1158008403671094, 0.5640343356961715],
		lookAt: [-1.45, 1.7, -1]
	},
	islandHood: {
		position: [-0.8606862932761058, 2.1158008403671094, 0.5640343356961715],
		lookAt: [-1.45, 1.7, -1]
	},
	rightWall: {
		position: [2.035905404272716, 0.659883131407369, 3.661091970432797],
		lookAt: [3.1, 0.4, -1]
	},
	islandCooking: {
		position: [2.366083501487005, 0.026967660326029153, -1.90000042012004],
		lookAt: [-1.8, -0.6, 1]
	},
	dishwasher: {
		position: [2.366083501487005, 0.026967660326029153, -1.90000042012004],
		lookAt: [-1.8, -0.6, 1]
	},
	islandView: {
		position: [2.366083501487005, 1, -2],
		lookAt: [-2, -1.6, 1]
	},
	counter: {
		position: [-2.633916498512995, 0.026967660326029153, -2.0000004201200396],
		lookAt: [-0.72, -0.8600000000000001, 1]
	},
}

window.cameraRefs = cameraRefs

const email_server = sessionStorage.dev ? 'http://localhost:7500/send-dacor' : 'https://samsungretailexperience.com/send-dacor'

export const sendSummary = (data) => {
	return axios.post(email_server, data)
}

export const doPanel = (groupRef, nodes, { names, is, }) => {
	let uuids = []
	names.forEach(name => {
		Object.keys(nodes).filter(nd => {
			return nd.toLowerCase() === name.toLowerCase()
		}).forEach(nd => {
			uuids.push(nodes[nd]?.geometry?.uuid)
		})
	})

	konsole.log('uuids found', uuids.length)

	if (!groupRef) return;
	let i = 0
	groupRef.traverse((object) => {
		if (object.isMesh) {
			if (uuids.some(uid => (object.geometry.uuid === uid))) {
				i++
				object.visible = is;
			}
		} else {
			if (object.children) {
				object.children.forEach(child => {
					if (child.isMesh) {
						if (uuids.some(uid => (child.geometry.uuid === uid))) {
							i++
							child.visible = is;
						}
					}
				})
			}
		}
	})
	konsole.log(`object's visible set to ${is}: ${i}`)
};
export const isPanel = (groupRef, nodes, { query, is = true }) => {
	let uuids = {}
	let foundPanels = jasearch(nodes, { name: query })
	foundPanels.forEach(x => {
		if (x.geometry) {
			uuids[x.geometry.uuid] = x.name
		} else {
			if (x.children) {
				x.children.forEach(y => {
					if (y.geometry) {
						uuids[y.geometry.uuid] = y.name
					}
				})
			}
		}

	})
	let finalObj = {}
	groupRef.traverse((object) => {
		if (object.isMesh && object.geometry.uuid in uuids) {
			finalObj[uuids[object.geometry.uuid]] = object.visible
		}
	})
	return finalObj
};

export const useForwardRaycast = (obj) => {
	const raycaster = useMemo(() => new Raycaster(), []);
	const pos = useMemo(() => new Vector3(), []);
	const dir = useMemo(() => new Vector3(), []);
	const scene = useThree((state) => state.scene);

	return () => {
		if (!obj.current) return [];
		raycaster.set(
			obj.current.getWorldPosition(pos),
			obj.current.getWorldDirection(dir)
		);
		return raycaster.intersectObjects(scene.children);
	};
};

export const inches = (num) => {
	return num * 0.025399428948678904;
};
// uninch function 
export const uninch = (num) => {
	return num * 39.37007874015748;
};
export const spacing = 0.283464566929126

window.jasearch = jasearch

window.inches = inches;
window.uninch = uninch;
export const search = (e) => jasearch(productList, e);
// window.search = search;


