import axios from 'axios';
import { atom } from 'jotai';
import jasearch from 'jasearch'
import { konsole } from '../helpers/utils';
export const getData = async () => {
	try {
		const { data: response } = await axios.get('https://dacor.ecommersive.com/api/dacors?populate[0]=img.data&pagination[pageSize]=200');
		let products = {}

		window.index = 0
		response.data.map(x => x.attributes).forEach(({ sku, additional, img, ...item }) => {

			item = {
				sku,
				...item,
				...additional
			}
			if (!item.sku) return konsole.error('MISSING SKU', item)
			if (item.overAllDimension) {
				item.overAllDimension = item.overAllDimension.split('Please')[0]

				if (item.overAllDimension.startsWith('<!--td {border: 1px solid #ccc;}br {mso-data-placement:same-cell;}-->')) {
					item.overAllDimension = item.overAllDimension.split('<!--td {border: 1px solid #ccc;}br {mso-data-placement:same-cell;}-->')[1]
				}
				if (item.overAllDimension.startsWith('\n\n')) {
					item.overAllDimension = item.overAllDimension.split('\n\n')[1]
				}
				item.overAllDimension = item.overAllDimension.trim()
			}
			if (item.color === 'silver') item.color = 'stainless'
			if (!item.color) {
				let theSku = sku.split('/')[0]
				if (theSku.endsWith('M')) item.color = 'graphite'
				else if (theSku.endsWith('S')) item.color = 'stainless'
			}
			if (typeof item.size === 'string') {
				item.size = parseInt(item.size)
			}
			if (item.sku.startsWith('DRR') || item.sku.startsWith('DRZ') || item.sku.startsWith('DRW') || item.sku.startsWith('DRF')) {

				if (item.images && item.images.length) {
					item.images.forEach(each => {
						if (each.url && !each.url.includes('medusa-strapi.s3')) konsole.log('image needs fixing', sku)
					})
				}
			}
			sku = sku.trim()
			window.index++
			products[sku] = {
				sku,
				...item,
				image: img?.data?.attributes?.url || ''
			};
		});

		// Add missing elements
		if (products['DOC30T977DM/DA']) products['DOC30T977DS/DA'] = products['DOC30T977DM/DA']
		if (products['DHD54U990IS/DA']) {
			products['DHD54U990IS/DA'] = JSON.parse(JSON.stringify(products['DHD54U990IS/DA']))
			products['DHD54U990IS/DA'].color = 'stainless'
		}

		if (products['DWS4000BB']) {
			products['DWS4000BBM'] = products['DWS4000BB']
			products['DWS4000BBS'] = products['DWS4000BB']
		}
		if (products['DHD36U990WS']) {
			products['DHD36U990WS'] = JSON.parse(JSON.stringify(products['DHD36U990WS']))
			products['DHD36U990WS'].color = 'stainless'
		}
		if (products['DRR30980LAP/DA']) products['DRR30980LAP/DA'] = products['DRR30980LAP/DA']
		if (positions['DTG30P875NS/DA']) {
			positions['DTG30P875NS/DA'].positionInKitchenSink[1] -= 1
			positions['DTG30P875NS/DA'].positionInIsland[1] -= 1
		}
		// initial wall oven double
		if (positions['DOB30M977DM']) {
			positions['DOB30M977DM'] = (positions['DOB30M977DM'])
		}

		if (positions['DWS4000BBM']) {
			positions['DWS4000BB'] = (positions['DWS4000BBM'])
		}


		Object.keys(products).forEach(sku => {
			if (sku.includes('977D')) {
				if (sku.startsWith('DOB')) {
					products[sku].altCategory = 'Wall Oven - Double'
				} else {
					products[sku].altCategory = 'Wall Oven - Combi'
				}
			}
		})


		return products;
	} catch (error) {
		// Handle error case here if needed
		konsole.error('Fetching products failed', error);
		throw error; // Re-throw the error to be handled by the component that uses the atom
	}
}

export const productsAtom = atom(async (get) => {
	let data = await getData()
	return data
});
